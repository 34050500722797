<template>
    <el-container v-loading="loading"
                  class="h-full"
                  direction="vertical">
        <page-header v-if="approvalDocument">
            <template #title>
                {{ processNumber }}.&nbsp;
                {{ approvalDocument?.version }}.&nbsp;
                Согласование документа.&nbsp;
                <span v-if="approvalInfo?.is_izm"
                      class="font-bold">Изм.&nbsp;</span>
                {{ approvalDocument?.translated_type }}
            </template>

            <!-- Go back -->
            <template #button>
                <el-button @click="goBack">
                    <i class="el-icon-back" /> К списку
                </el-button>
            </template>

            <template v-if="canEditDocument">
                <el-button
                    type="primary"
                    @click="onOpenEditDocument">
                    Редактировать документ
                </el-button>
            </template>
        </page-header>

        <div v-if="pdfUrl"
             class="approval-pdf">
            <div id="canvas-container"
                 class="approval-pdf__wrapper" />

            <span
                class="el-image-viewer__btn el-image-viewer__close"
                @click="pdfUrl = null"><i class="el-icon-close" /></span>
        </div>
        <el-main class="pr-0 py-0">
            <el-row type="flex"
                    style="min-height: 100%">
                <el-col :span="18"
                        class="pr-4">
                    <el-tabs v-model="activeTabName"
                             @tab-click="handleClickTab">
                        <!-- Common -->
                        <el-tab-pane label="Информация"
                                     name="project.approval.info">
                            <el-row v-if="!isCreator"
                                    class="w-50 _mt-1">
                                <el-form class="approval-form">
                                    <el-form-item label="Тип согласования">
                                        <el-radio-group v-model="processType">
                                            <el-radio-button :disabled="isProcessTypeDisabled"
                                                             :label="1">
                                                Параллельный
                                            </el-radio-button>
                                            <el-radio-button :disabled="isProcessTypeDisabled"
                                                             :label="2">
                                                Последовательный
                                            </el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-form>
                            </el-row>

                            <el-row class="w-50 _mt-1 approval-iteration">
                                <el-row
                                    v-if="allDocuments"
                                    type="flex"
                                    class="row-gap-1.5 gap-0.75 flex-wrap--wrap"
                                    justify="left"
                                    align="center">
                                    <approval-card-item
                                        v-for="(doc, idx) in getIterationDocs(currentIteration)"
                                        :key="doc.id + 'approval.info' + idx"
                                        :document="doc" />
                                </el-row>

                                <el-row
                                    v-if="
                                        currentIteration.approvers && !isAddApproverScreen &&
                                            ((
                                                (approvalInfo.process_status || isCreator || isApprover)
                                                && !isGip)
                                                || (isGip && approvalInfo.process_status !== 'not_initialized')
                                            )
                                    "
                                    class="approval-route">
                                    <div v-if="currentIteration.approvers"
                                         class="mb-3">
                                        Маршрут согласования
                                    </div>

                                    <approver
                                        v-for="(approver, idx) in currentIteration.approvers"
                                        :key="approver.id + 'approval.info' + idx"
                                        :approver="approver"
                                        :index="idx" />
                                </el-row>
                            </el-row>

                            <el-button
                                v-if="
                                    approvalInfo.process_status === 'not_initialized' && isCreator
                                "
                                type="danger"
                                class="w-25"
                                size="medium"
                                @click="reject('stopped')">
                                Остановить процесс согласования
                            </el-button>

                            <el-form
                                ref="reportForm"
                                class="_mt-1 approval-form"
                                :model="form"
                                :rules="rules"
                                @submit.native.prevent>
                                <!-- Process Date  -->
                                <el-row
                                    v-if="isGip"
                                    class="w-50 _mt-1">
                                    <date-field v-model="form.approvalEnd"
                                                :disabled="!isEditableField('approval_end')"
                                                name="approvalEnd"
                                                label="Срок согласования" />
                                </el-row>

                                <!-- List approvers  -->
                                <el-row
                                    v-if="(!isCreator && isTaskNotInitialized) || isAddApproverScreen"
                                    class="w-50 _mt-1">
                                    <el-form-item v-for="(approver, i) in form.approvers"
                                                  :key="approver.id"
                                                  class="approval-form__approvers">
                                        <user-field
                                            :label="i === 0 ? 'Проектировщик': (processInitiator && approver?.user?.id === processInitiator?.id) ? 'ГИП' : `Согласующий ${approver?.user?.deleted ? '(Удален)' : ''}`"
                                            class="reset-el-form-item"
                                            name="approvers"
                                            :value="approver.user"
                                            :disabled="!isEditableField('approvers') || isDisabledApprover(approver)"
                                            :is-document-approver="isEditableField('approvers') && !isDisabledApprover(approver)"
                                            @change="id => changeApproverUser(approver, id)" />

                                        <el-button v-if="i > 1 && isGip && !approver?.user?.deleted && (processInitiator && approver?.user?.id !== processInitiator?.id)"
                                                   size="small"
                                                   class="text-size--body border--none"
                                                   type="text"
                                                   @click="removeApprover(approver.id)">
                                            Удалить
                                        </el-button>
                                    </el-form-item>
                                </el-row>

                                <!-- Add To List approvers  -->
                                <el-row v-if="(!isCreator && isTaskNotInitialized) || isAddApproverScreen"
                                        class="w-50 f-x-end">
                                    <el-button
                                        type="primary"
                                        class="approval-form__add_button"
                                        size="small"
                                        icon="el-icon-plus"
                                        @click="addApproverToForm">
                                        Добавить
                                    </el-button>
                                </el-row>

                                <el-divider class="_my-1.25" />

                                <!-- Add To List approvers  -->
                                <el-row
                                    v-if="(isGip && isTaskNotInitialized) || isAddApproverScreen || (!isTaskNotInitialized && isAddApproverScreen)"
                                    class="w-50 _mt-1 _mb-1">
                                    <el-button
                                        v-if="isGip && isTaskNotInitialized"
                                        type="primary"
                                        class="text-size--body"
                                        size="small"
                                        @click="initProcess">
                                        {{ isGip && !hasCreatedApprovers ? 'В производство работ' : 'Создать процесс' }}
                                    </el-button>

                                    <el-button
                                        v-if="isAddApproverScreen"
                                        type="info"
                                        class="text-size--body"
                                        size="small"
                                        @click="isAddApproverScreen = false">
                                        Назад
                                    </el-button>

                                    <el-button
                                        v-if="!isTaskNotInitialized && isAddApproverScreen"
                                        type="primary"
                                        class="text-size--body"
                                        size="small"
                                        @click="addApproverToProcess">
                                        Применить
                                    </el-button>
                                </el-row>

                                <!-- Comment and file  -->
                                <el-row
                                    v-if="isCanApprov && !isAddApproverScreen"
                                    class="w-45 _mt-1 _mb-1">
                                    <el-input
                                        v-model="form.comment"
                                        type="textarea"
                                        :autosize="{ minRows: 2 }"
                                        maxlength="200"
                                        show-word-limit
                                        placeholder="Введите комментарий..."
                                        autocomplete="off" />

                                    <el-upload v-if="!commentFile"
                                               v-model="commentFile"
                                               action=""
                                               :accept="'.xlsx, .xls, .doc, .docx, .pdf'"
                                               type="file"
                                               :show-file-list="false"
                                               :auto-upload="false"
                                               :multiple="false"
                                               :on-change="handleUploadCommentFile"
                                               :limit="1">
                                        <div class="_mt-1"
                                             style="display: flex; align-items: center; width: 100%; justify-content: flex-start;">
                                            <el-tooltip v-if="!form.comment"
                                                        content="Введите комментарий, чтобы иметь возможность подгрузитть файл"
                                                        placement="right">
                                                <el-button
                                                    type="primary"
                                                    :disabled="!form.comment">
                                                    Выбрать файл
                                                </el-button>
                                            </el-tooltip>

                                            <el-button v-else-if="form.comment"
                                                       type="primary">
                                                Выбрать файл
                                            </el-button>
                                        </div>
                                    </el-upload>

                                    <div v-if="commentFile"
                                         class="wrapper _mt-1"
                                         @mouseover="hoveredFile = commentFile"
                                         @mouseleave="hoveredFile = null">
                                        <div style="display: flex; align-items: center; gap: 10px;">
                                            <div class="icon">
                                                <icon
                                                    :name="getIcon(commentFile)"
                                                    class="w-3" />
                                            </div>
                                            <small>{{ commentFile.name }}</small>
                                            <div class="icon">
                                                <el-button v-if="!hoveredFile"
                                                           icon="el-icon-circle-check"
                                                           class="icon_green"
                                                           type="text" />
                                                <el-button v-if="hoveredFile"
                                                           icon="el-icon-close"
                                                           type="text"
                                                           @click="deleteCommentFile" />
                                            </div>
                                        </div>
                                    </div>
                                </el-row>

                                <!-- Controll Main -->
                                <el-row
                                    v-if="!isAddApproverScreen && (canEditIzmDocument || isCanApprov || isButtonShowAddApprover)"
                                    class="w-50 _mt-1 _mb-1">
                                    <el-button
                                        v-if="canEditIzmDocument"
                                        type="primary"
                                        class="text-size--body"
                                        size="small"
                                        @click="onOpenEditIzmDocument">
                                        Загрузить Изм.
                                    </el-button>

                                    <el-button
                                        v-if="isCanApprov"
                                        style="flex: 1"
                                        type="primary"
                                        class="text-size--body"
                                        size="small"
                                        @click="approve">
                                        {{ isGip ? 'В производство работ' : 'Согласовать' }}
                                    </el-button>

                                    <el-button
                                        v-if="isCanApprov"
                                        style="flex: 1"
                                        type="danger"
                                        class="text-size--body"
                                        size="small"
                                        @click="reject('canceled')">
                                        Отклонить
                                    </el-button>

                                    <el-button
                                        v-if="isButtonShowAddApprover"
                                        style="flex: 1"
                                        size="small"
                                        class="text-size--body"
                                        @click="switchAddApproverScreen">
                                        {{ isGip ? 'Изменить маршрут' : 'Добавить согласующего' }}
                                    </el-button>
                                </el-row>

                                <el-row
                                    v-if="
                                        isApproverDelete
                                    ">
                                    <div class="text-size--body text-status-rejected">
                                        Вы удалены из процесса
                                    </div>
                                </el-row>

                                <el-row
                                    v-if="
                                        !isCreator && approvalInfo.process_status === 'stopped'
                                    ">
                                    <div class="text-size--body text-status-rejected">
                                        Процесс остановлен проектировщиком
                                    </div>
                                </el-row>
                            </el-form>
                        </el-tab-pane>

                        <!-- Archive -->
                        <el-tab-pane label="Архив"
                                     name="project.approval.archive">
                            <el-row
                                v-for="(iteration, index) in iterations"
                                :key="iteration.id + 'approval.archive' + index"
                                class="approval-iteration">
                                <div
                                    style="color: #606266"
                                    class="_mb-0.5 text-size--supplementary">
                                    {{
                                        index === iterations.length - 1
                                            ? "Актуальная итерация:"
                                            : `Итерация ${index + 1}:`
                                    }}
                                </div>
                                <el-row
                                    v-if="allDocuments"
                                    type="flex"
                                    class="row-gap-1.5 gap-0.75 flex-wrap--wrap"
                                    justify="left"
                                    align="center">
                                    <approval-card-item
                                        v-for="doc in getIterationDocs(iteration)"
                                        :key="doc.id + 'approval.archive' + iteration.id"
                                        :document="doc" />
                                </el-row>

                                <el-row
                                    v-if="
                                        iteration.approvers &&
                                            !isAddApproverScreen &&
                                            (approvalInfo.process_status || isCreator)
                                    "
                                    class="approval-route">
                                    <div v-if="isCreator"
                                         class="mb-3">
                                        Маршрут согласования
                                    </div>
                                    <approver
                                        v-for="(approver, idx) in iteration.approvers"
                                        :key="approver.id + 'approval.archive' + idx"
                                        :approver="approver"
                                        :index="idx" />
                                </el-row>

                                <el-divider
                                    class="approval-iteration__divider _mb-1.25 _mt-1.75" />
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-col>

                <el-col :span="7"
                        class="d-flex flex--grow">
                    <el-card
                        :body-style="{ padding: '16px' }"
                        shadow="always"
                        class="w-full border--none">
                        <approve-description
                            :info="approvalInfo"
                            :version="currentIteration.document_version"
                            :get-pdf-data="getPdfData"
                            :gip-or-approver="gipOrApprover"
                            :doc="approvalDocument"
                            :project-name="projectName"
                            @changeLoading="changeLoading" />
                    </el-card>
                </el-col>
            </el-row>
        </el-main>
    </el-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageHeader from '@/components/layout/PageHeader.vue';
import ApprovalCardItem from '@/components/cards/ApprovalCard.vue';
import Approver from '@/components/approval/Approver.vue';
import DateField from '@/components/fields/DateField'
import ApproveDescription from '@/components/approval/ApproveDescription.vue';
import { getDocument } from 'pdfjs-dist/build/pdf';
import UserField from '@/components/fields/UserField.vue';
import { extensionIcon } from '@/utils/ui';
import { getEditableFields, statuses } from '@/models/approval'
import { parse } from '@/utils/date'
import { getNumberWithPrefix } from '@/models/tasks'

export default {
  name: 'Approval',
  components: {
    UserField,
    ApproveDescription,
    ApprovalCardItem,
    PageHeader,
    Approver,
    DateField
  },
  data: () => ({
    activeTabName: 'project.approval.info',
    loading: false,
    pdfPages: null,
    pdfUrl: null,
    commentFile: null,
    hoveredFile: null,
    agreement: false,
    processType: 1,
    isAddApproverScreen: false,
    form: {
      comment: null,
      creatorId: null,
      approvalEnd: null,
      documentId: null,
      approvers: [],
      deleteApprovers: []
    }
  }),
  computed: {
    ...mapGetters('project', ['projectName']),
    ...mapGetters('approval', [
      'pageTitle',
      'approvalDocument',
      'approvalInfo',
      'iterations',
      'allDocuments',
      'currentIteration',
      'currentApprover',
      'isGip',
      'isApprover',
      'isCreator',
      'getCurrentApprover'
    ]),
    ...mapGetters('account', ['hasAccess']),
    ...mapGetters('auth', ['profile']),
    ...mapGetters('users', ['usersForSuggest']),

    rules() {
      return {
        approvalEnd: {
          required: true,
          message: 'Выберите дату'
        },
        approvers: {
          required: true,
          validator: (_rule, _value, callback) => {
            if (this.isGip || this.hasCreatedApprovers) {
              callback()
            } else {
              callback(new Error('Укажите пользователей согласования'))
            }
          }
        }
      }
    },
    hasCreatedApprovers() {
      return !!this.form.approvers.filter(x => x._created && x.user).length
    },
    canEditDocument() {
      return (this.isTaskStopped || this.isTaskCanceled) && this.isCreator;
    },
    canEditIzmDocument() {
      return (this.isTaskApproved) && this.isCreator;
    },
    gipOrApprover() {
      if (!this.isCreator) {
        return this.profile;
      }

      return null;
    },
    hasComment() {
      return !!this.form.comment && this.form.comment !== ''
    },
    processInitiator() {
      return this.approvalInfo?.initiator
    },
    processNumber() {
      return getNumberWithPrefix(this.approvalDocument?.approval_process)
    },
    isProcessParallel() {
      return this.approvalInfo?.process_type === 'Parallel';
    },
    isProcessTypeDisabled() {
      return this.approvalInfo?.process_type !== null;
    },
    isNoFirstIteration() {
      return this.iterations.length > 1
    },
    isApproverDelete() {
      return this.getCurrentApprover?.deleted
    },
    isTaskNotInitialized() {
      return this.approvalInfo?.process_status === statuses.NotInitialized
    },
    isTaskStopped() {
      return this.approvalInfo?.process_status === statuses.Stopped
    },
    isTaskCanceled() {
      return this.approvalInfo?.process_status === statuses.Canceled
    },
    isTaskApproved() {
      return this.approvalInfo?.process_status === statuses.Approved
    },
    isCurrentApprover() {
      return this.currentApprover;
    },
    isBtnsDisabled() {
      return (
        this.approvalInfo.process_status !== 'on_review' ||
        !this.isCurrentApprover
      );
    },
    isButtonShowAddApprover() {
      if(this.isTaskNotInitialized || this.isTaskStopped || this.isTaskCanceled || this.isTaskApproved) {
        return false
      }

      return this.isCurrentApprover;
    },
    isCanApprov() {
      return (
        (
          this.isGip &&
          !this.isTaskCanceled &&
          !this.isTaskStopped &&
          !this.isBtnsDisabled
        )
        ||
        (
          this.isApprover &&
          !this.isTaskCanceled &&
          !this.isTaskStopped &&
          !this.isBtnsDisabled
        )
      )
    },
    errorMessage() {
      return !this.isGip && this.isCanApprov ? 'Невозможно согласовать или отклонить документ' : 'Отображаемая информации неактуальна';
    }
  },
  watch: {
    approvalInfo(x) {
      x && (this.form.approvalEnd = parse(this.approvalInfo.approval_end, { iso: true }));
      x && (this.form.approvers = (this.currentIteration?.approvers || []).map(user => ({ id: key(), user })));
      x && (this.processType = (this.isProcessParallel || this.approvalInfo?.process_type === null) ? 1 : 2);
    }
  },
  created() {
    this.activeTabName = this.$route.name;
    this.init();
    this.fetchUsersForSuggest();
  },
  methods: {
    ...mapMutations({
      showForm: 'form/SHOW_FORM'
    }),
    ...mapActions('project', ['createProjectDocument']),
    ...mapActions('approval', [
      'updateProcessByApprover',
      'initApprovalProcess',
      'saveComment',
      'fetchApproval',
      'updateCommentFile'
    ]),
    ...mapActions('users', ['fetchUsersForSuggest']),
    getIcon(file) {
      return extensionIcon(file.name)
    },
    fetch() {
      return this.fetchApproval({ processId: this.$route.params.processId });
    },
    goBack() {
      this.$router.push({
        name: 'project.home'
      });
    },
    isEditableField(name) {
      return !!getEditableFields({status: this.approvalInfo.process_status})[name]
    },
    isDisabledApprover(approver) {
      return !approver._created
    },
    handleClickTab() {
      this.$router.push({
        name: this.activeTabName
      });
    },
    changeLoading(value) {
      this.loading = value;
    },
    async onOpenEditDocument() {
      if (this.canEditDocument) {
        await this.showForm({
          formName: 'task-bar-form',
          formTitle: 'Редактировать документ',
          action: this.createProjectDocument,
          payload: this.approvalDocument,
          callback: () => this.fetch()
        });
      }
    },
    async onOpenEditIzmDocument() {
      if (this.canEditIzmDocument) {
        await this.showForm({
          formName: 'task-bar-form',
          formTitle: 'Загрузить изменения',
          formType: 'izm',
          action: this.createProjectDocument,
          payload: this.approvalDocument,
          callback: () => this.fetch()
        });
      }
    },
    changeApproverUser({ id }, user) {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: approvers.map(x => x.id === id ? { ...x, user } : x)
      }
    },
    addApproverToForm() {
      if(this.processInitiator) {
        const approvesCount = this.form.approvers.length;
        this.form = {
          ...this.form,
          approvers: [
            ...this.form.approvers.slice(0, approvesCount - 1),
            { id: key(), _created: true },
            ...this.form.approvers.slice(approvesCount - 1, approvesCount)
          ]
        }
      } else {
        this.form = {
          ...this.form,
          approvers: [...this.form.approvers, { id: key(), _created: true }]
        }
      }
    },
    removeApprover(id) {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        deleteApprovers: [...this.form.deleteApprovers, approvers.find(x => x.id === id)],
        approvers: approvers.filter(x => x.id !== id)
      }
    },
    switchAddApproverScreen() {
      this.form.approvalEnd = this.approvalInfo.approval_end;

      this.isAddApproverScreen = !this.isAddApproverScreen;
    },
    getIterationDocs(iteration) {
      const documents = [];

      // add main doc
      documents.push({
        id: iteration?.document_id,
        version: iteration?.document_version,
        original_file_name: iteration?.document_name,
        newName: iteration?.document_name,
        isMainDoc: true
      })

      // add acc docs
      if(iteration.accompanying_documents.length){
        iteration.accompanying_documents.forEach((doc) => {
          documents.push({
            id: doc.id,
            newName: doc.original_file_name ?? '',
            version: doc.version
              ? doc.version
              : iteration?.document_version ?? '',
            original_file_name: doc.original_file_name,
            isMainDoc: false
          })
        })
      }

      return documents.filter(Boolean).sort((a, b) => b.version - a.version);
    },
    async init() {
      try {
        this.loading = true;

        if (!this.allDocuments.length) {
          await this.fetch();
        }

        if (this.canEditDocument) {
          await this.showForm({
            formName: 'task-bar-form',
            formTitle: 'Редактировать документ',
            action: this.createProjectDocument,
            payload: this.approvalDocument,
            callback: () => this.fetch()
          });
        }
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'При загрузке произошла ошибка'
        });
      } finally {
        this.loading = false;
      }
    },
    async getPdfData(url) {
      this.pdfUrl = url;

      const cMapUrl = '../../../node_modules/pdfjs-dist/cmaps';
      const cMapPacked = true;
      const enableXfa = true;
      const loadingTask = await getDocument({
        url: this.pdfUrl,
        cMapUrl,
        cMapPacked,
        enableXfa
      });
      const canvasArray = [];

      loadingTask.promise.then(
        function (pdf) {
          let totalPages = pdf.numPages;

          function renderPage(pageNumber) {
            pdf.getPage(pageNumber).then(function (page) {
              const scale = 1;
              const viewport = page.getViewport({ scale: scale });

              const canvas = document.createElement('canvas');
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              const renderContext = {
                canvasContext: context,
                viewport: viewport
              };

              const renderTask = page.render(renderContext);
              renderTask.promise.then(function () {
                canvasArray.push(canvas);

                if (pageNumber < totalPages) {
                  renderPage(pageNumber + 1);
                } else {
                  displayCanvases(canvasArray);
                }
              });
            });
          }

          function displayCanvases(canvases) {
            const container = document.getElementById('canvas-container');

            canvases.forEach((canvas) => {
              container.appendChild(canvas);
            });
          }

          renderPage(1);
        },
        function (reason) {
          console.error(reason);
        }
      );
    },
    async addApproverToProcess() {
      try {
        this.loading = true;

        const need_approve_from_author_ids = this.form.approvers.filter(({ _created }) => _created).map(({ user }) => user?.id).filter(is);
        const delete_author_ids = this.form.deleteApprovers.map(({ user }) => user?.id).filter(is);

        await this.updateProcessByApprover({
          processId: this.approvalInfo.id,
          payload: {
            approver_id: this.profile.id,
            approval_status: 'change_another_authors',
            updated_at: this.approvalInfo.updated_at,
            need_approve_from_author_ids,
            delete_author_ids
          }
        });

        await this.fetch();

        this.$notify({
          type: 'success',
          title: 'Согласующие изменены'
        });
      } catch (e) {
        dialogs.failed.call(this, { message: this.errorMessage })
      } finally {
        this.loading = false;
        this.isAddApproverScreen = false;
        this.form.deleteApprovers = [];
        this.form.approvers = this.form.approvers.map(approvers => {
          return {...approvers, _created: false};
        })
      }
    },

    handleUploadCommentFile(file) {
      this.commentFile = file.raw;
    },

    deleteCommentFile() {
      this.commentFile = null;
    },

    async approve() {
      try {
        this.loading = true;

        let fileId = ''
        if (this.commentFile) {
          const result = await this.updateCommentFile(this.commentFile);
          fileId = result.data
        }

        if(this.hasComment) {
          await this.saveComment({
            text: this.form.comment,
            approver_id: this.profile.id,
            iteration_id: this.currentIteration?.id,
            file_id: this.commentFile ? fileId : null
          })
          this.form.comment = '';
        }

        await this.updateProcessByApprover({
          processId: this.approvalInfo.id,
          payload : {
            approver_id: this.profile.id,
            approval_status: 'approved',
            updated_at: this.approvalInfo.updated_at
          }
        });

        await this.fetch();
      } catch (e) {
        dialogs.failed.call(this, { message: this.errorMessage })
      } finally {
        this.loading = false;
      }
    },
    async reject(status) {
      if (status === 'canceled' && !this.form.comment) {
        this.$notify({
          type: 'warning',
          title: 'Необходимо написать комментарий'
        });
        return;
      }
      this.loading = true;

      try {
        if(this.hasComment && status !== 'stopped') {
          let fileId = ''
          if (this.commentFile) {
            const result = await this.updateCommentFile(this.commentFile);
            fileId = result.data
          }
          await this.saveComment({
            text: this.form.comment,
            approver_id: this.profile.id,
            iteration_id: this.currentIteration?.id,
            file_id: this.commentFile ? fileId : null
          })
          this.form.comment = '';
        }
        await this.updateProcessByApprover({
          processId: this.approvalInfo.id,
          payload: {
            approver_id: this.profile.id,
            approval_status: status,
            updated_at: this.approvalInfo.updated_at
          }
        });

        await this.fetch();

        if (!this.isCreator) return;

        this.$message({
          type: 'success',
          message:
            status === 'stopped'
              ? 'Процесс остановлен проектировщиком'
              : 'Согласование отклонено'
        });
      } catch (e) {
        dialogs.failed.call(this, { message: this.errorMessage })
      } finally {
        this.loading = false;
      }
    },
    async initProcess() {
      this.$refs.reportForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;

            await this.initApprovalProcess({
              processId: this.approvalInfo.id,
              payload: {
                initiator_id: this.profile.id,
                process_name: this.approvalInfo.process_name,
                process_type: this.processType,
                approval_start: new Date().toISOString(),
                approval_end: this.form.approvalEnd,
                approver_ids: this.form.approvers.filter(({ _created }) => _created).map(({ user }) => user?.id).filter(is)
              }
            });

            await this.fetch();
          } catch (e) {
            dialogs.failed.call(this, { message: this.errorMessage })
          } finally {
            this.loading = false;
            this.agreement = false;
          }
        }
      });
    }
  }
};
</script>
<style lang="scss">
.approval-route {
  margin-top: 16px;
  font-size: 12px;
  color: #606266;

  & > div:last-of-type .line-route {
    width: 0;
  }
}

.approval-form {
  display: flex;
  flex-direction: column;

  &__approvers {
    .el-input {
      .el-input__inner {
        height: 32px;
      }
    }
  }

  .el-form-item {
    display: flex;
    align-items: center;
    width: 100%;
    > label {
      display: flex;
      flex: 0 0 16rem;
    }

    & .el-form-item__content {
      display: flex;
      width: 100%;

      //& > div {
      //  flex: 0 0 29rem;
      //}

      & > button {
        margin-left: 5px;
      }

      & .el-form-item__error {
        font-size: 10px;
      }
    }
  }

  &__add_button {
    width: calc(100% - 16rem);
    background: #ecf5ff !important;
    color: #409eff !important;
    font-weight: 500;
  }
}

.approval-iteration {
  &:last-of-type {
    margin-bottom: 16px;

    & .approval-iteration__divider {
      display: none;
    }
  }
}

.el-radio-button__orig-radio:disabled + .el-radio-button__inner {
  color: #b6bac2;
  border-color: #E4E7ED;
}

.approval-agreement {
  font-size: 11px;
  font-weight: 400;
  color: #606266;
}

.approval-pdf {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background: rgba(0, 0, 0, 0.5);

  &__wrapper {
    display: flex;
    background: #f4f4f5;
    border-radius: 12px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    left: 50%;
    top: 5%;
    transform: translate(-50%);
    height: 85%;
    max-width: 920px;
    overflow-y: scroll;
  }

  &__page {
    width: 550px;

    &_horizontal {
      width: 800px;
    }
  }

  &__toolbar {
    position: fixed;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    cursor: pointer;
    box-sizing: border-box;
    user-select: none;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
    width: 282px;
    height: 44px;
    padding: 0 23px;
    background-color: #606266;
    border-color: #fff;
    border-radius: 22px;
  }
}

.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  position: relative;
  left: 0;
}

.wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background .3s;

  &:hover {
    background: #F5F7FA;
    transition: background .3s;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: #000;

  & .el-button {
    color: inherit;
  }

  &_green {
    color: #67C23A !important;
  }
}

.text-status-rejected {
  width: fit-content;
  padding: 0.1rem 0.75rem;
  border-radius: 4px;
  background: rgba(245, 108, 108, 0.1);
  color: #f56c6c;
  font-weight: 500;
}
</style>
