<template>
    <fieldable :label="labels.root"
               :name="name"
               :required="required"
               :errors="errors"
               :no="independent">
        <!-- Select -->
        <el-select ref="select"
                   v-loading="loading"
                   :class="classes.select"
                   popper-class="with-auto-select-height"
                   :value="value"
                   :disabled="disabled"
                   :value-key="asKey ? null : 'id'"
                   :multiple="multiple"
                   :collapse-tags="collapse"
                   :placeholder="placeholder"
                   size="mini"
                   filterable
                   :clearable="clearable"
                   @change="change">
            <!-- Value -->
            <template v-if="native"
                      #prefix>
                <project-thumb :project="value"
                               class="w-12 cursor-pointer"
                               :with-address-tooltip="withAddressTooltipValue"
                               with-address
                               with-avatar
                               compact
                               dark />
            </template>

            <!-- Option -->
            <el-option v-for="project in projects"
                       :key="project.id"
                       :label="project.name"
                       :value="asKey ? project.id : project"
                       class="_px-0">
                <project-thumb :project="project"
                               :with-address="withAddress"
                               :with-address-tooltip="withAddressTooltipOption"
                               :with-avatar="withAvatar"
                               :selected="isSelected(project)" />
            </el-option>
        </el-select>
    </fieldable>
</template>

<script>
import { mapGetters } from 'vuex'
import { resourceable } from '@/store/connectors'
import { injectCommonFieldProps, injectSelectFieldProps } from '@/utils/ui'

import Fieldable from '@/components/fields/Fieldable'
import ProjectThumb from '@/components/projects/ProjectThumb'

const labels = {
  project: 'Проект',
  projects: 'Проекты',
  placeholder: 'Выбрать'
}

export default {
  components: {
    Fieldable,
    ProjectThumb
  },
  mixins: [
    resourceable({ on: 'projects', name: 'projectsForSelection' })
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps({
      defaultName: 'project',
      defaultPlaceholder: labels.placeholder
    }),
    ...injectSelectFieldProps(),

    value: { type: [Object, String, Array], default: null },

    clearable: { type: Boolean, default: false },

    selectFirst: { type: Boolean, default: false },
    selectCurrent: { type: Boolean, default: false },
    withoutCurrent: { type: Boolean, default: false },

    withAddress: { type: Boolean, default: false },
    withAddressTooltipValue: { type: Boolean, default: false },
    withAddressTooltipOption: { type: Boolean, default: false },
    withAvatar: { type: Boolean, default: false },

    collapse: { type: Boolean, default: false },

    native: { type: Boolean, default: false }
  },
  computed: {
    ...mapGetters('project', ['project']),

    classes() {
      return {
        select: {
          'w-full with-restricted-tag-width': true,
          'w-14 without-el-select-input with-full-el-input-height f-y-center h-3': this.native
        }
      }
    },

    loading() {
      return this.projectsForSelectionLoading
    },

    labels() {
      return {
        root: this.label || (this.multiple ? labels.projects : labels.project)
      }
    },

    projects() {
      let r = this.items || this.projectsForSelection || []

      r = (this.project && this.withoutcurrent) ? r.filter(({ id }) => id !== this.project.id) : r

      return r
    }
  },
  watch: {
    projectsForSelection(projects) {
      this.selectFirst && this.change(projects[0])
      this.selectCurrent && this.project && this.change(this.asKey ? this.project.id : projects.find(({ id }) => id === this.project.id))
    },

    items(projects) {
      this.selectFirst && this.change(projects[0])
      this.selectCurrent && this.project &&  this.change(this.asKey ? this.project.id : projects.find(({ id }) => id === this.project.id))
    }
  },
  mounted() {
    this.items === null && this.fetchProjectsForSelection()

    this.selectCurrent 
      && this.project 
      && this.change(this.asKey ? this.project.id : this.projects.find(({ id }) => id === this.project.id))

    this.selectFirst 
      && this.change(this.projects[0])
  },
  methods: {
    isSelected(project) {
      return this.asKey
        ? array(this.value).filter(is).some(x => x === project?.id)
        : array(this.value).filter(is).some(x => x.id === project?.id)
    },

    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>
