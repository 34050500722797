import { parse, format } from '@/utils/date'

import { resourceable, pagenable, loadable, actionable } from '../mixins'

import { toSchedule, fromSchedule } from '@/models/users'

export default {
  namespaced: true,

  mixins: [
    resourceable({
      name: 'user',
      initial: null,
      from: ({ api, getters }, { id, byProject = true }) => [
        byProject && (() => api.usersV2.getProjectUser({ project: getters['project/project'], id })),
        !byProject && (() => api.users.show(id))
      ].find(x => x)().then(x => x?.data?.data)
    }),

    pagenable({
      name: 'users',
      size: 24,
      from: ({ api, getters, page, size, payload: { searchByUser, searchByOrganization, onlyBlockedOrganizations, sort } = {} }) =>
        api.usersV2.getProjectUsers({ 
          project: getters['project/project'], 
          searchByUser, 
          searchByOrganization,
          onlyBlockedOrganizations,
          page, 
          size,
          sort
        })
          .then(x => x?.data?.data)
    }),

    resourceable({ 
      name: 'usersForSuggest', 
      from: ({ api, getters, payload: { 
        search, 
        email, 
        firstName, 
        lastName, 
        patronymic,

        organization, 
        exceptProject,

        withOrganization,
        
        sortByOrganization,

        hasBeTaskCreator,
        hasBeTaskContractor,
        hasBeTaskEngineer,
        hasBeTaskGeneralEngineer,
        hasBeTaskInspector,
        hasBeTaskManager,
        hasBePhotographer,
        hasBeWorkPlanAdministrator,
        hasBeWorkPlanSupervisor,
        hasBeWorkPlanForeman,
        hasBeProjectDesigner,
        hasBeChiefProjectDesigner,
        hasBeDocumentApprover
      } = {} }) => [
        organization && (() => api.usersV2.getOrganizationUsersForSuggest({ 
          search,
          email, 
          firstName, 
          lastName, 
          patronymic, 
          organization,
          exceptProject: exceptProject ? getters['project/project'] : null,

          withOrganization,

          sortByOrganization,

          hasBeTaskCreator,
          hasBeTaskContractor,
          hasBeTaskEngineer,
          hasBeTaskGeneralEngineer,
          hasBeTaskInspector,
          hasBeTaskManager,
          hasBePhotographer,
          hasBeWorkPlanAdministrator,
          hasBeWorkPlanSupervisor,
          hasBeWorkPlanForeman,
          hasBeProjectDesigner,
          hasBeChiefProjectDesigner,
          hasBeDocumentApprover
        })),
        !organization && (() => api.usersV2.getProjectUsersForSuggest({
          search,
          email, 
          firstName, 
          lastName, 
          patronymic, 
          project: getters['project/project'],

          withOrganization,

          sortByOrganization,

          hasBeTaskCreator,
          hasBeTaskContractor,
          hasBeTaskEngineer,
          hasBeTaskGeneralEngineer,
          hasBeTaskInspector,
          hasBeTaskManager,
          hasBePhotographer,
          hasBeWorkPlanAdministrator,
          hasBeWorkPlanSupervisor,
          hasBeWorkPlanForeman,
          hasBeProjectDesigner,
          hasBeChiefProjectDesigner,
          hasBeDocumentApprover
        }))
      ].find(x => x)().then(x => x?.data?.data || [])
    }),

    resourceable({ 
      name: 'schedule', 
      from: async ({ api, getters, payload: { user } }) => {
        const project = getters['project/project']
        const schedule = await api.usersV2.getSchedule({ project, user }).then(x => ifEmpty(x?.data?.data, { templates: [] }))
        const hours = fromSchedule({ schedule })

        return {
          timezone: schedule.timezone,
          hours
        }
      }
    }),

    resourceable({
      name: 'availableScheduleSlots',
      from: ({ api, getters, payload: { user, at } }) => {
        const project = getters['project/project']

        return api.usersV2.getAvailableScheduleSlots({ project, user, at })
          .then(x => (x?.data?.data?.available_periods || [])
            // Parse ISO dates
            .map(({ start_at_period, end_at_period, is_reserved }) => ({
              from: parse(start_at_period, { iso: true }),
              to: parse(end_at_period, { iso: true }),
              is_reserved
            }))
            // Build key & label
            .map(({ from, to, is_reserved }) => ({
              key: key(),
              value: [from, to],
              label: `${format(from, { withDate: false, withSeconds: false })} - ${format(to, { withDate: false, withSeconds: false })}`,
              disabled: is_reserved
            }))
          )
      }
    }),

    actionable({
      name: 'resetUserPassword',
      at: ({ api }, { user }) => api.usersV2.resetUserPassword({ user })
    }),

    loadable({ name: 'updateUser', action: 'updateUserRevision' }),
    loadable({ action: 'inviteUser' }),
    loadable({ action: 'removeUser' }),
    loadable({ action: 'storeSchedule' })
  ],

  getters: {
    schedulesByProject: state => project => state.schedules.filter(({ project_id }) => project_id === project.id)
  },

  actions: {
    inviteUser({ rootGetters: getters }, { user }) {
      return this.$api.usersV2.inviteUser({ user, project: getters['project/project'] })
    },

    storeSchedule({ rootGetters: getters }, { user, timezone, hours }) {
      const project = getters['project/project']
      const schedule = toSchedule({ project, user, timezone, hours })

      return this.$api.usersV2.updateSchedule({ schedule })
    },

    updateUserRevision({ rootGetters: getters }, { user }) {
      return this.$api.usersV2.updateProjectUser({ user, project: getters['project/project'] })
    },

    removeUser({ rootGetters: getters }, { user }) {
      return this.$api.usersV2.removeProjectUser({ user, project: getters['project/project'] })
    },

    /**
     * @deprecated
     */

    getUsers: async function (context, payload = null) {
      let {data} = await this.$api.users.index(payload);
      return data;
    },
    storeUser: async function (context, {payload}) {
      let {data} = await this.$api.users.store(payload);
      return data;
    },
    showUser: async function () {
      let {data} = await this.$api.users.show();
      return data;
    },
    updateUser: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.users.update(id, payload);
      return data;
    },
    destroyUser: async function (context, payload) {
      let {data} = await this.$api.users.destroy(payload);
      return data;
    },
    restoreUser: async function (context, id) {
      let {data} = await this.$api.users.restore(id);
      return data;
    },
    getUserPermissions: async function (context, id) {
      let {data} = await this.$api.users.getUserPermissions(id);
      return data;
    },
    getUserProjects: async function (context, id) {
      let {data} = await this.$api.users.getUserProjects(id);
      return data;
    },
    setUserProjects: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.users.setUserProjects(id, payload);
      return data;
    },
    setUserPermissions: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.users.setUserPermissions(id, payload);
      return data;
    },
    getUserRolesForAuth: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.users.getUserRolesForAuth(id, payload);
      return data;
    },
    setUserRolesForAuth: async function (context, {
      id,
      payload
    }) {
      let {data} = await this.$api.users.setUserRolesForAuth(id, payload);
      return data;
    },
    sendConfirmationEmail: async function (_, {userId}) {
      let {data} = await this.$api.users.sendConfirmationEmail(userId);
      return data;
    }
  }
}
