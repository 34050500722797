<template>
    <task-tooltip :tooltips="tooltips">
        <div v-loading="loading"
             class="f-col">
            <!-- Header -->
            <div class="_py-1 border-bottom-1 border-gray-300">
                <is-label :value="labels.title" />
            </div>

            <!-- Content -->
            <div class="f-col _py-0.5">
                <div v-for="entity in entities"
                     :key="entity.key"
                     class="f-x-between _py-0.25">
                    <!-- Entity path -->
                    <is-label :value="entity.label" />

                    <!-- Actions -->
                    <div class="f-y-center">
                        <!-- Stats -->
                        <task-polygon-stats class="_pr-0.5 cursor-default no-shrink"
                                            :value="entity.stats" />

                        <!-- Go to plan -->
                        <is-button class="w-5 h-1.5 no-shrink"
                                   :label="labels.goToPlan"
                                   primary
                                   @click="goToPlan(entity)" />
                    </div>
                </div>
            </div>
        </div>
    </task-tooltip>
</template>

<script>
import { mapActions } from 'vuex'
import { resourceable } from '@/store/connectors'

import { then } from '@/utils/immutable'

import dialogs from '@/values/dialogs'

import { getEntities, getPolygonsByFloors } from '@/models/tasks'

import TaskTooltip from '@/components/tasks/TaskTooltip.vue'
import TaskPolygonStats from '@/components/tasks/TaskPolygonStats.vue'

const labels = {
  title: 'Список приемки',
  goToPlan: 'К плану'
}

export default {
  components: {
    TaskTooltip,
    TaskPolygonStats
  },
  mixins: [
    resourceable({ on: 'floorPlans', name: 'plans' })
  ],
  props: {
    task: { type: Object, default: () => ({}) },
    editable: { type: Boolean, default: () => false },
    editableFields: { type: Array, default: () => [] }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    loading() {
      return this.plansLoading
    },

    entities() {
      return getEntities(this.task)
    },

    tooltips() {
      return this.editableFields
        .filter(({ name }) => ['polygons'].includes(name))
        .map(({ errors }) => errors)
        .flat()
        .map(description => ({
          id: key(),
          messages: [description]
        }))
        .filter(({ messages }) => messages.length)
    },

    polygonsByFloors() {
      return getPolygonsByFloors(this.task)
    }
  },
  methods: {
    ...mapActions('tasks', ['setAcceptanceDataForPlanViewer']),

    async goToPlan({ id, type, position: { floor_work_plan } = {} } = {}) {
      const acceptance = this.$route.params.taskId
      const editable = this.editable

      let r

      r ||= type === 'floor' && then((await this.fetchPlans({ floorId: id }))[0]?.id, planId => this.$router.push({
        name: 'project.plan',
        params: { planId },
        query: { acceptance, editable }
      })) 

      r ||= type === 'floor_plan' && this.$router.push({
        name: 'project.plan',
        params: { planId: id },
        query: { acceptance, editable }
      })

      r ||= type === 'floor_work_plan_layer' && floor_work_plan && this.$router.push({
        name: 'project.work-plan',
        params: { planId: floor_work_plan.id },
        query: { acceptance, editable }
      })

      !r && dialogs.notFoundFloorPlan.call(this)
    }
  }
}
</script>
