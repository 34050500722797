import { chain } from 'lodash';
import { fromError } from '@/utils/common'
import { toRadians, toRadiansFromVector } from '@/utils/math'
import { difference, first, then } from '@/utils/immutable'
import { resourceable, actionable } from '@/store/mixins'

import { fromPolygonsWithTasks, getPolygonsByIds, hasWorkerOrganizationBy, isFinished } from '@/models/tasks'
import { planTypes } from '@/models/plans'

const patch = (x, y) => ({
  ...x,
  ...y
})

export default {
  namespaced: true,
  mixins: [
    resourceable({
      name: 'plan',
      from: async ({ api, state, commit, dispatch }, { id, type, acceptance, polygonsFilter, withFloor, withPoints, withLayers }) => {
        let x

        x ||= type === planTypes.Common && api.floorPlans.getFloorPlan(id).then(r => r.data.data || {})
        x ||= type === planTypes.Tech && api.floorPlans.getFloorPlan(id).then(r => r.data.data || {})
        x ||= type === planTypes.Work && api.floorPlans.getWorkPlan({ id, withLayers, withFloor })

        const plan = await x

        const layers = plan.actual_layers || []

        commit('SET_FLOOR', plan)
        commit('SET_PLAN_DELTA', plan)

        const points = (type === 'common' && withPoints && await dispatch('fetchPlanPoints', { planId: id })) || []

        type === 'work' && withLayers && dispatch('selectWorkLayer', { 
          layer: first(layers),
          acceptance,
          polygonsFilter,
          resetable: true
        })

        const { north_vector: north } = plan

        const r = {
          ...plan,
          ...north && { north: toRadiansFromVector(north[0][0], north[0][1], north[1][0], north[1][1]) },
          layers,
          points
        }

        state.plan = r

        return r
      }
    }),

    resourceable({
      name: 'planComments',
      from: async ({ api, commit }, { id, type }) => {
        const on = ['common', 'technical'].includes(type)

        let comments

        comments ||= on && api.floorPlansV2.getPlanComments(id, {
          params: {
            include: ['jobTypes', 'tags'],
            sort: 'created_at'
          }
        }).then(r => r.data.data.data || [])

        comments ||= []

        commit('SET_PLAN_COMMENTS', comments);

        return comments
      }
    }),

    resourceable({
      name: 'plans',
      from: ({ api }, { floorId }) => api.floorPlans.getFloorPlans({ floorId }).then(x => x.data.data || [])
    }),

    resourceable({
      name: 'workPlans',
      from: ({ api }, { floorId }) => api.floorPlans.getFloorWorkPlans({ floorId }).then(x => x.data.data || [])
    }),

    resourceable({
      name: 'polygons',
      from: async ({ api, dispatch }, { planId, acceptance, filter, withTasks }) => {
        const task = acceptance && await dispatch('fetchTask', { id: acceptance, withUsers: true, withEditableFields: true })
        const taskPolygonsByIds = getPolygonsByIds(task, { planId })

        return api.floorPlansV2.getPlanPolygons({ planId, filter, withTasks })
          .then(x => fromPolygonsWithTasks(x?.data?.data || []))
          .then(x => x.map(x => ({ ...x, acceptable: !!taskPolygonsByIds[x.id] })))
      }
    }),

    resourceable({
      name: 'workPlanUnits',
      from: ({ api }) => api.floorPlans.getWorkPlanUnits()
    }),

    resourceable({
      name: 'workLayer',
      from: ({ api }, { id }) => api.floorPlans.getWorkLayer({ id }).then(r => ({
        ...r,
        layers: r.inner_layers.map(name => ({
          id: key(),
          name
        }))
      }))
    }),

    resourceable({
      name: 'workLayers',
      from: ({ api }, { plan }) => api.floorPlans.getWorkLayers({ plan })
    }),

    resourceable({
      name: 'workPolygonWorkStatuses',
      once: true,
      from: ({ api }) => api.floorPlans.getWorkPolygonWorkStatuses()
    }),

    actionable({
      name: 'fetchWorkLayerVersions',
      loadable: true,
      at: ({ api }, { layer }) => api.floorPlans.getWorkLayerVersions({ layer })
    }),

    actionable({
      name: 'fetchWorkLayerPolygons',
      loadable: true,
      at: async ({ api, state, dispatch, getters }, { 
        layer,
        acceptance,
        filter, 

        withTasks,
        withImages,
        withWorkTypes,

        resetable, 
        replaceable = true, 
        independent = false
      } = {}) => {
        const on = layer && (independent || replaceable || !state.polygonsByWorkLayers[layer.id])

        on && resetable && (state.polygonsByWorkLayers = {})
        on && resetable && (state.polygonsByWorkLayersLoading = {})

        on && !independent && (state.polygonsByWorkLayersLoading = patch(state.polygonsByWorkLayersLoading, { [layer.id]: true }))

        const task = acceptance && await dispatch('fetchTask', { id: acceptance, withUsers: true, withEditableFields: true })
        const taskPolygonsByIds = getPolygonsByIds(task, { layerId: layer.id })
        const acceptable = isFinished(task) || hasWorkerOrganizationBy(task, getters['auth/organizationId'])

        return on 
          ? (filter?.id?.length ? api.floorPlans.getWorkLayerPolygonsAsPost : api.floorPlans.getWorkLayerPolygons)({ 
            layer, 
            filter: {
              ...filter,
              ...then(task && acceptable && !state.polygonsByWorkLayers[layer.id], () => ({ acceptance_work_task_id: task.id }))
            },

            withTasks,
            withImages,
            withWorkTypes
          })
            .then(polygons => polygons.map(x => ({ ...x, acceptance_result: taskPolygonsByIds[x.id]?.result })))
            .then(polygons => (!independent && (state.polygonsByWorkLayers = patch(state.polygonsByWorkLayers, { [layer.id]: polygons })) || true) && polygons)
            .finally(() => (!independent && (state.polygonsByWorkLayersLoading = patch(state.polygonsByWorkLayersLoading, { [layer.id]: false })))) 
          : Promise.resolve([])
      }
    }),

    resourceable({
      name: 'planPoints',
      from: ({ api }, { planId }) =>
        api.floorPlans.getPlanPoints(planId, {
          params: {
            filter: {
              'with_finished_defects': true
            },
            include: ['lastDefiningPointImage'],
            sort: '-created_at',
            append: 'changes_count,actual_recognized_objects_count,view_direction_radian'
          }
        })
          .then(x => x?.data?.data || [])
          .then(points => points.map(x => ({ 
            ...x, 
            ...then(x.defect_image_direction, x => ({ sight: toRadians(x) })),
            ...then(x.additional_data_for_type?.view_direction_radian, x => ({ sight: x })),
            ...then(x.additional_data_for_type?.monitoring_complex?.street_falcon_complex_url_entry_point, x => ({ link: x }))
          })))
    }),

    actionable({
      name: 'uploadPlan',
      loadable: true,
      at: ({ api, getters }, { file }) => api.floorPlans.uploadWorkPlan({ file, project: getters['project/project'] }).then(({ id, inner_layers }) => ({
        uploadId: id,
        layers: inner_layers.map(name => ({
          id: key(),
          name
        }))
      }))
    }),

    actionable({
      name: 'uploadWorkLayer',
      loadable: true,
      at: ({ api }, { file, prevLayerId }) => api.floorPlans.uploadWorkLayer({ file, prevLayerId }).then(({ id, inner_layers }) => ({
        uploadId: id,
        layers: inner_layers.map(name => ({
          id: key(),
          name
        }))
      }))
    }),

    actionable({
      name: 'createPlan',
      at: ({ api }, { as, floorId, uploadId, plan }) => {
        let r

        r ||= as === 'default' && api.floorPlans.store({ 
          floor_id: floorId, 
          type: plan.type, 
          name: plan.name, 
          expired_at: plan.expired_at, 
          use_in_selling: plan.use_in_selling
        })
        r ||= as === 'work-plan' && api.floorPlans.createWorkPlan({ floorId, uploadId, plan })

        return r.then(({ data }) => data);
      }
    }),

    actionable({
      name: 'updatePlan',
      at: ({ api }, { as, plan }) => {
        let r

        r ||= as === 'default' && api.floorPlans.updateFloorPlan(plan.id, plan) 
        r ||= as === 'work-plan' && api.floorPlans.updateWorkPlan({ plan })

        return r.then(({ data }) => data)
      }
    }),

    actionable({
      name: 'updatePlanDelta',
      loadable: true,
      at: ({ api, commit }, { plan, layer, value }) => {
        const { type } = plan

        let r

        r ||= (type === planTypes.Common || type === planTypes.Tech) && api.floorPlans.updatePlanDelta({ plan, value }) 
        r ||= (type === planTypes.Work) && api.floorPlans.updateWorkLayer({ layer, scaleDelta: value })

        return r
          .then(({ data }) => data)
          .then(() => commit('CHANGE_PLAN_DELTA', { scale_delta: value }))
      }
    }),

    actionable({
      name: 'removePlan',
      loadable: true,
      at: ({ api }, { type, plan }) => {
        let r

        r ||= (type === planTypes.Common || type === planTypes.Tech) && api.floorPlans.delete(plan.id)
        r ||= (type === planTypes.Work) && api.floorPlans.removeWorkPlan({ plan })

        return r
      }
    }),

    actionable({
      name: 'createWorkLayer',
      loadable: true,
      at: ({ api }, { uploadId, prevLayerId, layers, floor_height }) => 
        api.floorPlans.createWorkLayer({ uploadId, prevLayerId, layers, floor_height })
    }),

    actionable({
      name: 'confirmWorkLayer',
      loadable: true,
      at: ({ api }, { layer }) => api.floorPlans.confirmWorkLayer({ layer })
    }),

    actionable({
      name: 'cancelWorkLayer',
      loadable: true,
      at: ({ api }, { layer }) => api.floorPlans.cancelWorkLayer({ layer })
    }),

    actionable({
      name: 'removeWorkLayer',
      loadable: true,
      at: ({ api }, { layer }) => api.floorPlans.removeWorkLayer({ layer })
    }),

    actionable({
      name: 'compareWorkLayers',
      loadable: true,
      at: ({ api, state }, { layers, replaceable = true } = {}) => {
        const layerId = layers[0].id

        const on = replaceable || !state.polygonsByWorkLayers[layerId]

        return on 
          ? api.floorPlans.compareWorkLayers({ layers })
            .then(({ polygons }) => ({ polygons: polygons.map(x => ({ ...x, id: key(), layer_id: layerId, _withoutId: true })) }))
            .then(({ polygons }) => ((state.polygonsByWorkLayers = patch(state.polygonsByWorkLayers, { [layerId]: polygons })) || true) && ({ polygons })) 
          : Promise.resolve([])
      }
    }),

    actionable({
      name: 'selectWorkLayer',
      at: ({ state, dispatch, commit }, { layer, acceptance, polygonsFilter, resetable }) => {
        state.selectedWorkLayer = layer

        commit('SET_PLAN_DELTA', layer)

        return dispatch('fetchWorkLayerPolygons', { layer, acceptance, filter: polygonsFilter, resetable, withWorkTypes: true })
      }
    }),

    actionable({
      name: 'selectWorkPolygon',
      at: ({ state }, { polygon }) => {
        state.selectedWorkPolygon = polygon
      }
    }),

    actionable({
      name: 'addWorkPolygon',
      at: ({ state }, { layer, polygon }) => {
        state.polygonsByWorkLayers[layer.id].push(polygon)
      }
    }),

    resourceable({
      name: 'workPolygon',
      from: ({ api }, { id, withTasks, withImages, withWorkTypes }) => api.floorPlans.getWorkPolygon({ id, withTasks, withImages, withWorkTypes })
    }),

    resourceable({
      name: 'workPolygonEditableFields',
      initial: {},
      from: ({ api }, { polygon }) => api.floorPlans.getWorkPolygonEditableFields({ polygon })
    }),

    actionable({
      name: 'updateWorkPolygon',
      loadable: true,
      at: async ({ api, state, dispatch }, { polygon, layer }) => {
        const update = (state, polygon) => state.polygonsByWorkLayers = Object
          .entries(state.polygonsByWorkLayers)
          .reduce((r, [k, v]) => ({
            ...r,
            [k]: v.map(x => x.id === polygon.id ? { ...polygon, _updated: Date.now() } : x)
          }), {})

        const { _created, _images_for_create = [], _images_for_remove = [], _updated_for_task } = polygon || {}

        _images_for_create.length && await api.floorPlans.createWorkPolygonImage({ polygon, image: _images_for_create[0] })
        _images_for_remove.length && await api.floorPlans.removeWorkPolygonImage({ polygon, image: _images_for_remove[0] })

        const task = _updated_for_task && await dispatch('fetchTask', { id: _updated_for_task, withUsers: true })
        const taskPolygonsByIds = getPolygonsByIds(task, { layerId: layer.id })

        return ((_created || _updated_for_task) ? Promise.resolve() : api.floorPlans.updateWorkPolygon({ polygon }))
          .then(() => dispatch('fetchWorkPolygon', { 
            id: polygon?.id,
            withWorkTypes: true
          }))
          .then(polygon => update(state, { ...polygon, acceptance_result: taskPolygonsByIds[polygon.id]?.result, _updated_for_task }))
      }
    }),

    actionable({
      name: 'updateWorkPolygons',
      loadable: true,
      at: async ({ api, state, dispatch }, { polygons, layer }) => {
        const update = (state, polygon) => state.polygonsByWorkLayers = Object
          .entries(state.polygonsByWorkLayers)
          .reduce((r, [k, v]) => ({
            ...r,
            [k]: v.map(x => x.id === polygon.id ? { ...polygon, _updated: Date.now() } : x)
          }), {})

        const { _updated_for_task } = polygons[0] || {}

        const task = _updated_for_task && await dispatch('fetchTask', { id: _updated_for_task, withUsers: true })
        const taskPolygonsByIds = getPolygonsByIds(task, { layerId: layer.id })

        return then(polygons.filter(({ _updated_for_task }) => !_updated_for_task), polygons => polygons.length && api.floorPlans.updateWorkPolygons({ layer, polygons }) || Promise.resolve()) 
          .then(() => dispatch('fetchWorkLayerPolygons', { 
            layer, 
            filter: { id: polygons.map(({ id }) => id) }, 
            independent: true, 
            withWorkTypes: true
          }))
          .then(polygons => polygons.map(x => update(state, ({ ...x, acceptance_result: taskPolygonsByIds[x.id]?.result, _updated_for_task }))))
      }
    }),

    actionable({
      name: 'removeWorkPolygon',
      at: ({ api, state }, { polygon }) => {
        const remove = (state, polygon) => state.polygonsByWorkLayers = Object
          .entries(state.polygonsByWorkLayers)
          .reduce((r, [k, v]) => ({
            ...r,
            [k]: v.filter(x => x.id !== polygon.id)
          }), {})

        return (polygon._created ? Promise.resolve() : api.floorPlans.removeWorkPolygon({ polygon }))
          .then(() => remove(state, polygon))
      }
    }),

    actionable({
      name: 'requestWorkPolygonEditing',
      loadable: true,
      at: ({ api }, { layer, polygonIds, comment }) => api.floorPlans.requestWorkPolygonEditing({
        layer,
        polygonIds,
        comment
      }).catch(fromError)
    }),

    actionable({
      name: 'createWorkPolygons',
      loadable: true,
      at: ({ api, state }, { layer }) => {
        const polygons = state.polygonsByWorkLayers[layer.id].filter(x => x._created)

        return api.floorPlans.createWorkPolygons({ layer, polygons }).then(r => {
          state.polygonsByWorkLayers = patch(state.polygonsByWorkLayers, { 
            [layer.id]: difference(state.polygonsByWorkLayers[layer.id], polygons, (a, b) => a.id === b.id) 
          })
          return r
        })
      }
    }),

    actionable({
      name: 'createPlans',
      loadable: true,
      at: ({ api }, { files, positionsByFiles }) => api.floorPlansV2.createPlans({ files, positionsByFiles })
    }),

    resourceable({
      name: 'task',
      initial: null,
      from: async ({ api, state }, { id, withUsers, withEditableFields }) => {
        const task = await api.tasks.show(id, {
          params: {
            include: [
              withUsers && 'members.user.organization'
            ]
          }
        }).then(x => x?.data?.data || {})

        state.taskEditableFields = withEditableFields && await api.tasks.getTaskEditableFieldsWithValidation({ id })
          .then(x => x.data?.data || [])
          .then(r => r.reduce((r, x) => ({ ...r, [x.name]: x }), {}))

        return task
      }
    }),

    actionable({
      name: 'clearTask',
      at: ({ state }) => {
        state.task = null
        state.taskEditableFields = null
      }
    })
  ],
  state: {
    floorPlanDetails: {},
    floorPlanImage: {},
    // TODO@refactor: Delete it. Replaced by "points"
    floorPlanPoints: [],
    floorPlanProtocols: [],
    buildingStandardTree: [],

    planDelta: 1,
    planDeltaChangedAt: null,

    planComments: [],
    
    plan: null,
    room: null,
    points: [],

    selectedWorkLayer: null,
    selectedWorkPolygon: null,

    polygonsByWorkLayers: {},
    polygonsByWorkLayersLoading: {},

    taskEditableFields: null
  },

  getters: {
    taskEditableFields: state => state.taskEditableFields,

    buildingStandardTree: (state) => {
      return chain(state.floorPlanProtocols)
        .groupBy('data.building_standard.name')
        .map((item, key) => ({
          label: key,
          id: item[0].data.building_standard_id,
          type: 'buildingStandard',
          children: chain(item)
            .groupBy('data.work_type.name')
            .map((workTypeItem, workTypeItemKey) => ({
              children: workTypeItem.map(protocol => ({
                label: protocol.name,
                id: protocol.id,
                type: 'protocol',
                color: protocol.data.color
              })),
              type: 'workType',
              id:workTypeItem[0].data.work_type_id,
              label: workTypeItemKey
            }))
            .value()
        }))
        .value();
    },

    north: state => state.plan?.north || 0,

    plan: state => state.plan,
    room: state => state.room,
    points: state => state.points,

    hasComments: state => !!state.planComments.length,

    selectedWorkLayer: state => state.selectedWorkLayer,
    selectedWorkLayerPolygons: state => (state.selectedWorkLayer && state.polygonsByWorkLayers[state.selectedWorkLayer.id]) || [],
    selectedWorkLayerUnsavedPolygons: state => ((state.selectedWorkLayer && state.polygonsByWorkLayers[state.selectedWorkLayer.id]) || []).filter(x => x._created),
    selectedWorkPolygon: state => state.selectedWorkPolygon,

    polygonsByWorkLayers: state => state.polygonsByWorkLayers,
    polygonsByWorkLayersLoading: state => state.polygonsByWorkLayersLoading
  },

  mutations: {
    SET_FLOOR: (state, plan) => { 
      const { north_vector: north } = plan

      state.plan = {
        ...plan,
        ...north && { north: toRadiansFromVector(north[0][0], north[0][1], north[1][0], north[1][1]) }
      } 
    },

    SET_ROOM: (state, room) => state.room = room,

    CLEAR_POINTS: state => state.points = [],

    UPDATE_POINT: (state, { id, x, y }) => {
      const point = state.points.find(each => each.id === id)

      const perform = () => {
        point.x = x
        point.y = y
      }

      point && perform()
    },

    // TODO@refactor: Eclude
    SET_FLOOR_PLAN_DETAILS: (state, payload) => {
      const { north_vector: north } = payload

      state.floorPlanDetails = {
        ...payload,
        ...north && { north: toRadiansFromVector(north[0][0], north[0][1], north[1][0], north[1][1]) }
      }
    },
    SET_FLOOR_PLAN_IMAGE: (state, payload) => {
      state.floorPlanImage = payload;
    },
    SET_FLOOR_PLAN_POINTS: (state, payload) => {
      state.floorPlanPoints = payload;
    },
    SET_FLOOR_PLAN_PROTOCOLS: (state, payload) => {
      state.floorPlanProtocols = payload;
    },

    SET_PLAN_DELTA: (state, payload) => {
      state.planDelta = payload['scale_delta']
    },

    CHANGE_PLAN_DELTA: (state, payload) => {
      state.planDelta = payload['scale_delta']
      state.planDeltaChangedAt = new Date()
    },

    SET_PLAN_COMMENTS: (state, payload) => {
      state.planComments = payload
    }
  },

  actions: {
    fetchPlanWithoutPoints: async function({ commit }, { id }) {
      const plan = (await this.$api.floorPlans.getFloorPlan(id))?.data?.data

      commit('SET_FLOOR', plan)
      commit('SET_PLAN_DELTA', plan)

      return plan
    },

    fetchRoom: async function({ commit, dispatch }, { id }) {
      const room = (await this.$api.rooms.show(id, {params: {include: ['planImage', 'furniturePlanImage']}}))?.data?.data || []

      commit('SET_ROOM', room)

      await dispatch('fetchRoomPoints')

      return room
    },

    fetchRoomPoints: async function({ commit, state }) {
      this.$api.rooms.getRoomPoints(state.room.id, {
        params: {
          include: ['lastDefiningPointImage']
        }
      })
        .then(x => x?.data?.data || [])
        .then(points => points.map(x => ({ 
          ...x, 
          ...x.defect_image_direction && { sight: toRadians(x.defect_image_direction) } 
        })))
        .then(points => commit('SET_POINTS', points))
    },

    clearPoints: ({ commit }) => commit('CLEAR_POINTS'),

    updatePoint: ({ commit }, payload) => commit('UPDATE_POINT', payload),

    // TODO@refactor: Exclude
    getFloorPlan: async function ({commit}, {
      floorPlanId,
      payload
    }) {
      let {data} = await this.$api.floorPlans.getFloorPlan(floorPlanId, payload);
      commit('SET_FLOOR_PLAN_DETAILS', data.data);
      return data;
    },

    getFloorPlanPoints: async function ({commit}, {floorPlanId, payload}) {
      let {data} = await this.$api.floorPlans.getPlanPoints(floorPlanId, payload)
      commit('SET_FLOOR_PLAN_POINTS', data.data);
      return data;
    },

    getFloorPlanProtocols: async function ({commit}, {floorPlanId, payload}) {
      let {data} = await this.$api.floorPlans.getFloorPlanProtocols(floorPlanId, payload);
      commit('SET_FLOOR_PLAN_PROTOCOLS', data.data);
      return data;

    },
    updateFloorPlan: async function (context, {
      floorPlanId,
      payload
    }) {
      let {data} = await this.$api.floorPlans.updateFloorPlan(floorPlanId, payload);
      return data;
    },
    storeFloorPlanImage: async function (context, {
      floorPlanId,
      payload
    }) {
      let {data} = await this.$api.floorPlans.storeFloorPlanImage(floorPlanId, payload);
      return data.data;
    },
    updateFloorPlanImage: async function({dispatch, commit}, {
      floorPlanId,
      payload
    }) {
      let data = await dispatch('storeFloorPlanImage', {
        floorPlanId,
        payload
      });
      commit('SET_FLOOR_PLAN_IMAGE', data);
      return data
    },

    fetchDelta: async function({ commit }, floorPlanId) {
      let data = await this.$api.floorPlans.getPlanDelta(floorPlanId);
      commit('CHANGE_PLAN_DELTA', data.data.data);
      return data;
    },

    storePlanComment: async function(context, {floorPlanId, payload}) {
      let data = await this.$api.floorPlansV2.storePlanComment(floorPlanId, payload);
      return data;
    },

    updateOrientation: async function(_, { id, vector }) {
      await this.$api.floorPlans.updateOrientation(id, { vector })
    }
  }
};

