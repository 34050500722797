<template>
    <select-field 
        :value="value"
        :options="options"
        :loading="loading"
        :disabled="disabled"
        :required="required"
        :errors="errors"
        :name="name"
        :label="label"
        :placeholder="placeholder"
        size="mini"
        value-key="id"
        label-key="name"
        :as-key="asKey"
        filterable
        clearable
        @change="change" />
</template>

<script>
import { resourceable } from '@/store/connectors'
import { injectCommonFieldProps, injectSelectFieldProps } from '@/utils/ui'

import SelectField from '@/components/fields/SelectField'

const labels = {
  label: 'Организация',
  placeholder: 'Выберите организацию'
}

export default {
  components: {
    SelectField
  },
  mixins: [
    resourceable({ on: 'organizations', name: 'organizationsForSuggest', mounted: () => ({ byProject: false }) })
  ],
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    ...injectCommonFieldProps({
      defaultName: 'organization',
      defaultLabel: labels.label,
      defaultPlaceholder: labels.placeholder
    }),
    ...injectSelectFieldProps(),
    value: { type: [Array, Object, String], default: null }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    loading() {
      return this.organizationsForSuggestLoading
    },

    options() {
      return this.organizationsForSuggest
    }
  },
  methods: {
    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>
