import { getNumberWithPrefix } from '@/models/tasks'
import { actionable, loadable } from '@/store/mixins'
import { download } from '@/utils/browser'
import { formatAsLabel } from '@/utils/date'

export default {
  namespaced: true,

  mixins: [
    actionable({ 
      name: 'downloadReportKpiFile',
      at: ({ api, getters }) => {
        const project = getters['project/project']

        api.approval.getReportKpiFile({ project })
          .then(r => {
            const url = URL.createObjectURL(new Blob([r.data], { type: 'application/octet-stream' }))

            const name = project.name
            const at = formatAsLabel(Date.now(), { template: 'y-MM-dd' })
            const object = 'Отчет по процессам согласования'

            download(url, { openable: true, name: `${name} ${object} ${at}.xlsx` })
          })
      }
    }),

    loadable({ action: 'fetchApproval' }),
    loadable({ action: 'initApprovalProcess' }),
    loadable({ action: 'updateProcessByApprover' }),
    loadable({ action: 'saveComment' }),
    loadable({ action: 'updateCommentFile' }),
    loadable({ action: 'downloadCommentFile' })
  ],

  state: {
    info: {},
    allDocuments: [],
    iterations: []
  },

  getters: {
    hasApprovalInfo(state) {
      return state.info?.id
    },
    // NOT REVIEWED
    pageTitle(state, getters) {
      const number = getNumberWithPrefix(getters.approvalDocument?.approval_process)
      return `${number}. ${getters.approvalDocument?.version}. Согласование документа. ${getters.approvalDocument?.translated_type}`;
    },
    approvalInfo(state) {
      return state.info;
    },
    approvalDocument(state, getters) {
      const mainDocId = getters.currentIteration?.document_id
      if(mainDocId) {
        return state.allDocuments.find((doc) => doc.id === mainDocId);
      }
      return null;
    },
    allDocuments(state) {
      return state.allDocuments;
    },
    iterations(state) {
      return state.iterations;
    },
    currentIteration(state, getters) {
      return getters.iterations[state.iterations.length - 1];
    },
    getCurrentApprover(state, getters, rootState, rootGetters) {
      return getters.currentIteration?.approvers?.find(a => a.id === rootGetters['auth/profile'].id);
    },
    currentApprover(state, getters, rootState, rootGetters) {
      const profile = rootGetters['auth/profile'];

      if(state.info.process_type === 'Parallel') {
        const currentApprovers = getters.currentIteration.approvers?.filter(a => a.approve_status === 'on_review' && !a.deleted)

        return currentApprovers.some(a => a.id === profile?.id);
      } else {
        const currentApprover = getters.currentIteration.approvers?.find(a => a.approve_status === 'on_review' && !a.deleted);

        return currentApprover?.id === profile.id;
      }
    },
    isGip(state, getters, rootState, rootGetters) {
      return rootGetters['account/hasAccess']({ permissions: ['project_approval_process_be_chief_project_engineer'] });
    },
    isApprover(state, getters, rootState, rootGetters) {
      return rootGetters['account/hasAccess']({ permissions: ['project_approval_process_be_document_approver'] });
    },
    isCreator(state, getters, rootState, rootGetters) {
      // если в актуальной итерации пользователь состоит в апруверах и процесс в статусе согласования, то убрать роль проектировщика
      const isApprover = getters.currentIteration?.approvers?.find(a => a.id === rootGetters['auth/profile'].id)?.id;
      if (isApprover && getters.approvalInfo?.process_status === 'on_review') return false;

      return rootGetters['account/hasAccess']({ permissions: ['project_approval_process_be_project_designer'] });
    }
  },

  mutations: {
    UPDATE_ALL_DOCUMENTS: (state, payload) => {
      state.allDocuments = payload;
    },
    UPDATE_INFO: (state, payload) => {
      state.info = payload;
    },
    UPDATE_ITERATIONS: (state, payload) => {
      const isProcessParallel = state.info.process_type === 'Parallel';

      state.iterations = payload.map((iteration, idx) => {
        const approvers = []
        let isEnd = false;
        let firstApprover = false;

        // add icon to approver
        iteration.approvers.forEach((a, idx) => {
          if (isEnd) return;

          if (!idx) {
            if (a.approve_status === 'stopped') {
              approvers.push({ ...a, icon: 'cross' });
              return;
            }
            approvers.push({ ...a, icon: 'homeFilled' });
            return;
          }

          if (idx !== 0 && a.approve_status === 'on_review') {
            if(isProcessParallel || !firstApprover) {
              firstApprover = true;
              approvers.push({ ...a, icon: 'edit' });
            } else {
              approvers.push({ ...a, icon: 'file' });
            }
            return;
          }

          if (a.approve_status === 'approved') {
            approvers.push({ ...a, icon: 'check' });
            return;
          }

          if (a.approve_status === 'not_approved') {
            approvers.push({ ...a, icon: 'file' });
            return;
          }

          isEnd = true;
          approvers.push({ ...a, icon: 'file' });
        });

        return {
          ...iteration,
          approvers,
          actualIteration: payload.length - 1 === idx
        };
      });
    }
  },

  actions: {
    fetchApproval: async function ({ commit, rootGetters }, { processId }) {
      const { data: process } = await this.$api.approval.getProcessById(processId);
      const { data: docs } = await this.$api.projectsV2.getProjectDocumentIndex(rootGetters['project/project']?.id, {
        params: {
          filter: {
            id: process.current_document_id
          }
        }
      });

      commit('UPDATE_ALL_DOCUMENTS', docs.data);
      commit('UPDATE_INFO', process);
      commit('UPDATE_ITERATIONS', process.iterations);

      return docs.data;
    },

    // NOT REVIEWED

    createProcess: async function(_, payload) {
      return this.$api.approval.createProcess(payload)
    },
    updateProcessByApprover: async function (_, { processId, payload }) {
      return this.$api.approval.update(processId, payload);
    },
    initApprovalProcess: async function (_, { processId, payload }) {
      return this.$api.approval.initProcess(processId, payload);
    },
    saveComment: async function (_, comment) {
      return await this.$api.approval.comment(comment);
    },
    downloadCommentFile: async function (_, fileId) {
      return await this.$api.approval.downloadCommentFile(fileId);
    },
    updateCommentFile: async function (_, commentFile) {
      return await this.$api.approval.putCommentFile(commentFile);
    },
    getAllProcesses: async function() {
      return await this.$api.approval.getProcesses();
    },
    resumeProcess: async function(_, { processId, payload }) {
      return await this.$api.approval.resumeProcess(processId, payload)
    },
    izmProcess: async function(_, { processId, payload }) {
      return await this.$api.approval.izmProcess(processId, payload)
    }
  }
};
