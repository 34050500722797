<template>
    <div class="w-full rows-min-minmax _p-1.5">
        <!-- Header -->
        <div class="f-x-between _mb-1.5">
            <!-- Me -->
            <user-thumb :user="profile"
                        :position="rolesAsLabel"
                        primary-name
                        full-name
                        with-avatar
                        with-position />

            <div class="f-y-center space-x-0.5">
                <!-- Actions -->
                <el-dropdown 
                    :disabled="!hasActions"
                    trigger="click"
                    @command="act">
                    <!-- Trigger -->
                    <el-button ref="actions"
                               type="primary"
                               round>
                        {{ labels.actions }}
                        <i class="el-icon-arrow-down" />
                    </el-button>

                    <!-- List -->
                    <el-dropdown-menu slot="dropdown"
                                      class="max-h-1/4 overflow-auto with-custom-scrollbar">
                        <el-dropdown-item v-for="action in actions"
                                          :key="action.id"
                                          :command="action.id">
                            {{ action.label }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <!-- Notification -->
                <el-dropdown v-loading="creatableNotificationTypesLoading"
                             :disabled="!hasNotificationTypes"
                             trigger="click"
                             @command="notify">
                    <!-- Trigger -->
                    <el-button ref="notify"
                               type="primary"
                               icon="el-icon-warning-outline"
                               plain
                               round>
                        {{ labels.notify }}
                        <i class="el-icon-arrow-down" />
                    </el-button>

                    <!-- List -->
                    <el-dropdown-menu slot="dropdown"
                                      class="max-h-1/4 overflow-auto with-custom-scrollbar">
                        <el-dropdown-item v-for="type in notificationTypesAsOptions"
                                          :key="type.value"
                                          :command="type.value">
                            {{ type.label }}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

                <!-- Help -->
                <el-button ref="help"
                           type="primary"
                           icon="el-icon-notebook-1"
                           plain
                           round
                           @click="goToHelp">
                    {{ labels.help }}
                </el-button>
            </div>
        </div>

        <!-- Body -->
        <div class="rel rounded border-1 border-gray-100 hidden-y">
            <!-- Tab header -->
            <div class="abs r-0.8 t-0.8 depth-10">
                <!-- Task tools -->
                <div v-show="selectedTab === 'tasks'"
                     ref="taskTools"
                     class="f space-x-0.5">
                    <!-- Task type -->
                    <el-radio-group v-model="taskType"
                                    size="mini"
                                    @change="applyTaskType">
                        <el-radio-button :label="null">
                            {{ labels.all }}
                        </el-radio-button>
                        <el-radio-button v-for="item in taskTypes"
                                         :key="item.type"
                                         :label="item.type">
                            {{ item.label }}
                        </el-radio-button>
                    </el-radio-group>

                    <!-- Task filter -->
                    <task-filter ref="taskFilter"
                                 v-model="taskFilter"
                                 :type="taskType"
                                 @confirm="confirmFilter" />
                </div>

                <!-- Schedule -->
                <el-button v-show="selectedTab === 'calendar'"
                           ref="schedule"
                           type="primary"
                           size="mini"
                           @click="showSchedule">
                    {{ labels.schedule }}
                </el-button>
            </div>

            <!-- Tabs -->
            <el-tabs v-model="selectedTab"
                     class="with-el-tabs-h-full with-el-tabs-header-indent"
                     @tab-click="clickTab">
                <!-- Tasks -->
                <el-tab-pane :label="labels.tasks"
                             name="tasks">
                    <!-- Table -->
                    <task-table ref="tasks"
                                v-loading="tasksLoading"
                                class="h-full"
                                :tasks="tasks"
                                :pagination="tasksPagination"
                                @paginate="paginate"
                                @resize="resize"
                                @task-click="setViewedTask"
                                @sort="sort" />
                </el-tab-pane>

                <!-- Tasks -->
                <el-tab-pane :label="labels.calendar"
                             name="calendar">
                    <task-calendar ref="calendar"
                                   @task-click="setViewedTask" />
                </el-tab-pane>
            </el-tabs>
        </div>

        <!-- Viewed task -->
        <task-viewer 
            :task="viewedTask"
            @close="clearViewedTask" />

        <!-- Schedule -->
        <user-schedule-dialog 
            v-if="scheduleDisplayed"
            :id="profileId"
            :description="labels.description"
            :schedule-description="labels.scheduleDescription"
            @close="hideSchedule" />

        <!-- Tutorial -->
        <tutorial ref="tutorial"
                  type="project_main_page"
                  :steps="tutorialSteps" />

        <!-- Children -->
        <transition name="fade"
                    mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import { HELPER_DEFAULT_URL } from '@/utils/helper'
import { openNew } from '@/utils/browser'
import { sortAsName } from '@/utils/immutable'

import { resourceable, pagenable } from '@/store/connectors'

import { notificationTypes } from '@/models/notifications'
import { types, translateType } from '@/models/tasks'

import Tutorial from '@/components/tutorials/Tutorial.vue'
import UserThumb from '@/components/users/UserThumb'
import TaskTable from '@/components/tasks/TaskTable'
import TaskViewer from '@/components/tasks/TaskViewer'
import TaskCalendar from '@/components/tasks/TaskCalendar'
import TaskFilter from '@/components/tasks/TaskFilter'
import UserScheduleDialog from '@/components/users/UserScheduleDialog'

const taskTypes = [
  types.DEFECTS_AND_VIOLATIONS,
  types.BUILDING_ORDER,
  types.ACCEPTANCE_OF_WORK,
  types.PHOTO_ORDER,
  types.APPROVAL_PROCESS
].map(x => ({
  type: x,
  label: translateType(x, { capital: true })
}))

const labels = {
  help: 'Инструкции',
  notify: 'Сообщить о проблеме',
  actions: 'Мои действия',
  tasks: 'Мои задачи',
  calendar: 'Мой календарь',
  all: 'Все',
  schedule: 'Настроить свое расписание',
  description: 'Настройте свою работу в проекте',
  scheduleDescription: 'Проставьте ячейки рабочего времени и нажмите "Сохранить"',
  taskTools: 'Фильтр задач',
  tutorialLaunch: 'Обучающий тур',

  tutorial: {
    actions: 'Добавляйте новые задачи и запускайте процессы',
    notify: 'Уведомляйте о проблемах или критичных изменениях на объекте',
    help: 'Ознакомьтесь с подробной информацией о работе с платформой',
    tasks: 'Управляйте своими задачами',
    taskTools: 'Изменяйте условия отображения задач в соответствии с требованиями',
    calendar: 'Просматривайте свои задачи в режиме планировщика',
    schedule: 'Настройте свое расписания для участия в процессах строительного контроля',
    tutorialLaunch: 'Запустите обучающий тур для выбранной странице еще раз'
  }
}

export default {
  components: {
    Tutorial,
    UserThumb,
    TaskTable,
    TaskViewer,
    TaskCalendar,
    TaskFilter,
    UserScheduleDialog
  },
  mixins: [
    resourceable({ on: 'notifications', name: 'creatableNotificationTypes', mounted: true }),
    resourceable({ on: 'account', name: 'creatableTasks', mounted: true }),
    resourceable({ on: 'account', name: 'access' }),
    pagenable({ on: 'account', name: 'tasks' })
  ],
  data() {
    return {
      selectedTab: 'tasks',

      taskSort: null,
      taskType: null,
      taskFilter: {},
      taskFilterForServer: {},

      viewedTask: null,

      scheduleDisplayed: false,
      
      labels,
      taskTypes
    }
  },
  computed: {
    ...mapGetters('auth', [ 'profile', 'profileId' ]),
    ...mapGetters('account', [ 'hasAccess' ]),
    ...mapGetters('approval', [ 'isCreator' ]),

    rolesAsLabel() {
      return (this.access?.roles || []).map(({ name }) => name).join(', ')
    },

    notificationTypesAsOptions() {
      return this.creatableNotificationTypes.map(({ type, translated_type, is_available_action }) => ({
        value: type,
        label: translated_type,
        allowed: is_available_action
      })).filter(({ allowed }) => allowed)
    },

    hasNotificationTypes() {
      return !!this.notificationTypesAsOptions.length
    },

    actions() {
      return [
        ...Object.values(this.creatableTasks)
          .filter(({ creatable }) => creatable)
          .map(({ type }) => ({
            label: ({
              [types.DEFECTS_AND_VIOLATIONS]: 'Создать задание на устранение дефекта',
              [types.BUILDING_ORDER]: 'Создать предписание',
              [types.PHOTO_ORDER]: 'Заказать снимки 360'
            })[type],
            act: () => this.$router.push({ name: 'project.tasks.create', query: { type } })
          })),
        ...[
          this.hasAccess({ permissions: ['project_tasks_acceptance_works_create', 'project_tasks_be_becomes_constructor'] }) && {
            label: 'Вызвать стройконтроль',
            act: () => this.createAcceptanceByContractor()
          },
          false && this.hasAccess({ permissions: ['project_tasks_acceptance_works_create', 'project_tasks_be_construction_control_engineer'] }) && this.notGeneralEngineer && {
            label: 'Создать приемку работ',
            act: () => this.createAcceptanceByEngineer()
          },
          false && this.hasAccess({ permissions: ['project_tasks_acceptance_works_create', 'project_tasks_be_general_control_engineer'] }) && {
            label: 'Создать приемку работ',
            act: () => this.createAcceptanceByGeneralEngineer()
          },
          this.hasAccess({ permissions: ['project_approval_process_be_project_designer'] }) && {
            label: 'Создать процесс согласования',
            act: () => this.createApproval()
          }
        ]
      ]
        .map(x => ({ id: key(), ...x }))
        .filter(({ label }) => label)
        .sort(sortAsName(x => x.label))
    },

    hasActions() {
      return !!this.actions.length
    },

    notGeneralEngineer() {
      return !this.hasAccess({ permissions: ['project_tasks_be_general_control_engineer'] })
    },

    tutorialSteps() {
      return [
        {
          name: 'actions',
          target: () => this.$refs?.actions?.$el
        },
        {
          name: 'notify',
          target: () => this.$refs?.notify?.$el
        },
        {
          name: 'help',
          target: () => this.$refs?.help?.$el
        },
        {
          name: 'tasks',
          target: () => this.$refs?.tasks?.$el,
          before: () => this.selectedTab = 'tasks'
        },
        {
          name: 'taskTools',
          target: () => this.$refs?.taskTools,
          before: () => this.selectedTab = 'tasks'
        },
        {
          name: 'calendar',
          target: () => this.$refs?.calendar?.$el,
          before: () => {
            this.selectedTab = 'calendar'
            this.$refs.calendar.fetch()
          }
        },
        {
          name: 'schedule',
          target: () => this.$refs?.schedule?.$el,
          before: () => {
            this.selectedTab = 'calendar'
          }
        },
        {
          name: 'tutorialLaunch',
          target: () => document.getElementById('tutorial-launch'),
          noOffset: true
        }
      ].map(x => ({ 
        ...x, 
        title: labels[x.name], 
        description: labels.tutorial[x.name] 
      }))
    }
  },
  mounted() {
    this.fetch()

    this.$refs.tutorial.show()
    this.$route.meta?.tutorial?.onShow?.(() => this.$refs.tutorial?.showForce?.())
  },
  methods: {
    ...mapActions('tasks', [ 'storeTaskWithError' ]),
    ...mapActions('project', ['createProjectDocument']),
    ...mapMutations({ showForm: 'form/SHOW_FORM' }),

    fetch() {
      this.fetchTasks({ 
        filter: {
          type: this.taskType,
          ...this.taskFilterForServer
        },
        sort: this.taskSort || ({
          [types.DEFECTS_AND_VIOLATIONS]: ['first_draft', 'kind_defect', '-created_at']
        })[this.taskType] || ['-created_at'],
        withMembers: true
      })
    },
    
    confirmFilter([filter, filterForServer]) {
      this.taskFilter = filter
      this.taskFilterForServer = filterForServer
      this.fetch()
    },

    notify(type) {
      const name = ({
        [notificationTypes.DPW]: 'project.notifications.dpw',
        [notificationTypes.LCR]: 'project.notifications.lcr'
      })[type]

      this.$router.push({ name })
    },

    act(id) {
      const action = this.actions.find(x => x.id === id)

      const { act } = action || {}

      act?.()
    },

    paginate(page) {  
      this.paginateTasks({ page })
      this.fetch()
    },

    resize(size) {
      this.paginateTasks({ size })
      this.fetch()
    },

    sort({ key, order }) {
      this.taskSort = this.generateSortParams(key, order)
      this.fetch()
    },
    
    applyTaskType() {
      this.taskFilter = {}
      this.taskFilterForServer = {}
      this.fetch()
    },

    goToHelp() {
      openNew(HELPER_DEFAULT_URL)
    },

    async setViewedTask(task) {
      this.viewedTask = task
    },

    clearViewedTask() {
      this.viewedTask = null
    },

    clickTab(x) {
      x.name === 'calendar' && this.$refs.calendar.fetch()
    },

    showSchedule() {
      this.scheduleDisplayed = true
    },

    hideSchedule() {
      this.scheduleDisplayed = false
    },

    createAcceptanceByContractor() {
      this.showForm({
        formName: 'task-acceptance-of-work-form',
        formTitle: 'Вызвать стройконтроль',
        action: this.storeTaskWithError,
        payload: {
          contractor_id: this.profileId,
          withGoToTask: true
        }
      })
    },

    createAcceptanceByEngineer() {
      this.showForm({
        formName: 'task-acceptance-of-work-form',
        formTitle: 'Создать приемку работ',
        action: this.storeTaskWithError,
        payload: {
          worker_id: this.profileId,
          withGoToTask: true
        }
      })
    },

    createAcceptanceByGeneralEngineer() {
      this.showForm({
        formName: 'task-acceptance-of-work-form',
        formTitle: 'Создать приемку работ',
        action: this.storeTaskWithError,
        payload: {
          withGoToTask: true
        }
      })
    },

    createApproval() {
      this.showForm({
        formName: 'task-bar-form',
        formTitle: 'Документ на согласование',
        action: this.createProjectDocument,
        callback: () => this.fetch()
      });
    }
  }
}
</script>
