<template>
    <task-tooltip v-if="actions"
                  :tooltips="tooltips">
        <!-- As dropdown -->
        <el-dropdown v-if="asDropdown"
                     trigger="click"
                     @command="actByName">
            <!-- Trigger -->
            <el-button type="primary"
                       :disabled="!actions.length"
                       plain>
                {{ labels.actions }}
                <i class="el-icon-arrow-down" />
            </el-button>

            <!-- List -->
            <el-dropdown-menu slot="dropdown"
                              class="max-h-1/4 overflow-auto with-custom-scrollbar">
                <el-dropdown-item v-for="action in actions"
                                  :key="action.name"
                                  :command="action.name">
                    {{ action.translated_name }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>  

        <!-- As buttons -->
        <el-row v-else
                :gutter="16">
            <el-col v-for="(action, index) in actions"
                    :key="index"
                    class="span-auto mt-2">
                <el-button :type="getMood(action)"
                           size="mini"
                           @click="act(action)">
                    {{ action.translated_name }}
                </el-button>
            </el-col>
        </el-row>
    </task-tooltip>
</template>

<script>
import TaskTooltip from '@/components/tasks/TaskTooltip.vue'

const labels = {
  actions: 'Действия'
}

export default {
  components: {
    TaskTooltip
  },
  props: {
    task: { type: Object, default: null },
    actions: { type: Array, default: () => [] },
    errors: { type: Object, default: () => ({}) },
    asDropdown: { type: Boolean, default: false },
    editableFields: { type: Array, default: () => [] }
  },
  data() {
    return {
      labels
    }
  },
  computed: {
    tooltips() {
      return this.actions
        .map(x => ({
          id: x.name,
          warnings: x.warnings,
          messages: x.messages,
          failed: !!this.errors[x.name]
        }))
        .filter(({ warnings, messages }) => warnings.length || messages.length)
    }
  },
  methods: {
    getMood(action) {
      return action.mood || 'primary'
    },

    act(action) {
      this.$emit('do', action.name)
      this.$emit('do-with-data', action)
    },

    actByName(name) {
      const action = this.actions.find(x => x.name === name)

      this.$emit('do', action.name)
      this.$emit('do-with-data', action)
    }
  }
}
</script>
