import {PERMISSION_LIST} from '@/utils/permissions';
import {LICENCE_ABILITIES} from '@/utils/licences';

const CheckingActsCreate = () => import( '@/views/management/checking-acts/CheckingActsCreate');
const CheckingActsManagementList = () => import( '@/views/management/checking-acts/CheckingActsManagementList');
const UsersEventLogs = () => import( '@/views/management/users/UsersEventLogs');
const UsersManagement = () => import( '@/views/management/users/UsersManagement');
const RolesManagement = () => import( '@/views/management/roles/RolesManagement');
const OrganizationsManagement = () => import( '@/views/management/organizations/OrganizationsManagement');
const MailingsManagement = () => import( '@/views/management/mailings/MailingsManagement');
const BuildingStandardsManagement = () => import( '@/views/management/building-standards/BuildingStandardsManagement');
const ObjectManagement = () => import('@/views/management/ObjectManagement')
const ProjectManagement = () => import('@/views/management/ProjectManagement')
const Task = () => import( '@/views/tasks/Task');
const LicensesManagement = () => import('@/views/management/licenses/LicensesManagement');
const PaymentManagement = () => import('@/views/management/payments/PaymentManagement')

const OrganizationForm = () => import('@/components/forms/OrganizationForm')
const MailingForm = () => import('@/components/forms/MailingForm')
const OrganizationsReportForm = () => import('@/components/forms/OrganizationsReportForm')

const routes = [
  {
    name: 'management.users',
    path: 'users',
    component: UsersManagement,
    meta: {
      title: 'Пользователи',
      access: {permission: PERMISSION_LIST['users.management']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'users'
      }
    }
  },
  {
    name: 'management.users.logs',
    path: 'users/logs',
    component: UsersEventLogs,
    meta: {
      title: 'История действий пользователей',
      access: {permission: PERMISSION_LIST['logs.users']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'users'
      }
    }
  },
  {
    name: 'management.roles',
    path: 'roles',
    component: RolesManagement,
    meta: {
      title: 'Роли',
      access: {permission: PERMISSION_LIST['roles.management']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'users'
      }
    }
  },
  {
    name: 'management.organizations',
    path: 'organizations',
    component: OrganizationsManagement,
    meta: {
      title: 'Организации',
      access: {permission: PERMISSION_LIST['organizations.management']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'organizations'
      }
    },
    children: [
      {
        name: 'management.organizations.create',
        path: 'create',
        component: OrganizationForm,
        props: true,
        meta: {
          title: 'Организации'
        }
      },
      {
        name: 'management.organizations.update',
        path: ':id/update',
        component: OrganizationForm,
        props: true,
        meta: {
          title: 'Организации'
        }
      },
      {
        name: 'management.organizations.report',
        path: 'report',
        component: OrganizationsReportForm,
        props: true,
        meta: {
          title: 'Сформировать отчет по организациям'
        }
      }
    ]
  },
  {
    name: 'management.payments',
    path: 'payments',
    component: PaymentManagement,
    meta: {
      title: 'Журнал оплат',
      access: { permission: 'system_organizations_edit' },
      topNavigation: true
    },
    children: [
      
    ]
  },
  {
    name: 'management.projects',
    path: 'projects',
    component: ProjectManagement,
    meta: {
      title: 'Проекты',
      access: {
        permission: 'system_projects_edit'
      },
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'projects'
      }
    }
  },
  {
    name: 'management.mailings',
    path: 'mailings',
    component: MailingsManagement,
    meta: {
      title: 'Шаблоны почтовой рассылки',
      access: {permission: PERMISSION_LIST['mailings.management']},
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'mailings'
      }
    },
    children: [
      {
        name: 'management.mailings.create',
        path: 'create',
        component: MailingForm,
        props: true,
        meta: {
          title: 'Шаблоны почтовой рассылки'
        }
      },
      {
        name: 'management.mailings.update',
        path: ':id/update',
        component: MailingForm,
        props: true,
        meta: {
          title: 'Шаблоны почтовой рассылки'
        }
      }
    ]
  },
  {
    name: 'management.building.standards',
    path: 'building-standards',
    component: BuildingStandardsManagement,
    meta: {
      title: 'Справочник строительных стандартов',
      access: {
        permission: PERMISSION_LIST['building.standards.management'],
        licenceAbility: LICENCE_ABILITIES['edit_building_standards_by_licence']
      },
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'building.standards'
      }
    }
  },
  {
    name: 'management.objects',
    path: 'objects',
    component: ObjectManagement,
    meta: {
      title: 'Справочник объектов',
      access: {
        permission: 'system_dir_1c_objects_edit'
      },
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'objects'
      }
    }
  },
  {
    name: 'management.checking.acts',
    path: 'checking-acts',
    component: CheckingActsManagementList,
    props: true,
    meta: {
      title: 'Акты проверки',
      //Hide this item from top menu navigation
      access: {
        licenceAbility: LICENCE_ABILITIES['edit_checking_acts_by_licence'],
        permission: PERMISSION_LIST['project_tasks_checking_acts_show']
      },
      topNavigation: true,
      leftSideMenu: {
        on: true,
        name: 'checking.acts'
      }
    }
  },
  {
    name: 'management.checking.acts.create',
    path: 'checking-acts/create',
    component: CheckingActsCreate,
    meta: {
      title: 'Акты проверки',
      topNavigation: false,
      leftSideMenu: {
        on: false,
        name: 'checking.acts'
      }
    }
  },
  {
    name: 'management.checking.acts.id',
    path: 'checking-acts/:taskId',
    component: Task,
    meta: {
      title: 'Акты проверки',
      topNavigation: false,
      leftSideMenu: {
        on: true,
        name: 'checking.acts'
      }
    }
  },
  {
    name: 'management.licenses',
    path: 'licenses',
    component: LicensesManagement,
    meta: {
      title: 'Лицензии',
      topNavigation: true,
      access: {permission: PERMISSION_LIST['licence.management']},
      leftSideMenu: {
        on: true,
        name: 'licenses'
      }
    }
  }
];
export default routes;
