<template>
    <div class="cols-min-minmax"
         @click="click">
        <!-- Left -->
        <div v-if="withAvatar"
             class="f-center _p-0.5">
            <div v-if="avatar"
                 :class="classes.avatar"
                 :style="styles.avatar" />
            <el-avatar v-else
                       :class="classes.avatarEmpty"
                       icon="el-icon-office-building" />
        </div>
        <!-- Right -->
        <div :class="classes.right">
            <!-- Name -->
            <is-label :value="name || address"
                      :dark="dark"
                      :color="nameColor || (selected ? 'accent' : null)"
                      truncate
                      compact />

            <!-- Address -->
            <el-tooltip v-if="withAddress"
                        placement="bottom-start"
                        :disabled="!withAddressTooltip"
                        :content="address"
                        :enterable="false">
                <is-label 
                    :value="address"
                    :dark="dark"
                    :color="selected ? 'accent' : null"
                    tertiary
                    truncate
                    compact />
            </el-tooltip>

            <slot />
        </div>
    </div>
</template>

<script>
export default {
  props: {
    project: { type: Object, default: null },

    nameColor: { type: String, default: null },

    withAddress: { type: Boolean, default: false },
    withAddressTooltip: { type: Boolean, default: false },
    withAvatar: { type: Boolean, default: false },

    selected: { type: Boolean, default: false },

    compact: { type: Boolean, default: false },

    dark: { type: Boolean, default: false }
  },
  computed: {
    classes() {
      return {
        avatar: {
          'wh-3': !this.compact,
          'wh-2': this.compact
        },
        avatarEmpty: {
          'f-center': true,
          'wh-3': !this.compact,
          'wh-2': this.compact
        },
        right: {
          'f-col f-x-center _px-0.5': true,
          '_py-0.5': !this.withAddress && !this.withAvatar
        }
      }
    },

    styles() {
      return {
        avatar: [
          `background-image: url("${this.avatar}")`,
          'background-size: cover',
          'background-position: center'
        ].join(';')
      }
    },

    name() {
      return this.project?.name
    },

    address() {
      return this.project?.address || 'Адрес не указан'
    },

    avatar() {
      return this.project?.avatar?.storage_thumb_url
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    }
  }
}
</script>
