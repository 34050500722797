import axios from 'axios';
import qs from 'qs';
import Cookie from 'js-cookie';

import apiApproval from '@/api/repositories/other/approval';
import apiProject from '@/api/repositories/v1/project';
import apiProjects from '@/api/repositories/v1/projects';
import apiDirs from '@/api/repositories/v1/dirs';
import apiBuildings from '@/api/repositories/v1/houses';
import apiFloors from '@/api/repositories/v1/floors';
import apiPlans from '@/api/repositories/v1/floor-plans';
import apiPhotos from '@/api/repositories/v1/photos';
import apiMl from '@/api/repositories/v1/ml';
import apiPlanItems from '@/api/repositories/v1/plan-items';
import apiPlanItemsStatistics from '@/api/repositories/v1/plan-items-statistics';
import apiAuth from '@/api/repositories/v1/auth';
import apiUsers from '@/api/repositories/v1/users';
import apiOrganizations from '@/api/repositories/v1/organizations';
import apiRoles from '@/api/repositories/v1/roles';
import apiPermissions from '@/api/repositories/v1/permissions';
import apiEventLogs from '@/api/repositories/v1/event-logs';
import apiHeatMap from '@/api/repositories/v1/heat-map';
import apiTasks from '@/api/repositories/v1/tasks';
import apiRooms from '@/api/repositories/v1/rooms';
import apiRoomsPlanItems from '@/api/repositories/v1/rooms-plan-items';
import apiTechRooms from '@/api/repositories/v1/tech-rooms';
import apiBuildingStandards from '@/api/repositories/v1/building-standards';
import apiRecognitionHistoryMarks from '@/api/repositories/v1/recognition-history-marks';
import apiCheckingActs from '@/api/repositories/v1/checking-acts';
import apiLicenses from '@/api/repositories/v1/licenses';
import apiGpr from '@/api/repositories/v2/gpr';
import apiGprJobs from '@/api/repositories/v2/gpr-jobs';
import apiDirsV2 from '@/api/repositories/v2/dirs';
import apiPhotosV2 from '@/api/repositories/v2/photos';
import apiPlansV2 from '@/api/repositories/v2/floor-plans';
import apiComments from '@/api/repositories/v2/comments';
import apiFacades from '@/api/repositories/v2/facades';
import apiActivity from '@/api/repositories/v2/activity';
import apiDefiningPoints from '@/api/repositories/v2/defining-points';
import apiEnv from '@/api/repositories/v1/env';
import apiProjectsV2 from '@/api/repositories/v2/projects';
import apiBim from '@/api/repositories/v2/bim';
import apiTasksV2 from '@/api/repositories/v2/tasks';
import errorComposer from '@/api/error-handler';
import {clean} from '@/utils/cleaner';

import users from '@/api/repositories/v2/users'
import points from '@/api/repositories/v1/points'
import documents from '@/api/repositories/v2/documents'
import other from '@/api/repositories/other'
import autodesk from '@/api/repositories/v2/autodesk'
import organizations from '@/api/repositories/v2/organizations'
import mailings from '@/api/repositories/v2/mailings'
import roles from '@/api/repositories/v2/roles'
import account from '@/api/repositories/v2/account'
import services from '@/api/repositories/v2/services'
import files from '@/api/repositories/v2/files'
import ml from '@/api/repositories/v2/ml'
import notifications from '@/api/repositories/v2/notifications'
import common from '@/api/repositories/v2/common'
import contact from '@/api/repositories/v1/contact'
import contactV2 from '@/api/repositories/v2/contact'
import projectGroups from '@/api/repositories/v1/project-groups'
import floorsV2 from '@/api/repositories/v2/floors'
import streetFalcon from '@/api/repositories/v1/street-falcon'
import payments from '@/api/repositories/v1/payments'

export default {
  createAxiosInstance(prefix=process.env.VUE_APP_API_PREFIX,apiVersion = process.env.VUE_APP_API_VERSION, baseUrl) {
    return axios.create({
      baseURL: `${baseUrl ?? process.env.VUE_APP_HOSTNAME}/${prefix}/${apiVersion}/`,
      headers: {
        'Access': 'application/json',
        'Accept-Language': 'ru',
        'X-Requested-With': 'XMLHttpRequest',
        'Terra-Client-App-Location': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Terra-Client-App-Platform': 'Web',
        'Terra-Client-App-Version': `v${process.env.VUE_APP_VERSION}`
      },

      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'comma'});
      },
      mode: 'cors',
      handleErrorGlobally: true
    });
  },
  initAxiosInterceptors(axiosInstance, store, router) {
    axiosInstance.interceptors.request.use(async config => {
      if (config.data instanceof FormData) {
        clean(config.data);
      }

      const tokenName = 'token';
      const token = Cookie.get(tokenName);

      if (token) {
        config.headers.common['Authorization'] = `Bearer ${token}`;
      }

      return config;

    }, async error => {
      return Promise.reject(error);
    });

    axiosInstance.interceptors.response.use(async response => {
      return response;
    }, async (error) => {
      let code = error.response && error.response.status;
      if(error.__CANCEL__) return Promise.reject('cancel');

      let failedUrl = new URL(error.response?.config?.url, error.response?.config?.baseURL);
      if (code === 401) {
        if (failedUrl.pathname === '/api/v1/auth/login' || failedUrl.pathname === '/api/v1/auth/login-by-token') {
          return;
        }
        let refreshToken = Cookie.get('refresh_token');
        if (failedUrl.pathname === '/api/v1/auth/refresh' || !refreshToken) {
          await store.dispatch('auth/RESET_PROFILE');
          router.push({name: 'auth.signin'});
        }
        await store.dispatch('auth/REFRESH_TOKEN', ({refresh_token: refreshToken}));

        const config = error.response.config;
        const token = Cookie.get('token');

        config.headers['Authorization'] = `Bearer ${token}`;

        try {
          return await axiosInstance.request(config);
        } catch (error) {
          return error;
        }
      }

      if (failedUrl.pathname === '/api/v2/activity') {
        if (code >= 400) {
          return;
        }
      }

      error.globalHandler = errorComposer(error);

      if (error.config && error.config.handleErrorGlobally) {
        error.globalHandler();
      }

      return Promise.reject(error);
    });
  },
  install(Vue, store, router) {
    const basicInstance = this.createAxiosInstance('', '');
    this.initAxiosInterceptors(basicInstance, store, router)

    const basicApprovalInstance = this.createAxiosInstance('api', '', process.env.VUE_APP_APPROVAL_SERVICE);
    this.initAxiosInterceptors(basicApprovalInstance, store, router)

    const apiAxiosInstance = this.createAxiosInstance('api','v1');
    this.initAxiosInterceptors(apiAxiosInstance, store, router);

    const apiAxiosInstanceV2 = this.createAxiosInstance('api','v2')
    this.initAxiosInterceptors(apiAxiosInstanceV2, store, router);

    const repositories = {
      project: apiProject(apiAxiosInstance),
      projects: apiProjects(apiAxiosInstance),
      dirs: apiDirs(apiAxiosInstance),
      houses: apiBuildings(apiAxiosInstance),
      floors: apiFloors(apiAxiosInstance),
      floorPlans: apiPlans(apiAxiosInstance),
      rooms: apiRooms(apiAxiosInstance),
      photos: apiPhotos(apiAxiosInstance),
      ml: apiMl(apiAxiosInstance, store),
      planItems: apiPlanItems(apiAxiosInstance),
      planItemsStatistics: apiPlanItemsStatistics(apiAxiosInstance),
      auth: apiAuth(apiAxiosInstance),
      approval: apiApproval(basicApprovalInstance),
      users: apiUsers(apiAxiosInstance),
      organizations: apiOrganizations(apiAxiosInstance),
      roles: apiRoles(apiAxiosInstance),
      permissions: apiPermissions(apiAxiosInstance),
      eventLogs: apiEventLogs(apiAxiosInstance),
      heatMap: apiHeatMap(apiAxiosInstance),
      tasks: apiTasks(apiAxiosInstance),
      roomsPlanItems: apiRoomsPlanItems(apiAxiosInstance),
      techRooms: apiTechRooms(apiAxiosInstance),
      buildingStandards: apiBuildingStandards(apiAxiosInstance),
      marks: apiRecognitionHistoryMarks(apiAxiosInstance),
      checkingActs: apiCheckingActs(apiAxiosInstance),
      licenses: apiLicenses(apiAxiosInstance),
      gpr: apiGpr(apiAxiosInstanceV2),
      contact: contact(apiAxiosInstance),
      contactV2: contactV2(apiAxiosInstanceV2),
      gprJobs: apiGprJobs(apiAxiosInstanceV2),
      dirsV2: apiDirsV2(apiAxiosInstanceV2),
      photosV2: apiPhotosV2(apiAxiosInstanceV2),
      floorPlansV2: apiPlansV2(apiAxiosInstanceV2),
      comments: apiComments(apiAxiosInstanceV2),
      facades: apiFacades(apiAxiosInstanceV2),
      definingPoints: apiDefiningPoints(apiAxiosInstanceV2),
      activity: apiActivity(apiAxiosInstanceV2),
      projectsV2: apiProjectsV2(apiAxiosInstanceV2),
      tasksV2: apiTasksV2(apiAxiosInstanceV2),
      env: apiEnv(apiAxiosInstance),
      bim: apiBim(apiAxiosInstanceV2),

      points: points(apiAxiosInstance),
      documents: documents(apiAxiosInstanceV2),
      other: other(basicInstance),
      usersV2: users(apiAxiosInstanceV2),
      autodesk: autodesk(apiAxiosInstanceV2),
      organizationsV2: organizations(apiAxiosInstanceV2),
      mailings: mailings(apiAxiosInstanceV2),
      rolesV2: roles(apiAxiosInstanceV2),
      account: account(apiAxiosInstanceV2),
      services: services(apiAxiosInstanceV2),
      files: files(apiAxiosInstanceV2),
      mlV2: ml(apiAxiosInstanceV2),
      notifications: notifications(apiAxiosInstanceV2),
      common: common(apiAxiosInstanceV2),
      projectGroups: projectGroups(apiAxiosInstance),
      floorsV2: floorsV2(apiAxiosInstanceV2),
      streetFalcon: streetFalcon(apiAxiosInstance),
      payments: payments(apiAxiosInstance)
    };

    Vue.prototype.$api = repositories;
    Vue.prototype.$axios = basicInstance;

    store.$api = repositories;
  }
};
