import objectHash from 'object-hash'
import { toSnakeCase, toCapitalCase } from '@/utils/format'
import { then } from '@/utils/immutable'

export const loadable = ({ name, action: actionKey }) => function() {
  const keys = {
    state: `${name || actionKey}Loading`,
    mutation: `set_${toSnakeCase(name || actionKey)}_loading`.toUpperCase()
  }

  const action = this.actions[actionKey]

  return {
    state: { [keys.state]: false },
    getters: { [keys.state]: state => state[keys.state] },
    mutations: { [keys.mutation]: (state, value) => state[keys.state] = value },
    actions: { [actionKey]: function(context, payload) {
      const { independent } = payload || {}

      return (context.commit(keys.mutation, !independent) || true) && action.call(this, context, payload).finally(() => context.commit(keys.mutation, false)) }
    }
  }
}

export const resourceable = ({ name, from, initial = [], once, after, resetBeforeFetch = false }) => function() {
  const keys = {
    state: name,
    mutation: `set_${name}`.toUpperCase(),
    action: `fetch${toCapitalCase(name)}`,
    dispose: `dispose${toCapitalCase(name)}`,
    cache: `_${name}`
  }

  return {
    mixins: [
      loadable({ name, action: keys.action })
    ],
    state: { [keys.state]: initial },
    getters: { [keys.state]: state => state[keys.state] },
    mutations: { [keys.mutation]: (state, value) => state[keys.state] = value },
    actions: { 
      [keys.action]: async function(context, payload = {}) {
        if (once && context.state[keys.cache] === objectHash(payload) && !payload._force) {
          return context.state[keys.state]
        }

        if (once) {
          context.state[keys.cache] = objectHash(payload)
        }

        const ctx = { 
          api: this.$api, 
          commit: context.commit, 
          state: context.state, 
          getters: context.rootGetters,
          dispatch: context.dispatch,
          payload 
        }

        resetBeforeFetch && context.commit(keys.mutation, initial)

        const result = await from(ctx, payload)

        context.commit(keys.mutation, result)

        after?.(ctx, payload)

        return result
      },
      ...then(once, () => ({
        [keys.dispose]: function(context) {
          delete context.state[keys.cache]
        }
      }))
    }
  }
}

export const pagenable = ({ name, from, initial = [], after, page = 1, size = 10, total = 10 }) => function() {
  const keys = {
    stateData: name,
    statePagination: `${name}Pagination`,
    mutationData: `set_${name}`.toUpperCase(),
    mutationPagination: `set_${name}_page`.toUpperCase(),
    actionFetch: `fetch${toCapitalCase(name)}`,
    actionPaginate: `paginate${toCapitalCase(name)}`
  }

  return {
    mixins: [
      loadable({ name, action: keys.actionFetch })
    ],
    state: { 
      [keys.stateData]: initial, 
      [keys.statePagination]: { page, size, total } 
    },
    getters: { 
      [keys.stateData]: state => state[keys.stateData], 
      [keys.statePagination]: state => state[keys.statePagination] 
    },
    mutations: { 
      [keys.mutationData]: (state, data) => { 
        state[keys.stateData] = data
      },
      [keys.mutationPagination]: (state, { page, size, total }) => { 
        state[keys.statePagination]['page'] = page ?? state[keys.statePagination]['page']
        state[keys.statePagination]['size'] = size ?? state[keys.statePagination]['size']
        state[keys.statePagination]['total'] = total ?? state[keys.statePagination]['total']
      }
    },
    actions: { 
      [keys.actionFetch]: async function(context, payload = {}) {
        const ctx = { 
          api: this.$api, 
          getters: context.rootGetters,
          commit: context.commit,
          payload, 
          page: context.state[keys.statePagination]['page'],
          size: context.state[keys.statePagination]['size'],
          total: context.state[keys.statePagination]['total']
        }

        const result = await from(ctx, payload)
    
        context.commit(keys.mutationData, result['data'] || [])
        context.commit(keys.mutationPagination, 
          [result['meta'] || {}].map(({ current_page, last_page, per_page, total }) => ({ page: current_page < last_page ? current_page : last_page, size: Number(per_page), total }))[0]
        )

        after?.(ctx, payload)

        return result
      },
      [keys.actionPaginate]: async function(context, payload) {
        context.commit(keys.mutationPagination, payload)
      }
    }
  }
}

export const actionable = ({ name, at, loadable: asLoadable, before, after } = {}) => function() {
  const keys = {
    action: name
  }

  return {
    mixins: [
      asLoadable && loadable({ name, action: keys.action })
    ].filter(x => x),
    actions: { 
      [keys.action]: async function(context, value) {
        const ctx = {
          api: this.$api,
          getters: context.rootGetters,
          state: context.state,
          commit: context.commit,
          dispatch: context.dispatch
        }

        before?.(ctx, value)

        const r = await at(ctx, value)

        after?.(ctx, value)

        return r
      }
    }
  }
}

export const toggleable = ({ name, initial = false, on = 'enable', off = 'disable', postfix = '' }) => function() {
  const keys = {
    state: name + postfix,
    set: `set_${toSnakeCase(name + postfix)}`.toUpperCase(),
    enable: `${on}${toCapitalCase(name)}`,
    disable: `${off}${toCapitalCase(name)}`
  }

  return {
    state: { [keys.state]: initial },
    getters: { [keys.state]: state => state[keys.state] },
    mutations: { [keys.set]: (state, value) => state[keys.state] = value },
    actions: {
      [keys.enable]: context => context.commit(keys.set, true),
      [keys.disable]: context => context.commit(keys.set, false)
    }
  }
}

export const openable = (name, initial = false) => function() {
  return {
    mixins: [
      toggleable({ name, initial, on: 'open', off: 'close', postfix: 'Opened'})
    ]
  }
}

export const evantable = name => function() {
  const keys = {
    state: name,
    getter: `from${toCapitalCase(name)}`,
    mutation: `set_${toSnakeCase(name)}`.toUpperCase(),
    action: `to${toCapitalCase(name)}`
  }

  return {
    state: { [keys.state]: {} },
    getters: { [keys.getter]: state => state[keys.state] },
    mutations: { [keys.mutation]: (state, value) => state[keys.state] = value },
    actions: { [keys.action]: (context, value) => context.commit(keys.mutation, value) }
  }
}
