import { actionable, pagenable, resourceable } from '@/store/mixins'
import { filterObjectByKeys } from '@/utils/immutable'

const patchPayment = (a, b) => ({
  ...a,
  ...b,
  ...filterObjectByKeys(a, ['object', 'subject', 'recipient', 'users'])
})

export default {
  namespaced: true,
  mixins: [
    pagenable({
      name: 'payments',
      from: ({ api }, { filter }) => api.payments.getPayments({ filter })
        .then(r => r.data.data || {})
    }),

    resourceable({
      name: 'paymentsCount',
      initial: 0,
      from: ({ api }, { filter }) => api.payments.getPaymentsCount({ filter })
        .then(r => r.data.data || {})
        .then(({ count }) => count || 0)
    }),

    actionable({
      name: 'updatePayment',
      loadable: true,
      before: ({ state }, { payment }) => state.loadingByPayment = { ...state.loadingByPayment, [payment.id]: true },
      after: ({ state }, { payment }) => state.loadingByPayment = { ...state.loadingByPayment, [payment.id]: false },
      at: ({ api, state }, { payment, status }) => api.payments.updatePayment({ payment, status })
        .then(r => r.data.data || {})
        .then(r => {
          state.payments = state.payments.map(x => x.id === r.id ? patchPayment(x, r) : x)
        })
    }),

    actionable({
      name: 'blockPayment',
      loadable: true,
      before: ({ state }, payment) => state.loadingByPayment = { ...state.loadingByPayment, [payment.id]: true },
      after: ({ state }, payment) => state.loadingByPayment = { ...state.loadingByPayment, [payment.id]: false },
      at: ({ api, state }, payment) => api.payments.blockPayment({ payment })
        .then(r => r.data.data || {})
        .then(r => {
          state.payments = state.payments.map(x => x.id === r.id ? patchPayment(x, r) : x)
        })
    }),

    actionable({
      name: 'unblockPayment',
      loadable: true,
      before: ({ state }, payment) => state.loadingByPayment = { ...state.loadingByPayment, [payment.id]: true },
      after: ({ state }, payment) => state.loadingByPayment = { ...state.loadingByPayment, [payment.id]: false },
      at: ({ api, state }, payment) => api.payments.unblockPayment({ payment })
        .then(r => r.data.data || {})
        .then(r => {
          state.payments = state.payments.map(x => x.id === r.id ? patchPayment(x, r) : x)
        })
    })
  ],
  state: {
    loadingByPayment: {}
  },
  getters: {
    isPaymentLoading: state => payment => state.loadingByPayment[payment.id]
  }
}
