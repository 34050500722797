<template>
    <el-form-item :label="label"
                  :prop="name"
                  class="reset-el-form-item">
        <tag-group :value="value"
                   :data="options"
                   :value-key="valueKey"
                   :multiple="multiple"
                   @change="change">
            <template #default="{ item, selected }">
                <!-- With tooltip -->
                <el-tooltip v-if="item.tooltip"
                            :content="item.tooltip"
                            :enterable="false">
                    <tag :label="item[labelKey]"
                         :active="selected" />
                </el-tooltip>

                <!-- Without tooltip -->
                <tag v-else
                     :label="item[labelKey]"
                     :active="selected" />
            </template>
        </tag-group>
    </el-form-item>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: [Array, Object, String], default: () => [] },
    options: { type: Array, default: () => [] },
    name: { type: String, default: null },
    label: { type: String, default: null },
    placeholder: { type: String, default: null },
    valueKey: { type: String, default: 'value' },
    labelKey: { type: String, default: 'label' },
    multiple: { type: Boolean, default: false }
  },
  computed: {
    items() {
      return this.options
    }
  },
  methods: {
    change(x) {
      this.$emit('change', x)
    }
  }
}
</script>
