import { resourceable, pagenable, loadable, actionable } from '../mixins'
import { fromError } from '@/utils/common'

export default {
  namespaced: true,
  mixins: [
    pagenable({
      name: 'organizations',
      size: 24,
      from: ({ api, page, size }, { name, projects, onlyBlockedOrganizations, withProjects }) => 
        api.organizationsV2.getOrganizations({ page, size, name, projects, onlyBlockedOrganizations, withProjects })
          .then(x => x?.data?.data || {})
    }),

    resourceable({ 
      name: 'organization', 
      from: ({ api, getters }, { organization, byProject }) => [
        byProject && (() => api.organizationsV2.getOrganization({ project: getters['project/project'], organization })),
        !byProject && (() => api.organizations.show(organization))
      ].find(x => x)().then(x => x?.data?.data)
    }),

    resourceable({
      name: 'organizationUsers',
      from: ({ api, payload: { organization } = {} }) =>
        api.usersV2.getUsersByOrganization({ organization })
          .then(x => x?.data?.data?.data || [])
    }),

    pagenable({ 
      name: 'projectOrganizations',
      size: 24,
      from: ({ api, getters, page, size, payload: { search, type, onlyBlockedOrganizations, sort } = {} }) => 
        api.organizationsV2.getProjectOrganizations({ 
          project: getters['project/project'], 
          page, 
          size, 
          search, 
          type,
          onlyBlockedOrganizations,
          sort 
        })
          .then(x => x?.data?.data || {})
    }),

    resourceable({ 
      name: 'organizationsForSuggest',
      from: ({ api, getters, payload: { name, inn, kpp, search, byProject, exceptProject } = {} }) => 
        api.organizationsV2.getOrganizationsForSuggest({ 
          name, 
          inn,
          kpp,
          search, 
          project: byProject ? getters['project/project'] : null,
          exceptProject: exceptProject ? getters['project/project'] : null
        }) 
    }),

    resourceable({
      name: 'projectOrganizationsForSuggest'
    }),

    resourceable({
      name: 'roles',
      from: ({ api }) => api.organizationsV2.getOrganizationsRoles()
    }),

    actionable({
      name: 'storeOrganization',
      loadable: true,
      at: ({ api }, { organization, project, paymentId }) =>
        api.organizationsV2.storeOrganization({ organization, project, paymentId })
          .catch(fromError)
    }),

    actionable({
      name: 'inviteOrganization',
      loadable: true,
      at: ({ api, getters }, { organization, project, paymentId }) =>
        api.organizationsV2.inviteOrganization({ organization, project: project || getters['project/project'], paymentId })
          .catch(fromError)
    }),

    loadable({ action: 'updateOrganization' }),
    loadable({ action: 'removeOrganization' })
  ],
  actions: {
    removeOrganization: function(_, { organization }) {
      return this.$api.organizations.destroy(organization)
    },

    updateOrganization: async function ({ rootGetters: getters }, { organization, byProject }) {
      await [
        byProject && (() => this.$api.organizationsV2.updateOrganization({ project: getters['project/project'], organization })),
        !byProject && (() => this.$api.organizations.update(organization))
      ].find(x => x)().then(x => x?.data?.data)
    },

    fetchOrganization: async function({ commit }, { id }) {
      commit('SET_ORGANIZATION', await this.$api.organizations.show({ id }))
    },
    getOrganizations: async function (context, payload = null) {
      let {data} = await this.$api.organizations.index(payload);
      return data;
    },
    destroyOrganization: async function (context, payload) {
      let {data} = await this.$api.organizations.destroy(payload);
      return data;
    },
    restoreOrganization: async function (context, id) {
      let {data} = await this.$api.organizations.restore(id);
      return data;
    }
  }
};

