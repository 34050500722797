import { planTypes } from '@/models/plans'
import { filterObjectByKeys } from '@/utils/immutable'

export const definePlanFilter = ({ planType = planTypes.Common, mailingId, uiHide } = {}) => filterObjectByKeys({
  withCamera: true,
  withQopter: true,
  withFacade: true,
  withPhoto: true,
  withDefect: true,
  withFinishedDefect: false,
  withPolygons: false,

  byDefects: false,
  byFilterPassedOnly: uiHide || (get('byFilterPassedOnly', 'false', { cookie: true }) === 'true'),
  with_finished_tasks: false,
  with_finished_not_accepted_tasks: false,

  polygonIds: [],
  workTypes: [],
  withInspector: true,
  withoutInspector: true,
  withWorkType: true,
  withoutWorkType: true,
  withAcceptable: false,
  mailingId
}, [
  ...(planType === planTypes.Common || planType === planTypes.Tech) && [
    'withCamera',
    'withQopter',
    'withFacade',
    'withPhoto',
    'withDefect',
    'withFinishedDefecte',
    'withPolygons',

    'byDefects',
    'byFilterPassedOnly',
    'with_finished_tasks',
    'with_finished_not_accepted_tasks' 
  ] || [],
  ...(planType === planTypes.Work) && [
    'polygonIds',
    'workTypes',
    'withInspector',
    'withoutInspector',
    'withWorkType',
    'withoutWorkType',
    'mailingId'
  ] || []
])
