<template>
    <div v-if="hasApprovalInfo"
         v-loading="loading">
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 class="space-y-0.5"
                 label-position="top">
            <!-- ProcessType -->

            <el-form-item v-if="!isCreator"
                          class="f-row f-v-center"
                          label="Тип согласования">
                <el-radio-group
                    v-model="processType"
                    class="_ml-1">
                    <el-radio-button :disabled="isProcessTypeDisabled"
                                     :label="1">
                        Параллельный
                    </el-radio-button>
                    <el-radio-button :disabled="isProcessTypeDisabled"
                                     :label="2">
                        Последовательный
                    </el-radio-button>
                </el-radio-group>
            </el-form-item>

            <!-- Document -->
            <document-thumb :document="document"
                            :name="documentName"
                            with-icon
                            with-version
                            with-type>
                <template #header>
                    <p v-if="approvalInfo?.is_izm"
                       class="_m-0 fs-0.9 font-bold text-gray-600">
                        Изм.
                    </p>
                </template>
            </document-thumb>

            <!-- Chapter -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="documentChapter"
                             class="reset-el-form-item"
                             label="Раздел"
                             placeholder="Раздел не указан"
                             size="mini"
                             readonly />
            </access>

            <!-- Code -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="documentCode"
                             class="reset-el-form-item"
                             label="Шифр"
                             placeholder="Шифр не указан"
                             size="mini"
                             readonly />
            </access>

            <!-- Version -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="documentVersion"
                             class="reset-el-form-item"
                             label="Версия"
                             placeholder="Версия не указана"
                             size="mini"
                             readonly />
            </access>

            <!-- Creator -->
            <access v-if="processInitiator"
                    :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <user-thumb :user="processInitiator"
                            align="center"
                            with-avatar
                            with-organization />
            </access>

            <!-- Created at -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="getCreatedAtLabel(task, { withCapital: true })"
                             class="reset-el-form-item"
                             label="Дата загрузки"
                             size="mini"
                             readonly />
            </access>

            <!-- Place -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <input-field :value="projectNameWithPath"
                             class="reset-el-form-item"
                             label="Привязан к объекту"
                             size="mini"
                             readonly />
            </access>

            <!-- Relations -->
            <access v-if="accompanyingDocs.length"
                    :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <el-form-item label="Сопроводительные документы"
                              class="reset-el-form-item">
                    <div>
                        <document-thumb v-for="x in accompanyingDocs"
                                        :key="x.id"
                                        :document="x"
                                        size="mini"
                                        with-icon />
                    </div>
                </el-form-item>
            </access>

            <!-- Project designer -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <el-form-item label="Проектировщик">
                    <user-thumb :user="taskCreator"
                                align="center"
                                with-avatar
                                with-organization />
                </el-form-item>
            </access>

            <!-- Creator -->
            <access :permissions="permissionsAny('project_approval_process_be_project_designer', 'project_approval_process_be_document_approver')"
                    hidable>
                <el-form-item label="Загрузил документ">
                    <user-thumb :user="documentAuthor"
                                align="center"
                                with-avatar
                                with-organization />
                </el-form-item>
            </access>

            <!-- Approve at -->
            <access permissions="project_approval_process_be_chief_project_engineer"
                    hidable>
                <date-field v-model="form.approvalEnd"
                            :disabled="!isEditableField('approval_end')"
                            name="approvalEnd"
                            label="Срок согласования"
                            size="mini" />
            </access>

            <!-- Approvers -->
            <access permissions="project_approval_process_be_chief_project_engineer"
                    hidable>
                <div class="space-y-0.5">
                    <user-field v-for="(approver, i) in form.approvers"
                                :key="approver.id"
                                class="reset-el-form-item"
                                :name="isDisabledApprover(approver) ? null : 'approvers'"
                                :value="approver.user"
                                :disabled="!isEditableField('approvers') || isDisabledApprover(approver)"
                                :is-document-approver="isEditableField('approvers') && !isDisabledApprover(approver)"
                                @change="id => changeApproverUser(approver, id)">
                        <template #label>
                            <span>{{ i === 0 ? 'Проектировщик': (processInitiator && approver?.user?.id === processInitiator?.id) ? 'ГИП' : 'Согласующий' }}</span>

                            <span v-if="i > 1 && isTaskNotInitialized && (processInitiator && approver?.user?.id !== processInitiator?.id)"
                                  class="text-accent cursor-pointer"
                                  @click="removeApprover(approver)">Удалить</span>
                        </template>
                    </user-field>
                </div>
            </access>
            <!-- Add approver -->
            <el-button
                v-if="isGip && isTaskNotInitialized"
                type="primary"
                class="_mt-0.5"
                size="mini"
                icon="el-icon-plus"
                plain
                @click="addApprover">
                Добавить
            </el-button>

            <!-- Comment (at review) -->
            <access v-if="isCanApprov"
                    hidable>
                <div>
                    <input-field v-model="form.comment"
                                 label="Комментарий"
                                 placeholder="Введите комментарий..."
                                 type="textarea" />

                    <el-upload v-if="!form.commentFile"
                               v-model="form.commentFile"
                               action=""
                               :accept="'.xlsx, .xls, .doc, .docx, .pdf'"
                               type="file"
                               :show-file-list="false"
                               :auto-upload="false"
                               :multiple="false"
                               :on-change="handleUploadCommentFile"
                               :limit="1">
                        <div style="display: flex; align-items: center; width: 100%; justify-content: flex-start;">
                            <el-tooltip v-if="!form.comment"
                                        content="Введите комментарий, чтобы иметь возможность подгрузить файл"
                                        placement="right">
                                <el-button
                                    type="primary"
                                    :disabled="!form.comment">
                                    Выбрать файл
                                </el-button>
                            </el-tooltip>

                            <el-button v-else-if="form.comment"
                                       type="primary">
                                Выбрать файл
                            </el-button>
                        </div>
                    </el-upload>

                    <div v-if="form.commentFile"
                         class="wrapper"
                         @mouseover="hoveredFile = form.commentFile"
                         @mouseleave="hoveredFile = null">
                        <div style="display: flex; align-items: center; gap: 10px;">
                            <div class="icon">
                                <icon
                                    :name="getIcon(form.commentFile)"
                                    class="w-3" />
                            </div>
                            <small>{{ form.commentFile.name }}</small>
                            <div class="icon">
                                <el-button v-if="!hoveredFile"
                                           icon="el-icon-circle-check"
                                           class="icon_green"
                                           type="text" />
                                <el-button v-if="hoveredFile"
                                           icon="el-icon-close"
                                           type="text"
                                           @click="deleteCommentFile" />
                            </div>
                        </div>
                    </div>
                </div>
            </access>
        </el-form>

        <!-- Actions -->
        <div class="space-y-0.5 _mt-1">
            <!-- Init process -->
            <access v-if="isTaskNotInitialized"
                    permissions="project_approval_process_be_chief_project_engineer"
                    hidable>
                <el-button
                    type="primary"
                    class="w-full"
                    size="small"
                    @click="initProcess">
                    {{ isGip && !hasCreatedApprovers ? 'В производство работ' : 'Создать процесс' }}
                </el-button>
            </access>

            <!-- Approval actions -->
            <access v-if="isCanApprov && !isTaskNotInitialized"
                    hidable>
                <div class="f">
                    <!-- Approve -->
                    <el-button
                        style="flex: 1"
                        type="primary"
                        class="text-size--body"
                        size="small"
                        @click="approve">
                        {{ isGip ? 'В производство работ' : 'Согласовать' }}
                    </el-button>

                    <!-- Reject -->
                    <el-button
                        style="flex: 1"
                        type="danger"
                        :body-style="{ margin: 0 }"
                        class="text-size--body"
                        size="small"
                        @click="reject">
                        Отклонить
                    </el-button>
                </div>
            </access>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { loadable } from '@/store/connectors'

import { getCreatedAtLabel } from '@/models/tasks'
import { getEditableFields, statuses } from '@/models/approval'

import { parse } from '@/utils/date'

import InputField from '@/components/fields/InputField'
import DateField from '@/components/fields/DateField'
import UserField from '@/components/fields/UserField'
import DocumentThumb from '@/components/documents/DocumentThumb'
import UserThumb from '@/components/users/UserThumb'
import { dateFormat } from '@/utils/datetime'
import { extensionIcon } from '@/utils/ui'

export default {
  components: {
    InputField,
    DateField,
    UserField,
    DocumentThumb,
    UserThumb
  },
  mixins: [
    loadable({ on: 'approval', name: 'initApprovalProcess' }),
    loadable({ on: 'approval', name: 'updateProcessByApprover' }),
    loadable({ on: 'approval', name: 'saveComment' }),
    loadable({ on: 'approval', name: 'updateCommentFile' })
  ],
  props: {
    task: { type: Object, default: null }
  },
  data() {
    return {
      form: {
        approvalEnd: null,
        approvers: [{ id: key() }],
        deleteApprovers: [],
        comment: null,
        commentFile: null
      },
      hoveredFile: null,
      processType: 1,

      getCreatedAtLabel,
      permissionsAny
    };
  },
  computed: {
    ...mapGetters('project', ['projectName']),
    ...mapGetters('approval', [
      'hasApprovalInfo',
      'currentIteration',
      'currentApprover',
      'iterations',
      'approvalInfo',
      'approvalDocument',
      'isGip',
      'isApprover',
      'isCreator'
    ]),
    ...mapGetters('auth', ['profile']),
    ...mapGetters('users', ['usersForSuggest']),
    loading() {
      return this.initApprovalProcessLoading || this.updateProcessByApproverLoading || this.saveCommentLoading
    },
    classes() {
      return {}
    },
    rules() {
      return {
        approvalEnd: {
          required: this.isRequiredField('approval_end'),
          message: 'Выберите дату'
        },
        approvers: {
          required: this.isRequiredField('approvers'),
          validator: (_rule, _value, callback) => {
            if (this.isGip || this.hasCreatedApprovers) {
              callback()
            } else {
              callback(new Error('Укажите пользователей согласования'))
            }
          }
        }
      }
    },
    taskId() {
      return this.task?.id
    },
    taskCreator() {
      return this.task?.creator
    },
    processName() {
      return this.approvalInfo?.process_name
    },
    processInitiator() {
      return this.approvalInfo?.initiator
    },
    documentChapter() {
      return this.approvalDocument?.chapter
    },
    documentCode() {
      return this.approvalDocument?.code
    },
    document() {
      return {
        ...this.approvalDocument,
        version: this.documentVersion
      };
    },
    documentVersion() {
      return this.currentIteration?.document_version;
    },
    documentAuthor() {
      return this.approvalDocument?.author
    },
    documentName() {
      return this.currentIteration?.document_name
    },
    accompanyingDocs() {
      return this.currentIteration?.accompanying_documents || []
    },
    isProcessTypeDisabled() {
      return this.approvalInfo?.process_type !== null;
    },


    isNoFirstIteration() {
      return this.iterations.length > 1
    },
    isTaskNotInitialized() {
      return this.approvalInfo?.process_status === statuses.NotInitialized
    },
    isCurrentApprover() {
      return this.currentApprover;
    },
    isProcessParallel() {
      return this.approvalInfo?.process_type === 'Parallel'; // Sequential
    },
    isBtnsDisabled() {
      return (
        this.approvalInfo?.process_status !== 'on_review' ||
        !this.isCurrentApprover
      );
    },
    projectNameWithPath() {
      const path = this.approvalDocument?.structurables?.map(({structurable_type, structurable}) => this.createObjectPath(structurable_type, structurable));

      if(path) {
        return `${this.projectName}/${path}`
      }

      return `${this.projectName}`
    },
    isCanApprov() {
      return (
        (
          this.isGip &&
          this.approvalInfo.process_status !== 'canceled' &&
          this.approvalInfo.process_status !== 'stopped' &&
          !this.isBtnsDisabled
        )
        ||
        (
          this.isApprover &&
          this.approvalInfo.process_status !== 'canceled' &&
          this.approvalInfo.process_status !== 'stopped' &&
          !this.isBtnsDisabled
        )
      )
    },
    hasCreatedApprovers() {
      return !!this.form.approvers.filter(x => x._created && x.user).length
    },
    hasComment() {
      return !!this.form.comment && this.form.comment !== ''
    },
    errorMessage() {
      return !this.isGip && this.isCanApprov ? 'Невозможно согласовать или отклонить документ' : 'Отображаемая информации неактуальна';
    }
  },
  watch: {
    approvalInfo(x) {
      x && (this.form.approvalEnd = parse(this.approvalInfo.approval_end, { iso: true }));
      x && (this.form.approvers = (this.currentIteration?.approvers || []).map(user => ({ id: key(), user })));
      x && (this.processType = (this.isProcessParallel || this.approvalInfo?.process_type === null) ? 1 : 2);
    }
  },
  methods: {
    ...mapActions('approval', [
      'initApprovalProcess',
      'updateProcessByApprover',
      'saveComment',
      'updateCommentFile'
    ]),

    getIcon(file) {
      return extensionIcon(file.name)
    },

    isEditableField(name) {
      return !!getEditableFields(this.task)[name]
    },

    isRequiredField(name) {
      return !!getEditableFields(this.task)[name]?.required
    },

    addApprover() {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: [...approvers, { id: key(), _created: true }]
      }
    },

    removeApprover({ id }) {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        deleteApprovers: [...this.form.deleteApprovers, approvers.find(x => x.id === id)],
        approvers: approvers.filter(x => x.id !== id)
      }
    },

    changeApproverUser({ id }, user) {
      const { approvers } = this.form || {}

      this.form = {
        ...this.form,
        approvers: approvers.map(x => x.id === id ? { ...x, user } : x)
      }
    },

    isDisabledApprover(approver) {
      return !approver._created
    },

    initProcess() {
      this.$refs.form.validate(on => {
        if (!on) {
          return
        }

        this.initApprovalProcess({
          processId: this.taskId,
          payload: {
            initiator_id: this.profile.id,
            process_name: this.processName,
            process_type: this.processType,
            approval_start: new Date().toISOString(),
            approval_end: this.form.approvalEnd.toISOString(),
            approver_ids: this.form.approvers
              .filter(({ _created }) => _created)
              .map(({ user }) => user?.id)
          }
        })
          .then(dialogs.success.bind(this))
          .then(this.$emit.bind(this, 'close'))
          .catch(dialogs.failed.bind(this, { message: this.errorMessage }))
      })
    },

    handleUploadCommentFile(file) {
      this.form.commentFile = file.raw;
    },

    deleteCommentFile() {
      this.form.commentFile = null;
    },

    approve() {
      this.$refs.form.validate(async (on) => {
        if (!on) {
          return
        }

        let fileId = ''
        if (this.form.commentFile) {
          const result = await this.updateCommentFile(this.form.commentFile);
          fileId = result.data
        }

        if(this.hasComment) {
          await this.saveComment({
            text: this.form.comment,
            approver_id: this.profile.id,
            iteration_id: this.currentIteration?.id,
            file_id: this.form.commentFile ? fileId : null
          })
        }

        await this.updateProcessByApprover({
          processId: this.taskId,
          payload: {
            updated_at: this.approvalInfo.updated_at,
            approver_id: this.profile.id,
            approval_status: 'approved',
            need_approve_from_author_ids: this.form.approvers.filter(({ _created }) => _created).map(({ user }) => user?.id),
            delete_author_ids: this.form.deleteApprovers.map(({ user }) => user?.id) ?? []
          }
        })
          .then(dialogs.success.bind(this))
          .then(this.$emit.bind(this, 'close'))
          .catch(dialogs.failed.bind(this, { message: this.errorMessage }))
      })
    },

    reject() {
      this.$refs.form.validate(async (on) => {
        if (!on) {
          return
        }

        if (!this.hasComment) {
          dialogs.warning.call(this, { message: 'Введите комментарий' })
          return
        }

        let fileId = ''
        if (this.form.commentFile) {
          const result = await this.updateCommentFile(this.form.commentFile);
          fileId = result.data
        }

        await this.saveComment({
          text: this.form.comment,
          approver_id: this.profile.id,
          iteration_id: this.currentIteration?.id,
          file_id: this.form.commentFile ? fileId : null
        })

        await this.updateProcessByApprover({
          processId: this.taskId,
          payload: {
            updated_at: this.approvalInfo.updated_at,
            approver_id: this.profile.id,
            approval_status: 'canceled',
            need_approve_from_author_ids: this.form.approvers.filter(({ _created }) => _created).map(({ user }) => user?.id),
            delete_author_ids: this.form.deleteApprovers.map(({ user }) => user?.id) ?? []
          }
        })
          .then(dialogs.success.bind(this))
          .then(this.$emit.bind(this, 'close'))
          .catch(dialogs.failed.bind(this, { message: this.errorMessage }))
      })
    },

    createObjectPath(type, structure) {
      let path = '';
      const createHouseName = data => `${data.street} ${data.number}`;
      const createFloorName = data => `Этаж ${data.number}`;
      const createFloorPlanName = data => data.name ? data.name : `План от ${dateFormat(data.created_at, 'YYYY-MM-DD')}`;
      const createDefiningPointName = data => `Точка ${data.name}`;

      switch (type) {
      case 'house':
        path = createHouseName(structure);
        break;
      case 'floor':
        path = `${createHouseName(structure.house)} / ${createFloorName(structure)}`;
        break;
      case 'floor_plan':
        path = `${createHouseName(structure.floor.house)} / ${createFloorName(structure.floor)} / ${createFloorPlanName(structure)}`
        break;
      case 'defining_point':
        path = `${createHouseName(structure.floor_plan.floor.house)}
      / ${createFloorName(structure.floor_plan.floor)}
      / ${createFloorPlanName(structure.floor_plan)}
      / ${createDefiningPointName(structure)}`;
        break;
      default:
        break;
      }
      return path;
    }
  }
};
</script>
<style scoped lang="scss">
.wrapper {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  transition: background .3s;

  &:hover {
    background: #F5F7FA;
    transition: background .3s;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: #000;

  & .el-button {
    color: inherit;
  }

  &_green {
    color: #67C23A !important;
  }
}
</style>

