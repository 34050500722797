<template>
    <!--  
      TODO: If need to make popover scrollable need to add attribute  popper-class="scrollable"
    -->
    <el-popover v-model="opened"
                :placement="popoverPlacement"
                :width="preparedWidth">
        <el-form v-if="hasFields"
                 :label-position="labelPosition"
                 class="space-y-0.5"
                 @submit.native.prevent>
            <!-- Groups -->
            <div v-for="group in groups"
                 :key="group.key">
                <!-- Body -->
                <div :class="computeGroupClass(group)">
                    <!-- Fields -->
                    <div v-for="field in group.fields"
                         :key="field.key">
                        <!-- User -->
                        <user-field v-if="field.type === 'user'"
                                    class="reset-el-form-item"
                                    :value="from(field)"
                                    :label="field.label"
                                    :disabled="field.disabled"
                                    :placeholder="field.placeholder"

                                    :as-key="!getFieldProps(field).withOrganizations"

                                    :is-task-creator="getFieldProps(field).isTaskCreator"
                                    :is-contractor="getFieldProps(field).isContractor"
                                    :is-engineer="getFieldProps(field).isEngineer"
                                    :is-general-engineer="getFieldProps(field).isGeneralEngineer"
                                    :is-inspector="getFieldProps(field).isInspector"
                                    :is-photographer="getFieldProps(field).isPhotographer"
                                    :is-work-plan-administrator="getFieldProps(field).isWorkPlanAdministrator"
                                    :is-work-plan-supervisor="getFieldProps(field).isWorkPlanSupervisor"
                                    :is-work-plan-foreman="getFieldProps(field).isWorkPlanForeman"
                                    :is-project-designer="getFieldProps(field).isProjectDesigner"
                                    :is-chief-project-designer="getFieldProps(field).isChiefProjectDesigner"
                                    :is-document-approver="getFieldProps(field).isDocumentApprover"

                                    :with-organizations="getFieldProps(field).withOrganizations"
                                    :with-disabled-selection="getFieldProps(field).withDisabledSelection"

                                    @change="value => to(field, value)" />

                        <!-- Place -->
                        <place-field v-else-if="field.type === 'place'"
                                     class="reset-el-form-item"
                                     :value="from(field)"
                                     :label="field.label"
                                     :disabled="field.disabled"
                                     :placeholder="field.placeholder"

                                     :with-at-project="getFieldProps(field).withAtProject"

                                     @change="value => to(field, value)" />

                        <!-- Project -->
                        <project-field v-else-if="field.type === 'project'" 
                                       class="reset-el-form-item"
                                       :value="from(field)"
                                       :label="field.label"
                                       :disabled="field.disabled"
                                       :placeholder="field.placeholder"

                                       :as-key="getFieldProps(field).asKey"
                                       :multiple="getFieldProps(field).multiple"
                                       :clearable="getFieldProps(field).clearable"

                                       @change="value => to(field, value)" />

                        <organization-field v-else-if="field.type === 'organization'" 
                                            class="reset-el-form-item"
                                            :value="from(field)"
                                            :label="field.label"
                                            :disabled="field.disabled"
                                            :placeholder="field.placeholder"

                                            :as-key="getFieldProps(field).asKey"
                                            :multiple="getFieldProps(field).multiple"

                                            @change="value => to(field, value)" />

                        <!-- Checkbox -->
                        <checkbox-field v-else-if="field.type === 'checkbox'"
                                        :value="from(field)"
                                        :label="field.label"
                                        :disabled="field.disabled"
                                        :placeholder="field.placeholder"
                                        @change="value => to(field, value)" />

                        <!-- Tags -->
                        <tag-group-field v-else-if="field.type === 'tag'"
                                         :value="from(field)"
                                         :label="field.label"
                                         :disabled="field.disabled"
                                         :placeholder="field.placeholder"
                                         :options="field.options"
                                         :multiple="getFieldProps(field).multiple"
                                         @change="value => to(field, value)" />

                        <!-- Switch -->
                        <switch-field v-else-if="field.type === 'switch-new'"
                                      class="reset-el-form-item"
                                      :value="from(field)"
                                      :label="field.label"
                                      :disabled="field.disabled"
                                      @change="x => to(field, x)" />

                        <!-- Work polygon work status -->
                        <polygon-work-status-field v-else-if="field.type === 'polygon-work-status'" 
                                                   class="reset-el-form-item"
                                                   :value="from(field)"
                                                   :label="field.label"
                                                   :disabled="field.disabled"
                                                   :as-key="getFieldProps(field).asKey"
                                                   @change="x => to(field, x)" />

                        <!-- Old -->
                        <el-form-item v-else
                                      v-loading="field.loading"
                                      :label="field.label"
                                      class="reset-el-form-item">
                            <template
                                v-if="field.type ==='input' || field.type ==='input-like'">
                                <el-input :value="from(field)"
                                          clearable
                                          size="mini"
                                          :placeholder="field.placeholder || 'Ввести'"
                                          @input="event => to(field, event)" />
                            </template>
                            <template v-if="field.type==='select'">
                                <el-select :value="from(field)"
                                           clearable
                                           size="mini"
                                           class="width-full"
                                           placeholder="Выбрать"
                                           @input="value => to(field, value)">
                                    <el-option
                                        v-for="(option,index) in field.options"
                                        :key="index"
                                        :label="option.label"
                                        :value="option.value" />
                                </el-select>
                            </template>
                            <template v-if="field.type==='autocomplete'">
                                <el-select :value="from(field)"
                                           clearable
                                           filterable
                                           class="width-full"
                                           size="mini"
                                           placeholder="Выбрать"
                                           @input="value => to(field, value)">
                                    <el-option
                                        v-for="(option,index) in field.options"
                                        :key="index"
                                        :label="option.label"
                                        :value="option.value" />
                                </el-select>
                            </template>
                            <template v-if="field.type==='multiselect'">
                                <el-select :value="from(field)"
                                           clearable
                                           multiple
                                           size="mini"
                                           placeholder="Выбрать"
                                           class="w-full"
                                           @input="value => to(field, value)">
                                    <el-option
                                        v-for="(option,index) in field.options"
                                        :key="index"
                                        :label="option.label"
                                        :value="option.value" />
                                </el-select>
                            </template>
                            <template v-if="field.type==='daterange'">
                                <el-date-picker :value="from(field)"
                                                format="dd.MM.yyyy"
                                                type="daterange"
                                                align="right"
                                                unlink-panels
                                                size="mini"
                                                range-separator="До"
                                                start-placeholder="Начало"
                                                end-placeholder="Конец"
                                                :picker-options="pickerOptionsDateRange"
                                                @input="value => to(field, value)" />
                            </template>
                            <template v-if="field.type==='datetime'">
                                <el-date-picker :value="from(field)"
                                                format="dd.MM.yyyy"
                                                type="datetime"
                                                align="right"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                size="mini"
                                                placeholder="Выбрать"
                                                :picker-options="pickerOptionsDateTime"
                                                @input="value => to(field, value)" />
                            </template>
                            <template v-if="field.type==='date'">
                                <el-date-picker :value="from(field)"
                                                type="date"
                                                format="dd.MM.yyyy"
                                                :picker-options="{firstDayOfWeek:1}"
                                                size="mini"
                                                align="right"
                                                value-format="yyyy-MM-dd HH:mm:ss"
                                                placeholder="Выбрать"
                                                class="with-full-picker-width"
                                                @input="value => to(field, value)" />
                            </template>
                            <template v-if="field.type==='radio-group-button'">
                                <el-radio-group :value="from(field)" 
                                                @input="value => to(field, value)">
                                    <el-radio-button v-for="(option,index) in field.options"
                                                     :key="index"
                                                     :label="option.value">
                                        {{ option.label }}
                                    </el-radio-button>
                                </el-radio-group>
                            </template>
                            <template
                                v-if="field.type ==='switch'">
                                <el-switch :value="from(field)" 
                                           size="mini"
                                           :active-value="1"
                                           :inactive-value="null"
                                           @input="value => to(field, value)" />
                            </template>
                        </el-form-item>
                    </div>
                </div>
            </div>

            <!-- Actions -->
            <div>
                <!-- Clear -->
                <el-button v-if="clearable"
                           size="mini"
                           @click="clear">
                    Очистить
                </el-button>

                <!-- Confirm -->
                <el-button v-if="confirmable && !deferable"
                           type="primary"
                           size="mini"
                           :disabled="disabledFilterButton"
                           @click="() => confirm(value)">
                    Применить
                </el-button>
            </div>

            <!-- Footer -->
            <el-row v-if="$slots.footer">
                <el-col>
                    <slot name="footer" />
                </el-col>
            </el-row>
        </el-form>

        <!-- If no has fields -->
        <el-empty v-else
                  class="reset-el-empty wh-12"
                  :description="noFieldsDescription" />
        
        <!-- Button -->
        <template slot="reference">
            <el-badge slot="reference"
                      class="w-full"
                      :value="countResult"
                      :hidden="countResult === 0">
                <slot name="button">
                    <el-button 
                        :disabled="disabled"
                        :class="{'width-full':blockButton,'button-squared':buttonSquared}"
                        :plain="buttonPlain"
                        :type="buttonType"
                        size="mini"
                        icon="el-icon-s-operation">
                        {{ buttonLabel }}
                    </el-button>
                </slot>
            </el-badge>
        </template>
    </el-popover>
</template>

<script>
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns'
import locale from 'date-fns/locale/ru'
import filterFieldsValidator from '@/utils/filterFiledsValidator';

import { key } from '../../utils/immutable';

import UserField from '@/components/fields/UserField'
import PlaceField from '@/components/fields/PlaceField'
import CheckboxField from '@/components/fields/CheckboxField'
import TagGroupField from '@/components/fields/TagGroupField'
import SwitchField from '@/components/fields/SwitchField'
import ProjectField from '@/components/fields/ProjectField'
import OrganizationField from '@/components/fields/OrganizationField.vue'
import PolygonWorkStatusField from '@/components/fields/PolygonWorkStatusField'

export default {
  name: 'FilterPopover',
  components: {
    UserField,
    PlaceField,
    CheckboxField,
    TagGroupField,
    SwitchField,
    ProjectField,
    OrganizationField,
    PolygonWorkStatusField
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: { type: Object, default: () => ({}) },
    fields: { type: Array, required: true, validator: (props) => filterFieldsValidator(props) },
    loading: { type: Boolean, default: false },
    width: { type: String, default: '720' },
    fit: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    popoverPlacement: { type: String, default: 'left-start' },
    labelPosition: { type: String, default: 'top' },
    blockButton: { type: Boolean, default: false },
    buttonPlain: { type: Boolean, default: true },
    buttonType: { type: String, default: '' },
    buttonLabel: { type: String, default: 'Фильтр' },
    buttonSquared: { type: Boolean, default: false },
    disabledFilterButton: { type: Boolean, default: false },
    closeOnAccept: { type: Boolean, default: true },
    deferable: { type: Boolean, default: false },
    initial: { type: Object, default: () => ({}) },
    count: { type: [String, Number], default: null },
    confirmable: { type: Boolean, default: true },
    clearable: { type: Boolean, default: true },
    noFieldsDescription: { type: String, default: null }
  },
  data: () => ({
    opened: false,
    onReady: false,
    defering: false,

    pickerOptionsDateRange: {
      firstDayOfWeek: 1,
      shortcuts: [
        {
          text: 'Текущая неделя',
          onClick: element => element.$emit('pick', [startOfWeek(Date.now(), { locale }), endOfWeek(Date.now(), { locale })])
        },
        {
          text: 'Текущий месяц',
          onClick: element => element.$emit('pick', [startOfMonth(Date.now(), { locale }), endOfMonth(Date.now(), { locale })])
        }
      ]
    },
    pickerOptionsDateTime: {
      firstDayOfWeek: 1,
      shortcuts: [
        {
          text: 'Сегодня',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: 'Вчера',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: 'Неделю назад',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
    }
  }),
  computed: {
    hasFields() {
      return !!this.fields.length
    },

    preparedWidth() {
      return (this.fit || !this.hasFields) ? null : this.width
    },

    preparedFields() {
      return this.fields
        .map(x => ({
          key: key(),
          ...x
        }))
    },

    groups() {
      const fieldsByGroup = this.preparedFields.reduce((r, x) => ({
        ...r,
        [x.group || 'Все']: [...r[x.group || 'Все'] || [], x]
      }), {})

      const groups = Object.entries(fieldsByGroup).map(([label, fields]) => ({
        key: key(),
        label,
        fields
      }))

      return groups
    },

    countResult() {
      return this.count ?? Object.values(this.value || {})
        .filter(is)
        .filter(x => Array.isArray(x) ? x.length : true)
        .length
    }
  },
  mounted() {
    this.onReady = true
  },
  methods: {
    from(field) {
      return field.from ? field.from(this.value) : this.value[field.propName]
    },

    to(field, value) {
      const result = {
        ...this.value,
        ...field.to ? field.to(value) : { [field.propName]: value }
      }

      this.$emit('change', result)

      this.deferable && this.confirm(result)
    },

    clear() {
      this.$emit('change', this.initial)
      this.$emit('clear')
      this.confirm(this.initial)
    },

    computeGroupClass(group) {
      return group.fields.length > 1 ? 'cols-2 gap-1' : ''
    },

    getFieldProps(field) {
      return field.props || {}
    },

    async prepareToFilter(value) {
      let prepared = {};

      const field = key => this.preparedFields.find(({ propName }) => propName === key)

      Object.keys(value).forEach(key => {
        const type = field(key)?.type

        if (type === 'input-like') {
          prepared[key] = value[key] ? `%${value[key]}%` : null;
        } else {
          prepared[key] = value[key] ? value[key] : null;
        }
      });

      return prepared;
    },

    async confirm(value) {
      const prepared = await this.prepareToFilter(value)

      const withDefer = () => {
        this.defering = true

        defer('confirm-filter', () => {
          this.$emit('confirm', prepared)
          this.defering = false
          this.closeOnAccept && (this.opened = !this.opened)
        }, 1000)
      }

      const withoutDefer = () => {
        this.$emit('confirm', prepared)
        this.closeOnAccept && (this.opened = !this.opened)
      }

      this.deferable ? withDefer() : withoutDefer()
    }
  }
};
</script>
