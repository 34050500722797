<template>
    <div>
        <el-form :model="value"
                 class="plan-polygon-form space-y-0.5"
                 label-position="left"
                 label-width="120px">
            <!-- Work type -->
            <access :permissions="permissionsAny('project_floor_work_plan_be_administrator', 'project_floor_work_plan_be_supervisor', 'project_floor_work_plan_be_foreman', 'project_tasks_be_becomes_constructor', 'project_tasks_be_construction_control_engineer', 'project_tasks_be_general_control_engineer')"
                    hidable>
                <work-type-field
                    v-model="value.work_type_id"
                    class="reset-el-form-item"
                    :placeholder="placeholders['work_type_id']"
                    :disabled="!isEditableField('work_type_ids')"
                    as-key
                    @change="changeWithDelay" />
            </access>

            <!-- Volume + unit -->
            <access :permissions="permissionsAny('project_floor_work_plan_be_administrator', 'project_floor_work_plan_be_supervisor', 'project_floor_work_plan_be_foreman', 'project_tasks_be_becomes_constructor', 'project_tasks_be_construction_control_engineer', 'project_tasks_be_general_control_engineer')"
                    hidable>
                <el-form-item :label="labels.volume"
                              class="reset-el-form-item">
                    <div class="f space-x-0.5">
                        <!-- Volume -->
                        <input-field 
                            v-model="value.volume"
                            :readonly="!editable"
                            :placeholder="placeholders['volume']"
                            :disabled="!isEditableField('volume')"
                            class="reset-el-form-item w-full"
                            name="volume"
                            size="mini"
                            independent
                            @change="changeWithDelay" />

                        <!-- Unit -->
                        <select-field 
                            v-model="value.unit"
                            class="reset-el-form-item w-6"
                            :options="units"
                            :readonly="!editable"
                            :placeholder="placeholders['unit']"
                            :disabled="!isEditableField('unit')"
                            label-key="translated_name"
                            name="unit"
                            size="mini"
                            as-key
                            independent
                            @change="changeWithDelay" />
                    </div>
                </el-form-item>
            </access>

            <!-- Inspector -->
            <access :permissions="permissionsAny('project_floor_work_plan_be_supervisor', 'project_floor_work_plan_be_foreman')"
                    hidable>
                <user-field 
                    v-model="value.inspector_id"
                    class="reset-el-form-item"
                    :label="labels.inspector"
                    :placeholder="placeholders['inspector_id']"
                    :disabled="!isEditableField('inspector_id')"
                    as-key
                    is-work-plan-foreman
                    @change="changeWithDelay" />
            </access>

            <!-- Status -->
            <access permissions="project_floor_work_plan_be_foreman"
                    hidable>
                <polygon-work-status-field 
                    v-model="value.work_status"
                    class="reset-el-form-item"
                    :placeholder="placeholders['work_status']"
                    :disabled="!isEditableField('work_status')"
                    as-key
                    @change="changeWithDelay" />
            </access>

            <!-- Progress -->
            <access permissions="project_floor_work_plan_be_foreman"
                    hidable>
                <input-field 
                    v-model="value.progress"
                    class="reset-el-form-item"
                    :label="labels.progress"
                    :placeholder="placeholders['progress']"
                    :disabled="!isEditableField('progress')"
                    @change="changeWithDelay" />
            </access>

            <!-- Images -->
            <access v-if="editable && !multiple"
                    permissions="project_floor_work_plan_be_foreman"
                    hidable>
                <el-form-item :label="labels.images">
                    <div class="cols-3 gap-0.5">
                        <!-- Add -->
                        <div v-if="editable"
                             class="wh-6 rel">
                            <uploader 
                                :modal="false"
                                multiple
                                with-camera
                                @change="createImage">
                                <div class="plan-polygon-form-upload rounded-lg">
                                    <i class="el-icon-plus" /> 
                                </div>
                            </uploader>
                        </div>

                        <!-- All -->
                        <task-photos-card v-for="(image, i) in value.images"
                                          :key="image.id"
                                          :thumb="image.storage_thumb_url"
                                          size="mini"
                                          removable
                                          @click="showImageViewer(i)"
                                          @remove="removeImage(image)" />

                        <!-- Gallery -->
                        <photo-viewer 
                            v-if="imageViewerVisible"
                            :images="imagesForViewer"
                            :position="imageViewerPosition"
                            :loading="getPhotoAnnotationLoading"
                            with-caption
                            @image-select="selectImageForViewer"
                            @close="hideImageViewer" />
                    </div>
                </el-form-item>
            </access>

            <!-- Defects -->
            <access :allowed="!!defects.length"
                    :permissions="permissionsAny('project_floor_work_plan_be_foreman', 'project_tasks_be_becomes_constructor', 'project_tasks_be_construction_control_engineer', 'project_tasks_be_general_control_engineer')"
                    hidable>
                <div class="space-y-1.5 _py-1">
                    <el-divider class="_m-0 bg-gray-700" />

                    <task-item v-for="x in defects"
                               :key="x.id"
                               class="cursor-pointer"
                               :task="x"
                               removable
                               with-type
                               no-go-to
                               dark
                               @click="$emit('task-select', x)"
                               @remove="removeDefect" />
                </div>
            </access>

            <!-- Acceptances -->
            <access :allowed="!!acceptances.length"
                    permissions="project_tasks_acceptance_works_show"
                    hidable>
                <div class="space-y-1.5 _py-1">
                    <el-divider class="_m-0 bg-gray-700" />

                    <task-item v-for="x in acceptances"
                               :key="x.id"
                               class="cursor-pointer"
                               :task="x"
                               :removable="isEditableField('polygons_can_delete')"
                               with-type
                               no-go-to
                               dark
                               @click="$emit('task-select', x)"
                               @remove="removeAcceptance" />
                </div>
            </access>

            <!-- Actions -->
            <div v-if="editable"
                 class="f-col _mt-0.7 space-y-0.5">
                <!-- Resolve polygons of acceptance -->
                <access v-if="taskTypeAcceptance && isEditableField('polygons_can_edit')"
                        permissions="project_tasks_acceptance_works_edit"
                        hidable>
                    <el-button icon="el-icon-s-claim"
                               size="mini"
                               type="success"
                               class="text-white border-0 reset-el-button"
                               @click="resolveAcceptance">
                        {{ labels.resolve }}
                    </el-button>
                </access>

                <!-- Reject polygons of acceptance -->
                <access v-if="taskTypeAcceptance && isEditableField('polygons_can_edit')"
                        permissions="project_tasks_acceptance_works_edit"
                        hidable>
                    <el-button icon="el-icon-s-claim"
                               size="mini"
                               type="danger"
                               class="text-white border-0 reset-el-button"
                               @click="rejectAcceptance">
                        {{ labels.reject }}
                    </el-button>
                </access>

                <!-- Add defect -->
                <access v-if="!multiple"
                        permissions="project_floor_work_plan_be_foreman"
                        hidable>
                    <el-button icon="el-icon-warning"
                               size="mini"
                               class="text-white bg-gray-700 border-0 reset-el-button"
                               @click="addDefect">
                        {{ labels.addDefect }}
                    </el-button>
                </access>

                <!-- Create acceptance -->
                <access v-if="!taskTypeAcceptance"
                        :permissions="['project_tasks_acceptance_works_create', 'project_tasks_be_becomes_constructor']"
                        hidable>
                    <el-button icon="el-icon-s-claim"
                               size="mini"
                               class="text-white bg-gray-700 border-0 reset-el-button"
                               @click="createAcceptance">
                        {{ labels.createAcceptance }}
                    </el-button>
                </access>

                <!-- Set acceptance -->
                <access v-if="taskTypeAcceptance && isEditableField('polygons_can_add')"
                        permissions="project_tasks_acceptance_works_edit"
                        hidable>
                    <el-button icon="el-icon-s-claim"
                               size="mini"
                               class="text-white bg-gray-700 border-0 reset-el-button"
                               @click="setAcceptance">
                        {{ labels.setAcceptance }}
                    </el-button>
                </access>

                <!-- Request polygon editing -->
                <access permissions="project_floor_work_plan_be_foreman"
                        hidable>
                    <el-button size="mini"
                               class="text-white bg-gray-700 border-0 reset-el-button"
                               @click="showRequestEditing">
                        {{ labels.requestEditing }}
                    </el-button>
                </access>
            </div>
        </el-form>

        <!-- Dialog of request polygon editing -->
        <plan-polygon-request v-if="requestEditingVisible"
                              :polygons="polygons"
                              :layer="layer"
                              @close="hideRequestEditing" />

        <!-- Add task form -->
        <access permissions="project_floor_work_plan_be_foreman"
                hidable>
            <task-defect-and-violation-form />
        </access>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { actionable, resourceable } from '@/store/connectors'

import { deduplicate, symmetricalDifference, equalityById, filterObjectByKeys, filterEmpty, then, unique, intersection } from '@/utils/immutable'
import { openNew } from '@/utils/browser'
import { labelAt } from '@/utils/date'
import { fromCollectionError } from '@/utils/common'

import { types, getTypeWithPostfix } from '@/models/tasks'
import { getWorkLayerName, getWorkPolygonName, planTypes } from '@/models/plans'

import TaskPhotosCard from '@/components/task/TaskPhotosCard'
import Uploader from '@/components/shared/FileUploaderRevision'
import PhotoViewer from '@/components/photos/PhotoViewer'
import PlanPolygonRequest from '@/components/map/PlanPolygonRequest'
import TaskDefectAndViolationForm from '@/components/forms/TaskDefectAndViolationForm'
import InputField from '@/components/fields/InputField'
import SelectField from '@/components/fields/SelectField'
import WorkTypeField from '@/components/fields/WorkTypeField'
import UserField from '@/components/fields/UserField'
import PolygonWorkStatusField from '@/components/fields/PolygonWorkStatusField'
import TaskItem from '@/components/tasks/TaskItem'
import dialogs from '@/values/dialogs'

const labels = {
  volume: 'Объемы',
  inspector: 'Ответственный',
  progress: 'Готовность (%)',
  addDefect: 'Назначить дефекты',
  createAcceptance: 'Вызвать стройконтроль',
  setAcceptance: 'Назначить приемку',
  requestEditing: 'Запрос на изменение полигонов',
  image: 'Фотография',
  images: 'Фотографии',
  different: 'Разное',
  resolve: 'Работы приняты',
  reject: 'Работы не приняты'
}

export default {
  components: {
    TaskPhotosCard,
    Uploader,
    PhotoViewer,
    PlanPolygonRequest,
    TaskDefectAndViolationForm,
    InputField,
    SelectField,
    WorkTypeField,
    UserField,
    PolygonWorkStatusField,
    TaskItem
  },
  mixins: [
    resourceable({ on: 'tasks', name: 'lotDefects', mounted: true }),
    actionable({ on: 'tasks', name: 'getPhotoAnnotation', loadable: true })
  ],
  props: {
    polygons: { type: Array, default: () => [] },
    layer: { type: Object, default: null },
    editableFields: { type: Object, default: () => ({}) },
    units: { type: Array, default: () => [] },
    editable: { type: Boolean, default: false },
    houseId: { type: String, default: null },
    floorId: { type: String, default: null },
    planId: { type: String, default: null },
    task: { type: Object, default: null }
  },
  data() {
    return {
      value: {},

      placeholders: {},

      requestEditingVisible: false,
      imageViewerVisible: false,

      imageViewerPosition: 0,
      imageViewerAnnotation: null,

      getTypeWithPostfix,
      permissionsAny
    }
  },
  computed: {
    ...mapGetters('auth', ['profileId']),

    labels() {
      return labels
    },

    polygon() {
      return this.polygons[0]
    },

    multiple() {
      return this.polygons?.length > 1
    },

    imagesForViewer() {
      return (this.value.images || []).map(({ id, storage_url, storage_thumb_url, created_at }, i) => ({
        id,
        thumb: storage_thumb_url,
        src: storage_url,
        caption: [getWorkLayerName(this.layer), getWorkPolygonName(this.polygon, { layer: this.layer }), `${labels.image} ${i + 1} (${labelAt(created_at)})`].filter(is).join(' / ')
      }))
    },

    taskTypeAcceptance() {
      return this.task?.type === types.ACCEPTANCE_OF_WORK
    },

    defects() {
      return this.value.defects || []
    },

    acceptances() {
      return this.value.acceptances || []
    }
  },
  watch: {
    polygons: {
      handler(x) {
        const { volume, unit, data, tasks = [], acceptance_work_tasks = [], images = [] } = this.polygon || {}
        const { work_type_ids, inspector_id, work_status, progress = 0 } = data || {}

        const unify = (x, f) => compose(
          x => x.length > 1 ? undefined : x[0],
          x => deduplicate(x),
          x => x.map(f)
        )(x)

        const unifyObjects = (x, f) => compose(
          x => unique(x, equalityById),
          x => x.reduce((r, x) => intersection(r, x, equalityById), x[0]),
          x => x.map(f)
        )(x)

        this.value = this.multiple ? {
          volume: unify(x, ({ volume }) => volume),
          unit: unify(x, ({ unit }) => unit),
          work_type_id: unify(x, ({ data: { work_type_ids } = {} }) => work_type_ids?.[0]),
          inspector_id: unify(x, ({ data: { inspector_id } = {} }) => inspector_id),
          work_status: unify(x, ({ data: { work_status } = {} }) => work_status),
          progress: unify(x, ({ data: { progress = 0 } = {} }) => progress * 100),
          defects: unifyObjects(x, ({ tasks = [] }) => tasks),
          acceptances: unifyObjects(x, ({ acceptance_work_tasks = [] }) => acceptance_work_tasks)
        } : {
          volume, 
          unit, 
          work_type_id: work_type_ids?.[0], 
          inspector_id,
          work_status,
          progress: progress * 100,
          defects: tasks,
          acceptances: acceptance_work_tasks,
          images
        }

        this.placeholders = this.multiple ? {
          volume: (unify(x, ({ volume }) => volume) || labels.different) + '',
          unit: (unify(x, ({ unit }) => unit) || labels.different) + '',
          work_type_id: (unify(x, ({ data: { work_type_ids } = {} }) => work_type_ids?.[0]) || labels.different) + '',
          inspector_id: (unify(x, ({ data: { inspector_id } = {} }) => inspector_id) || labels.different) + '',
          work_status: (unify(x, ({ data: { work_status } = {} }) => work_status) || labels.different) + '',
          progress: (unify(x, ({ data: { progress = 0 } = {} }) => progress * 100) || labels.different) + ''
        } : {}
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({ 
      showTaskForm: 'forms/taskDefectAndViolationForm/SHOW_FORM'
    }),
    ...mapActions('tasks', ['storeTaskWithError', 'storeDefectImages', 'storeTaskPolygons']),
    ...mapMutations({ showForm: 'form/SHOW_FORM' }),

    change({ _images_for_create, _images_for_remove, _updated_for_task } = {}) {
      const value = this.value

      const volume = value.volume && parseFloat(value.volume)
      const unit = value.unit
      const { work_type_id, inspector_id, work_status, progress, defects } = value || {}

      const on = 
        volume != this.polygon.volume 
        || unit != this.polygon.unit
        || work_type_id != this.polygon.data?.work_type_ids?.[0]
        || inspector_id != this.polygon.data?.inspector_id
        || work_status != this.polygon.data?.work_status
        || progress != this.polygon.data?.progress
        || symmetricalDifference(defects, this.polygon.tasks || [], equalityById).length
        || _images_for_create?.length
        || _images_for_remove?.length
        || _updated_for_task

      const r = {
        ...filterObjectByKeys({ 
          volume, 
          unit,
          task_ids: defects?.map(({ id }) => id),
          work_type_ids: [work_type_id].filter(is), 
          inspector_id, 
          work_status, 
          progress: progress && (progress / 100)
        }, Object.keys(this.editableFields)),

        _images_for_create,
        _images_for_remove,
        _updated_for_task
      }

      is(on && this.multiple) 
        ? this.$emit('change-multiple', { polygons: this.polygons.map(({ id }) => filterEmpty({ id, ...r }, { by: x => x === undefined })) }) 
        : this.$emit('change', { id: this.polygon.id, ...r })
    },

    changeWithDelay({ _images_for_create, _images_for_remove, _updated_for_task } = {}) {
      defer('update-work-polygon', () => this.change({ _images_for_create, _images_for_remove, _updated_for_task }), 1000)
    },

    createImage(images) {
      this.change({ _images_for_create: images })
    },

    removeImage(image) {
      this.change({ _images_for_remove: [image] })
    },

    showImageViewer(i) {
      this.imageViewerVisible = true
      this.imageViewerPosition = i
      this.fetchAnnotation()
    },

    selectImageForViewer({ id }) {
      const i = this.imagesForViewer.findIndex(({ id: x }) => x === id)

      this.showImageViewer(i)
    },

    hideImageViewer() {
      this.imageViewerVisible = false
    },

    fetchAnnotation() {
      const capture = this.imagesForViewer[this.imageViewerPosition]

      const { object_id, object_type } = capture || {}

      object_id && object_type && this.getPhotoAnnotation({ capture, withContent: true })
        .then(annotation => this.imageViewerAnnotation = annotation)
    },

    addDefect() {
      this.showTaskForm({
        task: {},
        projectId: this.$route.params.projectId,
        payload: {
          tasks: this.defects,
          floorId: this.floorId,
          multiple: true,
          forWorkPolygon: true
        },
        callback: async ({ payload } = {}) => {
          const { selectedTasks, task, photos = [] } = payload || {}

          selectedTasks && (this.value.defects = selectedTasks)
          selectedTasks && this.changeWithDelay()

          const photoIds = photos.length && await this.storeDefectImages({
            images: photos.map(x => x.file),
            forDefectCommon: true
          })

          return task && this.storeTaskWithError({ 
            payload: {
              type: types.DEFECTS_AND_VIOLATIONS,
              data: {
                ...task,
                project_id: this.$route.params.projectId,
                image_ids: photoIds || []
              }
            } 
          }).then(({ data: task }) => {
            this.value.defects = [...this.value.defects, task]
            this.changeWithDelay()
          }) || Promise.resolve()
        }
      })
    },

    createAcceptance() {
      this.showForm({
        formName: 'task-acceptance-of-work-form',
        formTitle: 'Вызвать стройконтроль',
        action: x => this.storeTaskWithError(x)
          .then(({ data: task }) => {
            this.value.acceptances = [...this.value.acceptances, task]

            return { data: task }
          })
          .catch(({ messages }) => {
            this.$emit('polygons-save-reject', fromCollectionError(messages)
              .reduce((r, { index, prefix, value }) => ({ 
                ...r, 
                ...then(
                  ['objects.0.polygons'].includes(prefix) && this.polygons[index]?.id, 
                  x => ({ [x]: [...r[x] || [], ...value] })
                ) 
              }), {}))

            throw ({ messages })
          }),
        payload: {
          contractor_id: this.profileId,
          house_id: this.houseId,
          floor_id: this.floorId,
          work_plan_id: this.planId,
          work_plan_layers: [this.layer],
          lot_defect: this.lotDefects.find(({ name }) => name === 'Кладка'),
          polygons: this.polygons,
          
          disablements: {
            lot_defect_id: true,
            objects: true
          }
        }
      })
    },

    updateAcceptance({ polygonsForCreate, polygonsForUpdate }) {
      this.storeTaskPolygons({
        taskId: this.task.id,
        planId: this.layer.id,
        planType: planTypes.Work,
        polygonsForCreate,
        polygonsForUpdate
      })
        .then(() => {
          this.change({ _updated_for_task: this.task?.id })
          this.$emit('polygons-save-resolve')
        })
        .catch(({ messages }) => this.$emit('polygons-save-reject', fromCollectionError(messages)
          .reduce((r, { index, prefix, value }) => ({ 
            ...r, 
            ...then(
              ['polygons.insert', 'polygons.update', 'polygons.delete'].includes(prefix) && this.polygons[index]?.id, 
              x => ({ [x]: [...r[x] || [], ...value] })
            ) 
          }), {}))) 
    },

    setAcceptance() {
      this.updateAcceptance({
        polygonsForCreate: this.polygons.map(({ id }) => ({ floor_work_plan_layer_polygon_id: id, type: 'finish' }))
      })
    },

    resolveAcceptance() {
      this.updateAcceptance({
        polygonsForUpdate: this.polygons.map(({ id }) => ({ id, acceptance_result: 'finished_accepted' }))
      })
    },

    rejectAcceptance() {
      this.updateAcceptance({
        polygonsForUpdate: this.polygons.map(({ id }) => ({ id, acceptance_result: 'finished_not_accepted' }))
      })
    },

    removeDefect(x) {
      const go = () => {
        this.value.defects = this.value.defects.filter(({ id }) => id !== x.id)
        this.changeWithDelay()
      }

      dialogs.confirmDeletion.call(this, { subject: 'дефект' })
        .then(go.bind(this))
        .catch(() => {})
    },

    removeAcceptance(x) {
      const go = () => {
        this.storeTaskPolygons({
          taskId: x.id,
          planId: this.layer.id,
          planType: planTypes.Work,
          polygonsForRemove: this.polygons
        }).then(this.change.bind(this, { _updated_for_task: this.task?.id }))
      }

      dialogs.confirmDeletion.call(this, { subject: 'приемку работ для этого полигона' })
        .then(go.bind(this))
        .catch(() => {})
    },

    showRequestEditing() {
      this.requestEditingVisible = true
    },

    hideRequestEditing() {
      this.requestEditingVisible = false
    },

    isEditableField(name) {
      return this.editable && !!this.editableFields[name]
    },

    goToTask(task) {
      const x = this.$router.resolve({ name: 'project.task', params: { taskId: task.id }})

      openNew(x.href)
    }
  }
}
</script>

<style lang="scss">
.plan-polygon-form {
  .el-input__inner {
    background-color: transparent;
    border-color: #616161;
    color: #9E9E9E;
  }

  .el-input.is-disabled .el-input__inner {
    background-color: #424242;
    border-color: #424242;
  }
}

.plan-polygon-form-upload {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 6rem;
  height: 6rem;    

  border: 1px dashed #909399;

  box-sizing: border-box;

  cursor: pointer;

  .el-icon-plus {
    font-size: 2.5rem;
    color:  #909399;
  }
}
</style>
