import { labelAt } from '../utils/date'

export const toCreate = ({ organization, project, paymentId }) => ({
  name: organization.name,
  inn: organization.inn,
  kpp: organization.kpp,
  project_id: project?.id,
  payment_id: paymentId
})

export const toInvite = ({ organization, project, paymentId }) => ({
  organization_id: organization.id,
  ...!organization.id && {
    inn: organization.inn,
    kpp: organization.kpp,
    name: organization.name
  },
  user_id: organization.responsible.user.id,
  position: organization.responsible.position,
  email: organization.responsible.user.email,
  first_name: organization.responsible.user.first_name,
  last_name: organization.responsible.user.last_name,
  patronymic: organization.responsible.user.patronymic,
  phone: organization.responsible.user.phone,
  type: organization.type.value,
  project_id: project.id,
  payment_id: paymentId
})

export const toUpdate = ({ organization }) => ({
  name: organization.name,
  position: organization.responsible.position,
  type: organization.type.value,
  user_id: organization.responsible.user.id
})

export const fill = (organization = {}) => ({
  ...organization,
  responsible: {
    ...organization?.responsible,
    user: {
      ...organization?.responsible?.user
    }
  }
})

export const fillResponsible = () => ({
  user: {}
})

export const getCreatedAtAsLabel = organization => labelAt(organization.created_at, { withTime: false })

export const getRoleName = organization => organization.type?.translated_name

export const getUnavavilableProjects = organization => (organization.projects || [])
  .filter(({ blocked_at }) => blocked_at)
  .map(({ project }) => project)

export const getProjects = organization => (organization.projects || [])

export const getAvailableProjects = organization => (organization.projects || [])
  .filter(({ blocked_at }) => !blocked_at)
  .map(({ project }) => project)

export const isImported = organization => organization.source === 'ast'
export const isInactive = organization => organization.status === 'unactive'
export const isActivable = organization => isImported(organization) && isInactive(organization)

