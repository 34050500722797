export default axios => ({
  getPayments: ({ filter }) => axios.get('admin/payments', {
    params: {
      filter,
      include: [
        'object.project.avatar',
        'subject.organization',
        'subject.users.user',
        'recipient.organization'
      ],
      sort: '-created_at'
    }
  }),

  getPaymentsCount: ({ filter }) => axios.get('admin/payments/count', {
    params: {
      filter
    }
  }),

  updatePayment: ({ payment, status }) => axios.patch(`admin/payments/${payment.id}`, {
    status
  }),

  blockPayment: ({ payment }) => axios.post(`admin/payments/${payment.id}/block`),
  unblockPayment: ({ payment }) => axios.post(`admin/payments/${payment.id}/unblock`)
})
