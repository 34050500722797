<template>
    <div class="rows-min-minmax-min">
        <!-- Header -->
        <div class="gap-0.5 _p-1 border-box border-bottom-1 border-gray-300 rounded-top"
             :style="styles.row">
            <div v-for="(column, index) in columns"
                 :key="index"
                 class="w-full">
                <our-label :value="column.label" />
            </div>
        </div>

        <!-- Rows -->
        <div id="task-container"
             v-save-scroll="tasks"
             class="scroll-y space-y-1 with-divider _p-1 border-box with-custom-scrollbar">
            <task-row v-for="(task, index) in tasks"
                      :key="task.id"
                      :task="task"
                      :index="index + 1"
                      :columns="columns"
                      :selectable="selectable" />
        </div>

        <!-- Footer -->
        <div class="f-v-center h-3 border-top-1 border-gray-200">
            <el-pagination background
                           :current-page="pagination.page"
                           :page-size="pagination.size"
                           :page-sizes="[60, 120, 180, 240]"
                           :total="pagination.total"
                           layout="prev, pager, next, sizes"
                           @size-change="resize"
                           @current-change="paginate" />
        </div>
    </div>
</template>

<script>
import { types } from '@/models/tasks'

import TaskRow from '@/components/tasks/TaskRow'

const labels = {
  task: 'Задача',
  acceptances: 'Принимаемые работы',
  initiator: 'Инициатор',
  inspector: 'Ответственный',
  worker: 'Исполнитель',
  engineer: 'Инженер стройконтроля',
  manager: 'Рук. строительства Г/П',
  contractor: 'Подрядчик',
  generalContractor: 'Контроль',
  comment: 'Комментарий',
  select: 'Выбрать'
}

export default {
  components: {
    TaskRow
  },
  props: {
    type: { type: String, default: null },
    tasks: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => null },
    selectable: { type: Boolean, default: false }
  },
  computed: {
    styles() {
      return {
        row: `
          display: grid;
          grid-template-columns: ${this.columns.map(x => x.width || 'minmax(0, 1fr)').join(' ')}
        `
      }
    },

    isDefectsType() { return this.type === types.DEFECTS_AND_VIOLATIONS },
    isBuildingOrderType() { return this.type === types.BUILDING_ORDER },
    isPhotoOrderType() { return this.type === types.PHOTO_ORDER },
    isAcceptanceType() { return this.type === types.ACCEPTANCE_OF_WORK },

    columns() {
      return [
        { width: '2rem' },
        { width: '2rem' },
        { label: labels.task, width: 'minmax(24rem, 1fr)' },
        { label: labels.acceptances, if: this.type === types.ACCEPTANCE_OF_WORK },
        { label: labels.initiator },
        { label: labels.engineer, if: this.isBuildingOrderType },
        { label: labels.manager, if: this.isBuildingOrderType },
        { label: this.isAcceptanceType ? labels.engineer : labels.worker },
        { label: labels.generalContractor, if: this.isDefectsType },
        { label: labels.contractor, if: this.isAcceptanceType },
        { label: labels.comment, if: this.isAcceptanceType || this.isPhotoOrderType || this.isBuildingOrderType },
        { label: labels.select, if: this.selectable, width: '4rem' }
      ].filter(x => x.if === undefined || x.if === true)
    }
  },

  methods: {
    resize(size) {
      this.$emit('resize', size)
    },

    paginate(page) {
      this.$emit('paginate', page)
    }
  }
}
</script>
