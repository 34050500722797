import { toCreate, toInvite, toUpdate } from '@/models/organizations'

export default axios => ({
  getOrganizations: ({ page, size, name, projects, onlyBlockedOrganizations, withProjects }) => axios.get('admin/organizations', {
    params: {
      filter: {
        name,
        ...name && { name },
        ...projects && { project_ids: projects.map(({ id }) => id) },
        ...onlyBlockedOrganizations && { only_with_blocked_projects: onlyBlockedOrganizations ? 1 : 0 }
      },
      sort: '-created_at',
      include: [
        withProjects && 'linked_projects'
      ].filter(x => x),
      page: {
        number: page,
        size
      }
    }
  }),

  getOrganizationsReport: (payload) => axios.post('admin/organizations/report', payload, {
    responseType: 'blob'
  }),

  getOrganizationsForSuggest: ({ name, inn, kpp, search: name_or_inn, project, exceptProject }) => axios.get('admin/organizations/suggestion', {
    params: {
      sort: 'name',
      filter: {
        name_or_inn,
        name,
        inn,
        kpp,
        project_id: project?.id,
        except_project_id: exceptProject?.id
      }
    }
  }).then(x => x?.data?.data || []),

  getOrganization: ({ project, organization }) => axios.get(`projects/${project.id}/organizations/${organization.id}`), 

  getProjectOrganizations: ({ project, page, size, search, type, onlyBlockedOrganizations, sort }) => axios.get(`projects/${project.id}/organizations`, {
    params: {
      sort: sort || '-created_at',
      filter: {
        name_or_inn: search,
        type: type?.value,
        ...onlyBlockedOrganizations && { only_blocked_organizations: onlyBlockedOrganizations ? 1 : 0 }
      },
      page: {
        number: page,
        size
      }
    }
  }),

  getProjectOrganizationsForSuggest: ({ project, search }) => axios.get(`projects/${project.id}/organizations/suggestion`, {
    params: {
      filter: {
        name_or_inn: search
      }
    }
  }),

  getOrganizationsRoles: () => axios.get('admin/organizations/type-role-in-project').then(x => x.data?.data || []),

  storeOrganization: ({ organization, project, paymentId }) => 
    axios.post('admin/organizations', toCreate({ project, organization, paymentId }))
      .then(x => x.data.data),

  inviteOrganization: ({ project, organization, paymentId }) => 
    axios.post('admin/organizations/invite', toInvite({ project, organization, paymentId }))
      .then(x => x?.data?.data),

  updateOrganization: ({ project, organization }) => axios.put(`projects/${project.id}/organizations/${organization.id}`, toUpdate({ organization })).then(x => x?.data?.data)
})
