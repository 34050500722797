import { resourceable, loadable, pagenable, actionable } from '@/store/mixins'

import { TASK_TYPES } from '@/utils/tasks'
import { formatForServer } from '@/utils/date'
import { symmetricalDifference, then } from '@/utils/immutable'
import { fromError } from '@/utils/common'

import { isDefectByPoint, isDefectCommon } from '@/models/tasks'
import { planTypes } from '@/models/plans'

const labels = {
  aboutFinish: 'Отметьте области приемки как принятые или не принятые, иначе все необработанные области приемки будут помечены как принятые',
  aboutFinishNotAccepted: 'Отметьте области приемки как принятые или не принятые, иначе все необработанные области приемки будут помечены как непринятые'
}

export default {
  namespaced: true,

  mixins: [
    resourceable({
      name: 'task',
      initial: null,
      from: async ({ api, commit, payload: { id, withUsers } }) => {
        const task = await api.tasks.show(id, {
          params: {
            include: [
              withUsers && 'creator',
              withUsers && 'acceptanceOfWorkContractor.organization',
              withUsers && 'defectGeneralContractor.organization',
              withUsers && 'memberWorkers.user.organization'
            ]
          }
        }).then(x => x?.data?.data || {})

        commit('REFRESH_TASK', task)

        return task
      }
    }),

    pagenable({
      name: 'tasks',
      from: ({ api, getters, page, payload: { 
        type, 
        from, 
        to,

        all = false, 

        filter = {},
        size = 60,
        sort,

        polygons,

        withUnread
      } }) => api.tasks.index({
        params: {
          filter: {
            ...filter,

            'type': type,
            'project_id': getters['project/project'].id,

            ...from && { 'created_from': formatForServer(from) },
            ...to && { 'created_to': formatForServer(to) },
            ...polygons && { 'polygon_ids': polygons.map(({ id }) => id) }
          },
          include: [
            'workerOrganization', 'creator', 'project', 'members.user.organization', 'memberInitiator.user.organization', 'memberWorkers.user.organization',
            'memberInspectors.user.organization', 'acceptanceOfWorkContractor.organization', 'defectGeneralContractor.organization',

            ...type === TASK_TYPES.DEFECTS_AND_VIOLATIONS ? [
              'defectGeneralContractor.organization',
              'objectsBuildingOrderConnection.object',
              'objectsAcceptanceOfWorkConnection.object',
              'linkedFirstDefectProjectObject'
            ] : [],

            ...type === TASK_TYPES.BUILDING_ORDER ? [
              'subjectsBuildingOrderConnection.subject'
            ] : [],

            ...type === TASK_TYPES.ACCEPTANCE_OF_WORK ? [
              'subjectsAcceptanceOfWorkConnection.subject',
              'acceptanceOfWorkContractor.organization'
            ] : []
          ],
          page: {
            number: page,
            size: all ? 0 : size
          },
          append: [
            withUnread && 'unread_changes_exists'
          ],
          ...sort && { sort }
        }
      }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'tasksByPolygons',
      from: ({ api }, { type, polygons }) => api.tasks.index({
        params: {
          filter: {
            type,
            ...polygons && { polygon_ids: polygons.map(({ id }) => id) }
          },
          include: [
            'workerOrganization', 'creator', 'project', 'memberInitiator.user.organization', 'memberWorkers.user.organization',
            'memberInspectors.user.organization', 'acceptanceOfWorkContractor.organization', 'defectGeneralContractor.organization',

            ...type === TASK_TYPES.DEFECTS_AND_VIOLATIONS ? [
              'defectGeneralContractor.organization',
              'objectsBuildingOrderConnection.object',
              'objectsAcceptanceOfWorkConnection.object'
            ] : [],

            ...type === TASK_TYPES.BUILDING_ORDER ? [
              'subjectsBuildingOrderConnection.subject',
              'members.user.organization'
            ] : [],

            ...type === TASK_TYPES.ACCEPTANCE_OF_WORK ? [
              'subjectsAcceptanceOfWorkConnection.subject',
              'acceptanceOfWorkContractor.organization'
            ] : []
          ]
        }
      }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'tasksCount',
      from: ({ api, getters, payload: { 
        type,
        contractorOrganization,
        byStatus: by_status, 
        byState: by_state,
        byKind: by_kind_defect
      } }) => api.tasksV2.getTasksCount({
        filter: {
          type,
          project_id: getters['project/project'].id,
          ...contractorOrganization && { contractor_organization_id: contractorOrganization.id }
        },
        append: Object.entries({
          by_status,
          by_state,
          by_kind_defect
        }).filter(([_, v]) => v).map(([k, _]) => k)
      }).then(x => x?.data?.data || {})
    }),

    resourceable({
      name: 'tasksCountByOrganization',
      from: ({ api, getters, payload: {
        type
      } }) => api.tasksV2.getTasksCountByOrganization({ 
        filter: {
          type,
          project_id: getters['project/project'].id 
        }
      }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'tasksCountByUsers',
      from: ({ api, getters }, {
        type,
        byStatus: by_status, 
        byState: by_state,
        byKind: by_kind_defect
      }) => api.tasksV2.getTasksCountByUsers({
        filter: {
          project_id: getters['project/project'].id,
          type,
          only_engineer: 1
        },
        append: [
          Object.entries({
            by_status,
            by_state,
            by_kind_defect
          }).filter(([_, v]) => v).map(([k, _]) => k) 
        ]
      }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'tasksCountInTime',
      from: ({ api, getters, payload: {
        type,
        by
      } }) => api.tasksV2.getTasksCountInTime({
        filter: {
          type,
          by,
          project_id: getters['project/project'].id
        }
      }).then(x => x?.data?.data || [])
    }),

    resourceable({
      name: 'workingTask',
      initial: null,
      from: async ({ api, dispatch, payload: {
        id
      } }) => {
        const task = await api.tasks.show(id, {
          params: {
            include: [
              'creator',
              'acceptanceOfWorkContractor.organization',
              'defectGeneralContractor.organization',
              'memberWorkers.user.organization',

              'subjectsAcceptanceOfWorkConnection.subject',
              'subjectsBuildingOrderConnection.subject'
            ]
          }
        }).then(x => x?.data?.data || {})

        await dispatch('fetchRelatedTasks', task)

        return task
      }
    }),

    resourceable({
      name: 'lotDefects',
      once: true,
      from: ({ api, payload: { search } }) => api.dirsV2.getLotDefects({
        params: {
          filter: {
            name: search ? search : null
          },
          page: {
            size: 0
          }
        }
      }).then(x => x.data?.data || []) 
    }),


    resourceable({
      name: 'defectKinds',
      from: ({ api }) => api.tasksV2.getDefectKinds().then(x => x.data?.data || [])
    }),

    resourceable({
      name: 'editableFieldsWithValidation',
      from: ({ api }, { id }) => 
        api.tasks.getTaskEditableFieldsWithValidation({ id })
          .then(x => x.data?.data || [])
    }),

    resourceable({
      name: 'creatableFields',
      resetBeforeFetch: true,
      from: ({ api, getters }, { type, subtype }) =>
        api.tasks.getProjectTasksCreatableFields({
          project: getters['project/project'],
          type,
          subtype
        })
          .then(x => x.data?.data || [])
    }),

    resourceable({
      name: 'allowedActions',
      from: ({ api }, { task }) =>
        api.tasksV2.getAllowedActions({ task })
          .then(r => r.map(x => ({
            ...x,
            warnings: [
              x.reason_disable
            ].filter(is),
            messages: [
              then(x.name === 'finish', () => labels.aboutFinish),
              then(x.name === 'finish_not_accept', () => labels.aboutFinishNotAccepted)
            ].filter(is)
          })))
    }),

    actionable({
      name: 'setTaskMessages',
      at: ({ state }, messages) =>
        state.taskMessages = messages
    }),

    actionable({
      name: 'addDefectMark',
      at: ({ api }, { task, photoId, yolo }) => api.tasksV2.addDefectMark({ task, photoId, yolo }) 
    }),

    actionable({
      name: 'addStreetFalconDefectMark',
      at: ({ api }, { task, photoId, yolo }) => api.tasksV2.addStreetFalconDefectMark({ task, photoId, yolo }) 
    }),

    actionable({
      name: 'applyPhotoAnnotation',
      loadable: true,
      at: ({ api }, { capture, createdShapes, updatedShapes, removedShapes }) => 
        api.tasks.applyPhotoAnnotation({ capture, createdShapes, updatedShapes, removedShapes })
          .then(r => {
            wantSomeMagic()
            return r
          })
    }),

    actionable({
      name: 'getPhotoAnnotation',
      loadable: true,
      at: async ({ api }, { capture, withContent }) => {
        const { annotation, can_edit } = await api.tasks.getPhotoAnnotation({ capture })

        const contentUrl = annotation?.image?.storage_url

        const content = withContent && contentUrl && await fetch(contentUrl).then(r => r.text())

        return {
          ...annotation,
          ...content && { content },
          can_edit
        }
      }
    }),

    actionable({
      name: 'storeFilter',
      at: ({ state }, { filter, filterForServer }) => {
        state.filter = filter
        state.filterForServer = filterForServer
      }
    }),

    actionable({
      name: 'storeTaskPolygons',
      loadable: true,
      at: ({ api }, { taskId, planId, planType, polygonsForCreate = [], polygonsForUpdate = [], polygonsForRemove = [] }) => api.tasks.update(taskId, {
        polygons: {
          object_id: planId,
          object_type: ({
            [planTypes.Common]: 'floor_plan',
            [planTypes.Work]: 'floor_work_plan_layer'
          })[planType],
          insert: polygonsForCreate,
          update: polygonsForUpdate,
          delete: polygonsForRemove.pluck('id')
        }
      }).catch(fromError)
    }),

    loadable({ action: 'getTasks' }),
    loadable({ action: 'showTask' }),
    loadable({ action: 'storeTaskWithError' }),
    loadable({ action: 'storeDefectImages' }),
    loadable({ action: 'updateTask' }),
    loadable({ action: 'updateTaskWithError' })
  ],

  state: {
    filter: {},
    filterForServer: {},

    requestedTask: null,
    relatedTasks: [],
    selectedTasks: [],

    taskMessages: [],

    editableFields: [],
    taskTypes: [],
    gprTasksCountData: [],
    filesCount: 0
  },

  getters: {
    filter: state => state.filter,
    filterForServer: state => state.filterForServer,

    requestedTask: state => state.requestedTask,
    relatedTasks: state => state.relatedTasks,
    selectedTasks: state => state.selectedTasks,

    taskMessages: state => state.taskMessages,
    editableFields: state => state.editableFields,

    editableFieldsErrorsByName: state => name => state.editableFieldsWithValidation.find(x => x.name === name)?.errors,

    creatableFieldsByName: state => name => state.creatableFields.find(x => x.name === name) || false,

    hasRequiredFields: state => [...state.creatableFields || [], ...state.editableFieldsWithValidation || []].some(x => x.required || x.is_require),

    isEditableField: state => name => state.editableFields.includes(name) || state.editableFieldsWithValidation.some(x => x.name === name),

    isRequiredField: state => name => [
      ...state.creatableFields || [],
      ...state.editableFieldsWithValidation || []
    ].some(x => x.name === name && (x.required || x.is_require || false)),

    hasRequiredFieldOption: state => (name, option) => [
      ...state.creatableFields || [], 
      ...state.editableFieldsWithValidation || []
    ].some(x => x.name === name && (x.available_values || []).includes(option)),

    getRequiredFieldOptions: state => name => [
      ...state.creatableFields || [], 
      ...state.editableFieldsWithValidation || []
    ].find(x => x.name === name)?.available_values || [],

    taskTypesOptionsCreate: state => {
      return state.taskTypes.filter(type => type.value !==
        'plain');
    },

    isBuildingOrderType: state => state.task.type === TASK_TYPES.BUILDING_ORDER,
    isDefectsType: state => state.task.type === TASK_TYPES.DEFECTS_AND_VIOLATIONS,
    isCheckingActType: state => state.task.type === TASK_TYPES.CHECKING_ACT,
    isPhotoOrderType: state => state.task.type === TASK_TYPES.PHOTO_ORDER,
    isPlainType: state => state.task.type === TASK_TYPES.PLAIN,
    isAcceptanceType: state => state.task.type === TASK_TYPES.ACCEPTANCE_OF_WORK,
    contractorTasksCount: state => contractorId => {
      const tasksCount = state.gprTasksCountData.find(item => item.contractorId === contractorId)
      return tasksCount ? tasksCount.data : null
    },
    contractorId: state => state.task?.data?.contractor_id || null,
    objects: state => state.task?.data?.objects || [],
    withoutDefectPhotos: state => state.task?.data?.without_defect_photos || [],
    checklistPhotos: state => state.task?.data?.checklist_photos || [],

    taskPhotosCount: state => {
      const withoutDefectPhotosCount = state.task.data?.without_defect_photos?.length || 0
      const checklistPhotosCount = state.task.data?.checklist_photos?.length || 0
      const defectPhotosCount = state.relatedTasks?.length || 0

      return withoutDefectPhotosCount + checklistPhotosCount + defectPhotosCount
    },

    filesCount: state => state.filesCount
  },

  mutations: {
    REFRESH_TASK: (state, x) => {
      state.tasks = state.tasks.map(y => y.id === x.id ? x : y)
    },

    REQUEST_TASK: (state, { id }) => state.requestedTask = { id },

    SET_RELATED_TASKS: (state, tasks) => state.relatedTasks = tasks,
    CLEAR_RELATED_TASKS: state => state.relatedTasks = [],
    REMOVE_RELATED_TASK: (state, task) => state.relatedTasks = state.relatedTasks.filter(x => x.id !== task.id),

    TOGGLE_TASKS: (state, tasks) => state.selectedTasks = symmetricalDifference([...state.selectedTasks], tasks, (x, y) => x.id === y.id),
    CLEAR_SELECTED_TASKS: state => state.selectedTasks = [],

    CLEAR_TASKS: state => {
      state.tasks = []
      state.selectedTasks = []
    },

    // NOT REVIEWED

    SET_SELECTED_TASK: (state, payload) => {
      state.task = payload;
    },
    SET_EDITABLE_FIELDS: (state, payload) => {
      state.editableFields = payload;
    },
    SET_ALLOWED_ACTIONS: (state, payload) => {
      state.allowedActions = payload;
    },
    SET_TASK_TYPES: (state, payload) => {
      state.taskTypes = payload;
    },
    SET_GPR_TASKS_COUNT_DATA: (state, payload) => {
      state.gprTasksCountData.push(payload)
    },
    CLEAR_GPR_TASKS_COUNT_DATA: state => state.gprTasksCountData = [],
    SET_FILES_COUNT: (state, payload) => state.filesCount = payload,
    INCREASE_FILES_COUNT: state => state.filesCount++,
    DECREASE_FILES_COUNT: state => state.filesCount--
  },

  actions: {
    storeDefectImage: function (_, payload) {
      return this.$api.tasksV2.storeDefectImage(payload).then(x => x?.data?.data);
    },

    storeDefectImages: function(_, { task, images, forDefectCommon, forDefectByPoint }) {
      let r

      r ||= !images.length && Promise.resolve([])

      r ||= (forDefectCommon || isDefectCommon(task)) && this.$api.tasksV2.storeImages({ type: 'defect_and_violation_photo_defect', images })
        .then(x => x.data.data || [])
        .then(x => x.map(({ id }) => id))

      r ||= (forDefectByPoint || isDefectByPoint(task)) && this.$api.tasksV2.storeDefectImage({ image: images[0] })
        .then(x => x.data.data || {})
        .then(({ id }) => [id])

      r ||= Promise.resolve([])

      return r.catch(fromError)
    },

    toggleTasks({ commit }, tasks) {
      commit('TOGGLE_TASKS', tasks)
    },

    clearSelectedTasks({ commit }) {
      commit('CLEAR_SELECTED_TASKS')
    },

    async fetchRelatedTasks({ commit, dispatch }, task) {
      const relations = [...task.subjects_acceptance_of_work_connection || [], ...task.subjects_building_order_connection || []]

      if (relations.length) {
        const ids = relations.map(({ task_subject_id }) => task_subject_id)

        const tasks = await dispatch('getTasks', {
          params: {
            filter: {
              id: ids
            },
            include: [
              'creator.organization',
              'linkedDefectProjectObjects'
            ],
            page: {
              size: 0
            }
          }
        })

        commit('SET_RELATED_TASKS', tasks.data)
      } else {
        commit('CLEAR_RELATED_TASKS')
      } 
    },

    removeRelatedTask({ commit }, task) {
      commit('REMOVE_RELATED_TASK', task)
    },

    clearTasks({ commit }) {
      commit('CLEAR_TASKS')
    },

    selectTask({ commit }, task) {
      commit('SET_SELECTED_TASK', task)
    },

    deselectTask({ commit }) {
      commit('SET_SELECTED_TASK', null)
      commit('SET_FILES_COUNT', 0)
    },

    // NOT REVIEWED

    clearEditableFields: async function ({commit}) {
      await commit('SET_EDITABLE_FIELDS', []);
    },
    clearAllowedActions: async function ({commit}) {
      await commit('SET_ALLOWED_ACTIONS', [])
    },
    clearTask: async function ({commit}) {
      commit('SET_SELECTED_TASK', null)
      commit('SET_FILES_COUNT', 0)
    },
    getTasks: async function (_, payload = null) {
      let {data} = await this.$api.tasks.index(payload);
      return data;
    },
    storeTask: async function (_, { payload }) {
      const { data } = await this.$api.tasks.store(payload);

      wantSomeMagic()
      
      return data;
    },

    storeTaskWithError({ dispatch }, { payload }) {
      return dispatch('storeTask', { payload })
        .catch(fromError)
    },

    storeDefectAndViolationTask: async function ({ dispatch }, {taskData}) {
      return dispatch('storeTask', {
        payload: {
          type: 'defects_and_violations',
          data: taskData
        }
      }).then(r => r.data);
    },

    storeDefectAndViolationTaskWithError: function({ dispatch }, { taskData }) {
      return dispatch('storeDefectAndViolationTask', { taskData })
        .catch(fromError)
    },

    showTask: async function ({state, commit, dispatch}, {
      taskId,
      payload
    }) {
      commit('REQUEST_TASK', { id: taskId })

      // TODO@temp
      state.taskLoading = true

      const result = await this.$api.tasks.show(taskId, payload)

      const task = result.data.data

      commit('SET_SELECTED_TASK', task)
      commit('REFRESH_TASK', task)
      dispatch('account/refreshTask', task, { root: true })

      const { files_count, type } = task || {}

      files_count && commit('SET_FILES_COUNT', files_count)

      type !== TASK_TYPES.APPROVAL_PROCESS && await dispatch('fetchRelatedTasks', task)
      type !== TASK_TYPES.APPROVAL_PROCESS && await dispatch('fetchEditableFieldsWithValidation', task)
      type !== TASK_TYPES.APPROVAL_PROCESS && await dispatch('getTaskEditableFields', { taskId: taskId })
      type !== TASK_TYPES.APPROVAL_PROCESS && await dispatch('fetchAllowedActions', { task })

      type === TASK_TYPES.APPROVAL_PROCESS && await dispatch('approval/fetchApproval', { processId: taskId }, { root: true })

      // TODO@temp
      state.taskLoading = false

      return result.data;
    },
    updateTask: async function ({dispatch}, {
      taskId,
      payload
    }) {
      let {data} = await this.$api.tasks.update(taskId, payload);

      await dispatch('showTask', {
        taskId: taskId,
        payload: {
          params: {
            include: [
              'project',
              'creator',
              'children',
              'members.user.organization',
              'memberInitiator.user.organization',
              'memberInspectors.user.organization',
              'memberWorkers.user.organization',
              'defectGeneralContractor.organization',
              'acceptanceOfWorkContractor.organization',
              'objectsBuildingOrderConnection.object',
              'objectsAcceptanceOfWorkConnection.object',
              'subjectsBuildingOrderConnection.subject',
              'subjectsAcceptanceOfWorkConnection.subject',
              'linkedDefectProjectObjects'
            ],
            append: [
              'unseen_files_exists',
              'unseen_messages_exists'
            ]
          }
        }
      });

      await dispatch('getTaskEditableFields', {taskId: taskId});
      await dispatch('fetchAllowedActions', {taskId: taskId});
      await dispatch('fetchEditableFieldsWithValidation', { id: taskId })

      wantSomeMagic()

      return data;
    },

    updateTaskWithError({ dispatch }, { taskId, payload }) {
      return dispatch('updateTask', { taskId, payload })
        .catch(fromError)
    },

    destroyTask: async function (context, payload) {
      let {data} = await this.$api.tasks.destroy(payload);
      return data;
    },
    multipleDestroyTask: async function (context, payload) {
      let {data} = await this.$api.tasks.multipleDestroy(payload);
      return data;
    },
    restoreTask: async function (context, id) {
      let {data} = await this.$api.tasks.restore(id);
      return data;
    },
    startTask: async function (context, id) {
      let {data} = await this.$api.tasks.startTask(id);
      return data;
    },
    sendToRevisionTask: async function (context, id) {
      let {data} = await this.$api.tasks.sendToRevisionTask(id);
      return data;
    },
    finishTask: async function (context, id) {
      let {data} = await this.$api.tasks.finishTask(id);
      return data;
    },
    completeTask: async function (context, id) {
      let {data} = await this.$api.tasks.completeTask(id);
      return data;
    },
    getTaskTypes: async function ({commit}) {
      let {data} = await this.$api.tasks.getTaskTypes();
      commit('SET_TASK_TYPES', data.data);
      return data;
    },
    getMessages: async function (context, payload) {
      let {data} = await this.$api.tasks.getMessages(payload);
      return data;
    },
    sendMessage: async function (context, {
      taskId,
      payload
    }) {
      let {data} = await this.$api.tasks.sendMessage(taskId, payload);
      return data;
    },
    updateMessage: async function (context, {
      taskId,
      messageId,
      payload
    }) {
      let {data} = await this.$api.tasks.updateMessage(taskId, messageId,
        payload);
      return data;
    },
    getTaskEditableFields: async function ({commit}, {taskId}) {
      let {data} = await this.$api.tasks.getTaskEditableFields(taskId);
      commit('SET_EDITABLE_FIELDS', data.data);
      return data;
    },
    makeTaskAction: async function ({ getters, dispatch }, {
      taskId,
      payload
    }) {
      let { data } = await this.$api.tasks.makeTaskAction(taskId, payload);
      dispatch('showTask', {
        taskId: taskId,
        payload: {
          params: {
            include: [
              'project',
              'creator',
              'children',
              'members.user.organization',
              'memberInitiator.user.organization',
              'memberWorkers.user.organization',
              'memberInspectors.user.organization',
              'defectGeneralContractor.organization',
              'acceptanceOfWorkContractor.organization',
              'objectsBuildingOrderConnection.object',
              'objectsAcceptanceOfWorkConnection.object',
              'subjectsBuildingOrderConnection.subject',
              'subjectsAcceptanceOfWorkConnection.subject',
              'linkedDefectProjectObjects'
            ]
          }
        }
      });

      dispatch('getTaskEditableFields', { taskId: taskId })
      dispatch('fetchAllowedActions', { taskId: taskId })
      await dispatch('fetchEditableFieldsWithValidation', { id: taskId })

      return data;
    },
    getTaskEvents: async function (context, {
      taskId,
      payload
    }) {
      let {data} = await this.$api.tasks.getTaskEvents(taskId, payload);
      return data;
    },
    getTaskFiles: async function (context, {
      taskId,
      payload
    }) {
      let {data} = await this.$api.tasks.getTaskFiles(taskId, payload);
      return data;
    },
    uploadTaskFiles: async function (context, {
      taskId,
      payload
    }) {
      let {data} = await this.$api.tasks.uploadTaskFiles(taskId, payload);
      return data;
    },
    deleteTaskFiles: async function (context, {
      taskId,
      payload
    }) {
      let {data} = await this.$api.tasks.deleteTaskFiles(taskId, payload);
      return data;
    },
    createTaskByImageDefects: async function (context, {imageDefectIds}) {
      let {data} = await this.$api.tasks.createTaskByImageDefects(
        {image_defects: imageDefectIds});
      return data.data.id;
    },
    getTaskAvailableFieldsForCreate: async function (context, payload) {
      let {data} = await this.$api.tasks.getTaskAvailableFieldsForCreate(
        payload);
      return data;
    },
    storeTaskImage: async function (context, {taskId, payload}) {
      let {data} = await this.$api.tasksV2.storeTaskImage(taskId, payload);
      return data;
    },
    getTaskImage: async function (context, {taskId, taskImageId}) {
      let {data} = await this.$api.tasksV2.getTaskImage(taskId, taskImageId);
      return data;
    },
    getTaskFillingFields: async function (context, {taskId, docTemplateId}) {
      let {data} = await this.$api.tasksV2.getTaskFillingFields(taskId, docTemplateId);
      return data;
    },
    storeTaskFillingFields: async function (context, {taskId, docTemplateId, payload}) {
      return await this.$api.tasksV2.storeTaskFillingFields(taskId, docTemplateId, payload);
    },
    deleteTaskImage: async function (context, {taskId, taskImageId}) {
      let {data} = await this.$api.tasksV2.deleteTaskImage(taskId, taskImageId);
      return data;
    },
    getGprTasksCount: async function ({commit}, {projectId, payload, contractorId}) {
      const data = (await this.$api.tasksV2.getGprTasksCount(projectId, payload))?.data?.data || []
      commit('SET_GPR_TASKS_COUNT_DATA', {contractorId, data})
      return data
    },
    clearGprTasksCount: function ({commit}) {
      commit('CLEAR_GPR_TASKS_COUNT_DATA')
    },
    increaseFilesCount: ({ commit }) => commit('INCREASE_FILES_COUNT'),
    decreaseFilesCount: ({ commit }) => commit('DECREASE_FILES_COUNT')
  }
};

